import { Injectable } from '@angular/core';
import {GeoJSON} from 'geojson';
import {MapDataImportSelectionDialogComponent} from '../../components/map-data-import-selection-dialog/map-data-import-selection-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MapboxService} from '../mapbox/mapbox.service';
import {InfoDialogService} from '../../../core/services/info-dialog/info-dialog.service';
import {AppRoutes} from '../../../core/enums/app-routes';
import {HubCustomViewType} from '../../../hub/enums/hub-custom-view-type';
import {Router} from '@angular/router';
import {HubRoutes} from '../../../hub/enums/hub-routes';

@Injectable({
  providedIn: 'root'
})
export class MapDataImportService {

  constructor(
    public dialog: MatDialog,
    private mapboxService: MapboxService,
    private infoDialogService: InfoDialogService,
    private router: Router
  ) { }

  showDialog(data: GeoJSON) {
    const dialogRef = this.dialog.open(MapDataImportSelectionDialogComponent, {
      width: '680px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result && result.geometry && (result.geometry.type === 'Polygon' || result.geometry.type === 'MultiPolygon')) {
          const encodedPolyline = this.mapboxService.convertPolygonToEncodedPolyline(result.geometry);

          this.router.navigate([AppRoutes.HUB, HubRoutes.CUSTOM, encodedPolyline], {
            queryParams: {type: HubCustomViewType.POLYGON}
          });
        } else {
          this.infoDialogService.showDialog(360, 'exclamation-triangle', 'Invalid Feature Selected', 'Invalid feature selected. Only polygon feature types are supported.');
        }
      }
    });
  }

  processFile(files: FileList, callback: (filename: string, data: any, error?: string) => void) {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.readAsText(files[0]);

      const filename = files[0].name;

      reader.onload = (result: ProgressEvent) => {
        if (result && result.target && (result.target as FileReader).result) {
          const data: string = (result.target as FileReader).result as string;

          try {
            const mapData = JSON.parse(data);

            if (!mapData || !mapData.type || mapData.type !== 'FeatureCollection' ||
              !mapData.features || mapData.features.length === 0) {
              return callback(null, null, 'The file you have selected is invalid. Please ensure this is a valid GeoJSON file.');
            } else {

              // Add id if it doesnt exist
              if (mapData.features) {
                mapData.features.forEach((f, i) => {
                  if (!f.properties.id) {
                    f.properties.id = i + 1;
                  }
                });
              }

              return callback(filename, mapData);
            }
          } catch {
            return callback(null, null, 'The file you have selected is invalid. Only GeoJSON files are currently supported.');
          }
        }
      };
    }
  }
}
