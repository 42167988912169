<div>
  <button mat-icon-button class="close-x-button" (click)="close()">
    <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>
  </button>

  <h1 mat-dialog-title>Select Feature to Import</h1>

  <div mat-dialog-content>
    <div class="map-container">
      <ee-map-group class="map"
                    [mapId]="'import-feature-map'"
                    [basemapName]="MapBasemapNameEnum.CARTO_LIGHT"
                    extraClass="small-map" [toolbarOptions]="toolbarOptions"
                    [scrollZoomEnabled]="false"
                    [mapSearchBarEnabled]="false" [mapLegendEnabled]="false"
                    (createCallback)="onMapCreated($event)"
      ></ee-map-group>
    </div>
  </div>

  <div mat-dialog-actions fxLayoutAlign="end end">
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button class="key-action" mat-dialog-close="true"
            [disabled]="!selectedFeature" (click)="import()">Import</button>
  </div>
</div>
