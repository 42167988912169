export enum UserEvents {
  MODULE_ACCESSED = 'MODULE_ACCESSED',

  // Toolbar

  IMAGE_EXPORT = 'IMAGE_EXPORT',
  STREET_VIEW_ON = 'STREET_VIEW_ON',
  STREET_VIEW_OFF = 'STREET_VIEW_OFF',
  MEASURE_LINE = 'MEASURE_LINE',
  MEASURE_POLYGON = 'MEASURE_POLYGON',
  MEASURE_RADIUS = 'MEASURE_RADIUS',
  MEASURE_TRAVEL_CYCLING = 'MEASURE_TRAVEL_CYCLING',
  MEASURE_TRAVEL_WALKING = 'MEASURE_TRAVEL_WALKING',
  MEASURE_TRAVEL_DRIVING = 'MEASURE_TRAVEL_DRIVING',
  MEASURE_TRAVEL_TRANSPORT = 'MEASURE_TRAVEL_TRANSPORT',
  MEASURE_TRAVEL_REACHABILITY = 'MEASURE_TRAVEL_REACHABILITY',
  SCHOOLS_3D_VIZ_ON = '3D_VIZ_ON',
  SCHOOLS_3D_VIZ_OFF = '3D_VIZ_OFF',
  MAP_INSPECTOR = 'MAP_INSPECTOR',
  ADD_ANNOTATION = 'ADD_ANNOTATION',
  ADD_PLANNED_AREA = 'ADD_PLANNED_AREA',
  HOME_BUTTON = 'HOME_BUTTON',

  // Reference Layers

  REFERENCE_LAYER_ON = 'REFERENCE_LAYER_ON',
  REFERENCE_LAYER_OFF = 'REFERENCE_LAYER_OFF',
  REFERENCE_LAYER_TOOLTIP_ON = 'REFERENCE_LAYER_TOOLTIP_ON',
  REFERENCE_LAYER_TOOLTIP_OFF = 'REFERENCE_LAYER_TOOLTIP_OFF',

  // Basemaps

  BASEMAP_CHANGED = 'BASEMAP_CHANGED',

  // ERRORS

  CLIENT_ERROR = 'CLIENT_ERROR',

  DOWNLOAD = 'DOWNLOAD'
}
