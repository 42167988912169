/// <reference types="@types/googlemaps" />

import {Injectable} from '@angular/core';
import {StreetViewObject} from '../../interfaces/street-view-object';
import {MapboxService} from '../mapbox/mapbox.service';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {MapObject} from '../../interfaces/map-object';
import {MapImage} from '../../interfaces/map-image';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {MapPsuedoLayers} from '../../enums/map-psuedo-layers';
import {LngLat, PointLike} from 'mapbox-gl';
import {point} from '@turf/helpers';
import {distance, nearestPointOnLine} from '@turf/turf';
import {Feature, LineString, MultiLineString} from 'geojson';
import StreetViewPanorama = google.maps.StreetViewPanorama;
import StreetViewStatus = google.maps.StreetViewStatus;
import LatLngLiteral = google.maps.LatLngLiteral;
import StreetViewPanoramaOptions = google.maps.StreetViewPanoramaOptions;
import Layer = mapboxgl.Layer;

@Injectable({
  providedIn: 'root'
})
export class StreetViewService {

  private streetViewPool: StreetViewObject[] = [];
  private readonly sourceId = 'street-view';
  private readonly svMarkerLayerId = 'street-view-marker';
  private markerImage: MapImage[] = [{
    id: 'sv-marker',
    url: environment.assetsFolder + 'sv-marker.png'
  }];

  private layers: Layer[] = [{
    'id': this.svMarkerLayerId,
    'type': 'symbol',
    'layout': {
      'icon-image': 'sv-marker',
      'icon-size': 1,
      'icon-rotate': {
        'type': 'identity',
        'property': 'iconRotation'
      },
      'icon-pitch-alignment': 'map'
    }
  }];

  private snapToRoadId = 'street-view-snap-to-road';
  private snapToRoadLayer: Layer[] = [{
    'id': this.snapToRoadId,
    'type': 'line',
    'source-layer': 'road',
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#2AAAFF',
      'line-opacity': 0,
      'line-width': 5,
      'line-blur': 0
    },
    filter: ['all', ['==', '$type', 'LineString'],
      ['in', 'class', 'motorway', 'motorway_link', 'primary', 'secondary', 'street', 'tertiary']
    ]
  }];

  constructor(
    private mapboxService: MapboxService,
    private http: HttpClient
  ) {}

  getStreetViewObjectById(id: string) {
    let streetViewObject: StreetViewObject = null;

    for (const svObj of this.streetViewPool) {
      if (svObj.id === id) {
        streetViewObject = svObj;
        break;
      }
    }

    return streetViewObject;
  }

  createPanorama(containerId: string, position?: LatLngLiteral, showAddressControl?: boolean) {
    let streetViewObject = this.getStreetViewObjectById(containerId);

    if (streetViewObject) {
      const svContainer: HTMLElement = document.getElementById(containerId);
      const parent: HTMLElement = svContainer.parentElement;
      parent.removeChild(svContainer);
      parent.appendChild(streetViewObject.element);
    }
    else {
      const elem: HTMLElement = document.getElementById(containerId);

      const svOptions: StreetViewPanoramaOptions = {
        pov: {heading: 0, pitch: 0},
        motionTracking: false,
        motionTrackingControl: false,
        zoomControl: false,
        addressControl: showAddressControl
      };

      if (position) {
        svOptions.position = position;
      }

      const panorama = new StreetViewPanorama(
        elem, svOptions
      );

      streetViewObject = {
        panorama: panorama,
        element: elem,
        id: containerId,
        active: false,
        onStatusChange: new BehaviorSubject<boolean>(false),
        onPovChange: new BehaviorSubject<number>(0),
        onEnabled: new BehaviorSubject<boolean>(false)
      };

      // On street view status change
      streetViewObject.statusChangedListener = streetViewObject.panorama.addListener(
        'status_changed', () => {
          if (streetViewObject.onStatusChange) {
            streetViewObject.onStatusChange.next((streetViewObject.panorama.getStatus() === StreetViewStatus.OK));
            this.triggerResize(streetViewObject);
          }
        });

      this.streetViewPool.push(streetViewObject);
    }

    return streetViewObject;
  }

  bindToMap(streetViewObject: StreetViewObject, mapObject: MapObject) {
    if (streetViewObject && !streetViewObject.mapObject) {
      streetViewObject.mapObject = mapObject;

      // Add streets
      this.mapboxService.updateVectorDataLayer(streetViewObject.mapObject, this.snapToRoadId, 'mapbox://mapbox.mapbox-streets-v7',
        this.snapToRoadLayer, MapPsuedoLayers.PSUEDO_LAYER_TOP);

      // On map set position
      streetViewObject.mapSetPositionSubscription = mapObject.onSetPosition
        .subscribe(
          position => {
            streetViewObject.panorama.setPosition({lng: position[0], lat: position[1]});
          }
        );

      // On street view position change
      streetViewObject.positionChangedListener = streetViewObject.panorama.addListener(
        'position_changed', () => {
          const pos = streetViewObject.panorama.getPosition();

          if (streetViewObject.marker) {
            streetViewObject.marker.geometry.coordinates = [pos.lng(), pos.lat()];
            this.mapboxService.updateGeoJsonDataLayer(streetViewObject.mapObject, this.sourceId,
              [streetViewObject.marker], null, this.layers);
          }
          this.triggerResize(streetViewObject);
        });

      // On street view pov change
      streetViewObject.povChangedListener = streetViewObject.panorama.addListener(
        'pov_changed', () => {
          if (streetViewObject.onPovChange) {
            const orientation = Math.floor(streetViewObject.panorama.getPov().heading);
            streetViewObject.onPovChange.next(orientation);
          }
        }
      );
    }
  }

  unbindFromMap(streetViewObject: StreetViewObject) {
    if (streetViewObject && streetViewObject.mapObject) {
      streetViewObject.mapSetPositionSubscription.unsubscribe();

      google.maps.event.removeListener(streetViewObject.positionChangedListener);
      // google.maps.event.removeListener(streetViewObject.statusChangedListener);
      google.maps.event.removeListener(streetViewObject.povChangedListener);

      streetViewObject.mapObject = null;
    }
  }

  createDragMarker(streetViewObject: StreetViewObject) {
    if (streetViewObject && streetViewObject.mapObject) {
      this.mapboxService.loadImages(streetViewObject.mapObject, this.markerImage, () => {
        const pos = streetViewObject.mapObject.map.getCenter();

        streetViewObject.marker = {
          geometry: {
            'type': 'Point',
            'coordinates': [pos.lng, pos.lat]
          },
          iconRotation: 0
        };

        this.mapboxService.updateGeoJsonDataLayer(streetViewObject.mapObject, this.sourceId,
          [streetViewObject.marker], null, this.layers);

        streetViewObject.onRotateChangeSubscription = combineLatest([
          streetViewObject.onPovChange,
          streetViewObject.mapObject.onRotateChange
        ])
          .subscribe(([pov, rotation]) => {
            if (streetViewObject.marker) {
              streetViewObject.marker.iconRotation = pov - rotation;
              this.mapboxService.updateGeoJsonDataLayer(streetViewObject.mapObject, this.sourceId,
                [streetViewObject.marker], null, this.layers);
            }
          });

        const markerMouseDownEvent: (ev: any) => void = (e) => {
          // Prevent map from panning while dragging
          e.preventDefault();
          streetViewObject.isDragging = true;

          // Show snap to roads if map has been bound
          if (streetViewObject.mapObject) {
            streetViewObject.mapObject.map.setPaintProperty(this.snapToRoadId, 'line-opacity', 0.8);
          }
        };

        const mapMouseUpEvent: (ev: any) => void = () => {
          streetViewObject.isDragging = false;

          // Hide snap to roads if map has been bound
          if (streetViewObject.mapObject) {
            streetViewObject.mapObject.map.setPaintProperty(this.snapToRoadId, 'line-opacity', 0);
          }
        };

        const mapMouseMoveEvent: (ev: any) => void = (e) => {
          if (streetViewObject.isDragging && e.lngLat) {
            // Prevent map from panning while dragging
            e.preventDefault();

            // Snap to nearest road if map has been bound
            let nearestPoint = null;
            if (streetViewObject.mapObject) {
              const bbox: [PointLike, PointLike] = [
                [e.point.x - 25, e.point.y - 25],
                [e.point.x + 25, e.point.y + 25]
              ];
              const features = streetViewObject.mapObject.map.queryRenderedFeatures(bbox, {
                layers: [this.snapToRoadId]
              });

              const svPoint = point(e.lngLat.toArray());
              let minDistance = null;

              for (const f of features) {
                const np = nearestPointOnLine(f as Feature<MultiLineString | LineString>, svPoint);
                const d = distance(svPoint, np);

                if (!minDistance || d < minDistance) {
                  minDistance = d;
                  nearestPoint = np;
                }
              }
            }

            let markerPoint = e.lngLat;
            if (nearestPoint && nearestPoint.geometry && nearestPoint.geometry.type === 'Point') {
              markerPoint = new LngLat(nearestPoint.geometry.coordinates[0], nearestPoint.geometry.coordinates[1]);
            }

            if (streetViewObject.marker) {
              streetViewObject.marker.geometry.coordinates = markerPoint;
              this.mapboxService.updateGeoJsonDataLayer(streetViewObject.mapObject, this.sourceId,
                [streetViewObject.marker], null, this.layers);
            }

            streetViewObject.panorama.setPosition(markerPoint);
          }
        };

        const markerMouseMove: (ev: any) => void = () => {
          if (streetViewObject.mapObject) {
            streetViewObject.mapObject.map.getCanvas().style.cursor = 'pointer';
          }
        };

        const markerMouseLeave: (ev: any) => void = () => {
          if (streetViewObject.mapObject) {
            streetViewObject.mapObject.map.getCanvas().style.cursor = '';
          }
        };

        // Catch click event to prevent 'click through'
        this.mapboxService.registerEvent(streetViewObject.mapObject, 'click', this.sourceId, () => {});

        streetViewObject.mapObject.map.on('mousedown', this.svMarkerLayerId, markerMouseDownEvent);
        streetViewObject.onMarkerMouseDown = markerMouseDownEvent;

        streetViewObject.mapObject.map.on('mouseup', mapMouseUpEvent);
        streetViewObject.onMapMouseUp = mapMouseUpEvent;

        streetViewObject.mapObject.map.on('mousemove', mapMouseMoveEvent);
        streetViewObject.onMapMouseMove = mapMouseMoveEvent;

        streetViewObject.mapObject.map.on('mousemove', this.svMarkerLayerId, markerMouseMove);
        streetViewObject.onMarkerMouseMove = markerMouseMove;

        streetViewObject.mapObject.map.on('mouseleave', this.svMarkerLayerId, markerMouseLeave);
        streetViewObject.onMarkerMouseLeave = markerMouseLeave;

        streetViewObject.panorama.setPosition(pos);
        this.mapboxService.panTo(streetViewObject.mapObject.map, [pos.lng, pos.lat]);
      });
    }
  }

  removeDragMarker(streetViewObject: StreetViewObject) {
    streetViewObject.mapObject.map.off('mousedown', streetViewObject.onMarkerMouseDown);
    streetViewObject.mapObject.map.off('mouseup', streetViewObject.onMapMouseUp);
    streetViewObject.mapObject.map.off('mousemove', streetViewObject.onMapMouseMove);

    if (streetViewObject.onRotateChangeSubscription) {
      streetViewObject.onRotateChangeSubscription.unsubscribe();
    }

    this.mapboxService.updateGeoJsonDataLayer(streetViewObject.mapObject, this.sourceId, null, null, this.layers);
  }

  triggerResize(streetViewObject: StreetViewObject) {
    setTimeout(() => {
      google.maps.event.trigger(streetViewObject.panorama, 'resize');
    });
  }

  enable(streetViewObject: StreetViewObject, mapObject: MapObject) {
    this.bindToMap(streetViewObject, mapObject);
    this.createDragMarker(streetViewObject);
    setTimeout(() => {
      mapObject.map.resize();
      this.triggerResize(streetViewObject);
    });
    streetViewObject.onEnabled.next(true);
  }

  disable(streetViewObject: StreetViewObject) {
    // Save reference to map as street view object will be cleared out
    const mapRef = streetViewObject.mapObject.map;

    this.removeDragMarker(streetViewObject);
    this.unbindFromMap(streetViewObject);
    setTimeout(() => {
      mapRef.resize();
    });
    streetViewObject.onEnabled.next(false);
  }

  clear(streetViewObject: StreetViewObject) {
    streetViewObject.onStatusChange.next(false);
  }

  getStaticImageUrl(lat: number, lng: number, width: number, height: number): Observable<string> {
    const baseUrl = 'https://maps.googleapis.com/maps/api/streetview';
    const urlParams = `?size=${width}x${height}&location=${lat},${lng}&fov=90&heading=235&pitch=10&key=${environment.googleApiKey}`;

    const metaUrl = baseUrl + '/metadata' + urlParams;

    return this.http.get<any>(metaUrl)
      .pipe(
        map(
          result => {
            if (result && result.status && result.status === 'OK') {
              return baseUrl + urlParams;
            } else {
              return null;
            }
          }
        )
      );
  }
}
