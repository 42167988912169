import { Injectable } from '@angular/core';
import {Subscription, interval, Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {MatDialog} from '@angular/material/dialog';
import {SessionExpiryNotificationComponent} from '../../components/session-expiry-notification/session-expiry-notification.component';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SessionExpiryNotificationService {

  private dialogOpen = false;

  private sessionCheckSubscription: Subscription = null;
  private readonly checkInterval = 60000; // 1 min

  constructor(
    private cookieService: CookieService,
    public dialog: MatDialog,
    private authService: AuthService
  ) { }

  private showDialog(expiry: number): Observable<any> {
    // Wait until DOM is ready
    const dialogRef = this.dialog.open(SessionExpiryNotificationComponent, {
      width: '340px',
      disableClose: true,
      data: {
        expiry: expiry
      }
    });

    return dialogRef.afterClosed();
  }

  private checkSession(): void {
    const now = Math.round(new Date().getTime() / 1000);
    const exp = Number(this.cookieService.get('expiry'));
    const int = 5 * 60; // 5 mins;

    if (exp - now < int) {
      if (!this.dialogOpen) {
        this.dialogOpen = true;
        this.showDialog(exp)
          .subscribe(result => {
            this.dialogOpen = false;

            if (result) {
              this.renewSession();
            } else {
              this.logout();
            }
          });
      }
    }
  }

  private renewSession() {
    this.authService.checkAuthenticated().subscribe();
  }

  private logout() {
    this.authService.logout();
  }

  startPolling() {
    this.sessionCheckSubscription = interval(this.checkInterval)
      .pipe(startWith(0))
      .subscribe(() => {
        this.checkSession();
      });
  }

  stopPolling() {
    if (this.sessionCheckSubscription) {
      this.sessionCheckSubscription.unsubscribe();
      this.sessionCheckSubscription = null;
    }
  }
}
