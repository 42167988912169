import { Injectable } from '@angular/core';
import {MapObject} from '../../interfaces/map-object';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import {saveAs} from 'file-saver';
import {Observable} from 'rxjs';
import {LoadingService} from '../../../core/services/loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class MapImageExportService {

  constructor(
    private loadingService: LoadingService
  ) {
  }

  captureImage(mapObject: MapObject, title?: string, subtitle?: string): Observable<Blob> {


    return new Observable(observer => {
        const northArrowImage: HTMLImageElement = new Image();

        const exportImage = () => {
          mapObject.map.once('render', () => {

            const mapElem = document.getElementById(mapObject.id);
            html2canvas(mapElem, {
              backgroundColor: null,
              allowTaint: true,
              imageTimeout: 100000,
              onclone: (clonedDocument) => {
                // Fix issues with capturing SVGs - they do not appear unless they have a fixed width/height
                const clonedMapElem = clonedDocument.getElementById(mapObject.id);
                const svgElems = clonedMapElem.getElementsByTagName('svg');

                Array.from(svgElems).forEach(
                  (svg: SVGGraphicsElement) => {
                    const bBox = svg.getBBox();
                    svg.setAttribute('width',  `${bBox.width}px`);
                    svg.setAttribute('height', `${bBox.height}px`);
                  });

                // Ensure attribution displays correctly
                const attributionElem = clonedMapElem.getElementsByClassName('mapboxgl-ctrl-attrib');
                if (attributionElem && attributionElem.length === 1 && attributionElem[0]) {
                  attributionElem[0].classList.remove('mapboxgl-compact');
                  (attributionElem[0] as HTMLElement).style.marginBottom = '12px';
                  const improveElem = attributionElem[0].getElementsByClassName('mapbox-improve-map');
                  if (improveElem && improveElem.length === 1 && improveElem[0]) {
                    (improveElem[0] as HTMLElement).style.display = 'none';
                  }
                }

                // Move scale control up
                const scaleElem = clonedMapElem.getElementsByClassName('mapboxgl-ctrl mapboxgl-ctrl-scale');
                if (scaleElem && scaleElem.length === 1 && scaleElem[0]) {
                  (scaleElem[0] as HTMLElement).style.position = 'absolute';
                  (scaleElem[0] as HTMLElement).style.bottom = '32px';
                }

                // Add copyright and export date on top of image
                const copyElem = clonedDocument.createElement('div');
                copyElem.innerHTML = 'Eagle Eye &copy; School Infrastructure NSW. Exported on ' + moment().format('DD/MM/YYYY') + '.';
                copyElem.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
                copyElem.style.position = 'absolute';
                copyElem.style.height = '20px';
                copyElem.style.bottom = '12px';
                copyElem.style.left = '0';
                copyElem.style.color = 'rgba(0, 0, 0, 0.75)';
                copyElem.style.fontSize = '12px';
                copyElem.style.padding = '0 5px';
                clonedMapElem.appendChild(copyElem);

                // Add disclaimer
                const disclaimerElem = clonedDocument.createElement('div');
                disclaimerElem.innerHTML = 'Data is approximate only. For internal use only. Unauthorised distribution is ' +
                  'strictly forbidden.';
                disclaimerElem.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
                disclaimerElem.style.position = 'absolute';
                disclaimerElem.style.width = '100%';
                disclaimerElem.style.height = '12px';
                disclaimerElem.style.lineHeight = '1.25';
                disclaimerElem.style.bottom = '0';
                disclaimerElem.style.left = '0';
                disclaimerElem.style.color = 'rgba(255, 255, 255, 1)';
                disclaimerElem.style.fontSize = '10px';
                disclaimerElem.style.padding = '0 5px';
                clonedMapElem.appendChild(disclaimerElem);

                // Ensure map tooltip is not visible
                const mapTooltip = clonedDocument.getElementsByClassName('tooltip-text');
                if (mapTooltip && mapTooltip.length) {
                  (mapTooltip[0] as HTMLElement).style.display = 'none';
                }

                // Add optional title
                if (title || subtitle) {

                  const defaultWidth = 934;
                  const mapWidth = clonedMapElem.offsetWidth;
                  const scaling = Math.max((mapWidth / defaultWidth), 1);

                  const topElem = clonedDocument.createElement('div');
                  topElem.style.backgroundColor = 'rgba(0, 0, 0, 0.65)';
                  topElem.style.color = 'white';
                  topElem.style.position = 'absolute';
                  topElem.style.width = 'auto';
                  topElem.style.maxWidth = 'calc(100% - 16px - 24px)';
                  topElem.style.top = '8px';
                  topElem.style.left = '8px';
                  topElem.style.padding = `${8 * scaling}px ${12 * scaling}px`;

                  if (title) {
                    const titleElem = clonedDocument.createElement('div');

                    titleElem.innerHTML = title;
                    titleElem.style.fontSize = `${16 * scaling}px`;
                    titleElem.style.fontWeight = '600';

                    topElem.appendChild(titleElem);
                  }

                  if (subtitle) {
                    const subtitleElem = clonedDocument.createElement('div');

                    if (title) {
                      subtitleElem.style.marginTop = `${8 * scaling}px`;
                    }

                    subtitleElem.innerHTML = subtitle;
                    subtitleElem.style.fontSize = `${12 * scaling}px`;

                    topElem.appendChild(subtitleElem);
                  }

                  clonedMapElem.appendChild(topElem);
                }

                // Add north arrow
                const rotation = -1 * Math.floor(mapObject.map.getBearing());
                northArrowImage.style.transform = `rotate(${rotation}deg)`;
                northArrowImage.style.transformOrigin = '16px 16px';
                northArrowImage.width = 32;
                northArrowImage.height = 32;

                const northArrowElem = clonedDocument.createElement('div');
                northArrowElem.style.position = 'absolute';
                northArrowElem.style.right = '5px';
                northArrowElem.style.bottom = '32px';
                northArrowElem.style.zIndex = '1000000';
                northArrowElem.style.width = '32px';
                northArrowElem.style.height = '32px';
                northArrowElem.appendChild(northArrowImage);

                // Can only apply font family to the container - doesn't work on individual elements
                clonedMapElem.style.fontFamily = '\'Open Sans\', sans-serif';

                clonedMapElem.appendChild(northArrowElem);
              }
            })
              .then(canvas => {
                  canvas.toBlob(blob => {
                    observer.next(blob);
                    observer.complete();
                  });
              })
              .catch(error => {
                console.log('Error', error);
                observer.error(error);
                observer.complete();
              });
          });

          // Force trigger render event (above)
          mapObject.map.setBearing(mapObject.map.getBearing());
        };

        // Need to pre-load the image (even base64) in order for it to display on canvas snapshot
        northArrowImage.onload = function() {
          exportImage();
        };

        northArrowImage.src = '/assets/images/north-arrow.svg';
    });
  }

  saveImage(blob: Blob, filename?: string) {
    saveAs(blob, 'Eagle Eye - Image Export - '
      + (filename ? `${filename} - ` : '')
      + moment().format('DD-MM-YYYY') + '.png');
  }

  captureAndSaveImage(mapObject: MapObject, title?: string, subtitle?: string, filename?: string) {
    this.loadingService.setLoadingMessage('Exporting Map Image...');
    this.loadingService.incrementLoading();

    this.captureImage(mapObject, title, subtitle)
      .subscribe(blob => {
        if (blob) {
          this.saveImage(blob, filename);

          setTimeout(() => {
            this.loadingService.clearLoadingMessage();
            this.loadingService.decrementLoading();
          });
        }
      });
  }
}
