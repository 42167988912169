import {Component, OnDestroy, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';
import {AppRoutes} from '../../enums/app-routes';
import {HelpService} from '../../services/help/help.service';
import {UserRoles} from '../../enums/user-roles';
import {MakeUpMirrorService} from '../../services/make-up-mirror/make-up-mirror.service';
import {environment} from '../../../../environments/environment';
import {MapStatsService} from '../../../shared/services/map-stats/map-stats.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NotificationPollingService} from '../../../shared/services/notification-polling/notification-polling.service';
import {SessionExpiryNotificationService} from '../../services/session-expiry-notification/session-expiry-notification.service';
import {DownloadsApiCommsService} from '../../services/downloads-api-comms/downloads-api-comms.service';
import {MapDataImportUploadDialogService} from '../../../shared/services/map-data-import-upload-dialog/map-data-import-upload-dialog.service';
import {ShapeDownloadsResolverService} from '../../resolvers/shape-downloads-resolver/shape-downloads-resolver.service';
import {ShapeDownload} from '../../../shared/interfaces/shape-download';
import {UserEventCategories} from '../../../shared/enums/user-event-categories';
import {UserEvents} from '../../../shared/enums/user-events';
import {UserEventService} from '../../services/user-event/user-event.service';
import {DownloadDisclaimerService} from '../../services/download-disclaimer/download-disclaimer.service';
import {UserAgreementService} from '../../services/user-agreement/user-agreement.service';
import {DocumentLinksResolverService} from '../../resolvers/document-links-resolver/document-links-resolver.service';
import {DocumentLinks} from '../../interfaces/document-links';

@Component({
  selector: 'ee-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  notificationsCount: number = null;

  ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  username = 'user';
  isAdmin = false;
  nonProdAlert = null;
  supportUrl = `https://schoolsnsw.sharepoint.com/sites/EagleEye/`

  shapeDownloads: ShapeDownload[] = [];
  documentLinks: DocumentLinks[] = [];

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private helpService: HelpService,
    private mapStatsService: MapStatsService,
    private notificationPollService: NotificationPollingService,
    private makeUpMirrorService: MakeUpMirrorService,
    private sessionExpiryNotificationService: SessionExpiryNotificationService,
    private router: Router,
    private downloadsApiComms: DownloadsApiCommsService,
    private mapDataUploadDialogService: MapDataImportUploadDialogService,
    private shapesDownloadResolverService: ShapeDownloadsResolverService,
    private userEventService: UserEventService,
    private downloadDisclaimerService: DownloadDisclaimerService,
    private userAgreementService: UserAgreementService,
    private documentLinksResolverService: DocumentLinksResolverService
  ) {}

  ngOnInit() {
    const givenName = this.authService.getUserFirstName();
    if (givenName) {
      this.username = givenName;

      // Sometimes you must do silly things to keep people happy
      if (this.authService.getUsername().toLowerCase() === 'jannatun.haque') {
        this.username = 'Meowster';
      }
    }

    this.isAdmin = this.authService.checkUserRole(UserRoles.ADMIN);

    if (environment.environment && !environment.production) {
      if (environment.preview) {
        this.nonProdAlert = 'PREVIEW';
      } else {
        this.nonProdAlert = environment.environment;
      }
    }

    this.onNotificationsDataUpdate();
    this.sessionExpiryNotificationService.startPolling();

    this.watchShapeDownloads();
    this.watchDocumentLinks();
    this.mapStatsService.resetVisibility();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.sessionExpiryNotificationService.stopPolling();
  }

  private watchShapeDownloads() {
    this.shapesDownloadResolverService.onUpdate()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.shapeDownloads = data;
        }
      });
  }

  private watchDocumentLinks() {
    this.documentLinksResolverService.onDocumentLinksReady()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.documentLinks = data;
        }
      });
  }

  sendFeedback() {
    const email = this.authService.getUserEmail();
    const givenName = this.authService.getUserFirstName();
    const lastName = this.authService.getUserLastName();

    // Calculate window position
    const windowWidth = 500;
    const windowHeight = 500;

    // Fixes dual-screen position                         Most browsers      Firefox
    // Credit - http://www.xtf.dk/2011/08/center-new-popup-window-even-on.html
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ?
      document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ?
      document.documentElement.clientHeight : screen.height;

    const left = ((width / 2) - (windowWidth / 2)) + dualScreenLeft;
    const top = ((height / 2) - (windowHeight / 2)) + dualScreenTop;

    const url = `https://sinsw.tpondemand.com/helpdesk/widget?product_id=222` +
      `&email=${email}&firstname=${givenName}&lastname=${lastName}&show_request_types=true&top_requests_count=10&show_description=true` +
      `&show_attachments=true&default_request_type=1&form_title=Eagle%20Eye%20Feedback`;

    window.open(url, 'eagle-eye-feedback',
      `scrollbars=no,status=no,titlebar=no,toolbar=no,width=${windowWidth},height=${windowHeight},top=${top},left=${left}`);
  }

  showUploadDialog() {
    this.mapDataUploadDialogService.showDialog();
  }

  toggleHelp() {
    //this.helpService.toggleHints();
    window.open(this.supportUrl, '_blank');
  }

  navigateToAdmin() {
    this.router.navigate([AppRoutes.ADMIN]);
  }

  showMakeUpMirror() {
    this.makeUpMirrorService.showDialog();
  }

  toggleMapStats() {
    this.mapStatsService.toggleVisibility();
  }

  viewUserAgreement() {
    this.userAgreementService.showUserTocDialog(true);
  }

  logout() {
    this.authService.logout();
  }

  onNotificationsDataUpdate() {
    this.notificationPollService.onUpdate()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.notificationsCount = data ?
          data.filter((notification) => !(notification.hasRead)).length : 0;
      });
  }

  downloadSchoolList() {
    this.downloadDisclaimerService.showDownloadDisclaimer(() => {
      this.downloadsApiComms.getSchoolMasterList();
    });
  }

  downloadProjectList() {
    this.downloadDisclaimerService.showDownloadDisclaimer(() => {
      this.downloadsApiComms.getProjectList();
    });
  }

  downloadGeometry(shape: ShapeDownload) {
    if (shape && shape.url) {
      this.downloadDisclaimerService.showDownloadDisclaimer(() => {
        this.userEventService.logUserEvent({
          category: 'CORE',
          event: UserEvents.DOWNLOAD,
          metadata: {
            name: shape.displayName,
            url: shape.url
          }
        });

        window.open(shape.url, '_blank');
      });
    }
  }

  openDocument(document: DocumentLinks) {
    if (document && document.url) {
      window.open(document.url, '_blank');
    }
  }
}
