<div [class]="notificationMessage.type" [ngClass]="{'visible': visible}">
  <button mat-icon-button class="panel-close-button" (click)="close()">
    <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
  </button>
  <div class="content" fxLayout="column">
    <div class="heading" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon fontSet="fal" fontIcon="fa-exclamation-triangle" *ngIf="notificationMessage.type === 'error'"></mat-icon>
      <mat-icon fontSet="fal" fontIcon="fa-exclamation-triangle" *ngIf="notificationMessage.type === 'simple-error'"></mat-icon>
      <mat-icon fontSet="fal" fontIcon="fa-check" *ngIf="notificationMessage.type === 'success'"></mat-icon>
      <mat-icon fontSet="fal" fontIcon="fa-info-circle" *ngIf="notificationMessage.type === 'info'"></mat-icon>
      <h3>{{notificationMessage.title}}</h3>
    </div>
    <div class="text" *ngIf="notificationMessage.message">
      <div *ngIf="notificationMessage.type === 'error'">
        An error has occurred. Please report this to an administrator. It is recommended that you refresh this page
        before continuing.
        <br><br>
        <span class="italic">{{notificationMessage.message}}</span>
      </div>
      <div *ngIf="notificationMessage.type !== 'error'">
        {{notificationMessage.message}}
      </div>
    </div>
  </div>
</div>
