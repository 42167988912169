import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {finalize, Observable, throwError} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {catchError, map, tap} from 'rxjs/operators';
import {LoadingService} from '../loading/loading.service';
import {environment} from '../../../../environments/environment';
import {ApiResponse} from '../../interfaces/api-response';
import {NotificationPopupType} from '../../enums/notification-popup-type';
import {NotificationPanelService} from '../../../shared/services/notification-panel/notification-panel.service';
import {AppService} from '../app/app.service';
import {Router} from '@angular/router';
import {AppRoutes} from '../../enums/app-routes';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {

  private requestCount = 0;

  private redirectInterval: any = null;

  private readonly excludeFromLoading = [
    '/eagle-eye/search?',
    '/eagle-eye/user-event',
    '/notifications/current-user',
    'https://api.nearmap.com/coverage/v2/poly/',
    'https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer/0/query',
    environment.tileyServer + '/search'
  ];

  private readonly excludeFromErrors = [
    environment.backendServer.isochrone
  ];

  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
    private notificationPanelService: NotificationPanelService,
    private appService: AppService,
    private router: Router
  ) { }

  inExclusionList(exclusionList: string[], requestUrl: string): boolean {
    let result = false;
    const urlStem = requestUrl.replace(environment.backendServer.mainPath, '');
    for (const url of exclusionList) {
      if (urlStem.startsWith(url)) {
        result = true;
        break;
      }
    }

    return result;
  }

  private checkAndUpdateRequestHeader(request: HttpRequest<any>): HttpRequest<any> {
    // Only modify requests to backend server
    if (request.url.startsWith(environment.backendServer.mainPath)) {

      // Ensure authToken in cookie is provided with all calls
      request = request.clone({
        withCredentials: true
      });

      // Manually add unique CSRF token to mitigate against cross-forgery attacks
      const csrfToken = this.authService.getCsrfToken();
      if (csrfToken) {
        request = request.clone({
          setHeaders: {
            'X-XSRF-TOKEN': csrfToken
          }
        });
      }
    }

    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.incrementLoading(request.url);
    request = this.checkAndUpdateRequestHeader(request);

    // Check whether received 401 unauthorised
    return next.handle(request)
      .pipe(
        map(response => {
          // If url request is hitting the backend, check and map ApiResponse
          if (request.url.startsWith(environment.backendServer.mainPath)) {
            response = (response as HttpResponse<ApiResponse<any>>);
            if (response.body && response.body.success) {
              response = response.clone({
                body: response.body.data
              });
            }
          }
          return response;
        }),
        finalize(() => {
          this.loadingService.decrementLoading(request.url);
        }),
        catchError(error => {
          if (error.status === 401) {
            this.authService.redirectToLoginPage();
            return throwError('Unauthorized');
          } else if (error.status === 403) {
            // Wait until there are no more requests before redirecting
            if (!this.redirectInterval) {
              this.redirectInterval = setInterval(() => {
                if (this.requestCount === 0) {
                  clearInterval(this.redirectInterval);
                  this.redirectInterval = null;
                  this.authService.redirectToRestrictedPage();
                }
              }, 500);
            }

            return throwError(error);
          } else {
            if (!this.inExclusionList(this.excludeFromLoading, request.url) &&
              !this.inExclusionList(this.excludeFromErrors, request.url)) {

              if (!this.appService.isRouterOutletActive()) {
                console.log('navigate here');
                this.router.navigate([AppRoutes.ERROR], {
                  queryParams: {
                    message: error.error && error.error.error ?
                      (error.error.error.code + ': ' + error.error.error.message) : (error.name + ': ' + error.message + '.')
                  }
                });
              }

              this.notificationPanelService.showNotification({
                type: NotificationPopupType.ERROR,
                title: 'Communication Error',
                message: error.error && error.error.error ?
                  (error.error.error.code + ': ' + error.error.error.message) : (error.name + ': ' + error.message + '.')
              });
              return throwError(error);
            } else {
              // return of(null);
              return throwError(null);
            }
          }
        })
    );
  }
}
