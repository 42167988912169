<div fxLayout="row">
  <div *ngIf="nonProdAlert" class="non-prod-alert">{{nonProdAlert}}</div>
  <div fxFlex></div>

  <button mat-button class="header-button" (click)="showUploadDialog()">
    <mat-icon fontSet="fal" fontIcon="fa-upload"></mat-icon>
    <span>Import</span>
  </button>
  <button mat-button class="header-button" [matMenuTriggerFor]="downloadMenu">
    <mat-icon fontSet="fal" fontIcon="fa-download"></mat-icon>
    <span>Downloads</span>
  </button>
  <button mat-button class="header-button" (click)="sendFeedback()">
    <mat-icon fontSet="fal" fontIcon="fa-comments"></mat-icon>
    <span>Feedback</span>
  </button>
  <!--Issue #9546-->
  <button mat-button class="header-button" (click)="toggleHelp()">
    <mat-icon fontSet="fal" fontIcon="fa-question"></mat-icon>
    <span>Support</span>
  </button>
  <button mat-button class="header-button" [matMenuTriggerFor]="showNotifications">
    <mat-icon fontSet="fal" fontIcon="fa-bell" [matBadge]="notificationsCount > 0 ? notificationsCount : null" matBadgeSize="small" matBadgeColor="accent"></mat-icon>
    <span>Notifications</span>
  </button>
  <button mat-button class="header-button user-menu-button" [matMenuTriggerFor]="userMenu">
    <mat-icon fontSet="fal" fontIcon="fa-user-cog"></mat-icon>
    <span>Hi {{username}}</span>
  </button>

  <mat-menu #documentsMenu="matMenu" class="documents-menu">
    <button mat-menu-item *ngFor="let document of documentLinks" (click)="openDocument(document)">
      <mat-icon fontSet="fal" [fontIcon]="document.icon"></mat-icon>
      <span>{{document.documentTitle}}</span>
      <mat-icon fontSet="fas" fontIcon="fa-info-circle" class="info-icon" *ngIf="document.description"
                eeTooltip [tooltipDirection]="'right'" [tooltipText]="document.description" tooltipClass="wide-tooltip"></mat-icon>
    </button>
  </mat-menu>

  <mat-menu #downloadMenu="matMenu" class="user-menu">
    <button mat-menu-item *ngIf="documentLinks && documentLinks.length" [matMenuTriggerFor]="documentsMenu">
      <mat-icon fontSet="fal" fontIcon="fa-book"></mat-icon>
      <span>Documents</span>
    </button>
    <button mat-menu-item (click)="downloadSchoolList()">
      <mat-icon fontSet="fal" fontIcon="fa-school"></mat-icon>
      <span>School List</span>
    </button>
    <button mat-menu-item (click)="downloadProjectList()">
      <mat-icon fontSet="fal" fontIcon="fa-tasks"></mat-icon>
      <span>Project List</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="shapes">
      <mat-icon fontSet="fal" fontIcon="fa-vector-square"></mat-icon>
      <span>Geometry</span>
    </button>
  </mat-menu>

  <mat-menu #shapes="matMenu" class="user-menu sub-menu">
    <button mat-menu-item (click)="downloadGeometry(shape)" *ngFor="let shape of shapeDownloads">
      <mat-icon fontSet="fal" fontIcon="fa-vector-square"></mat-icon>
      <span>{{shape.displayName}}</span>
      <mat-icon fontSet="fas" fontIcon="fa-info-circle" class="info-icon"
                eeTooltip [tooltipDirection]="'right'" [tooltipText]="shape.tooltip" tooltipClass="wide-tooltip"></mat-icon>
    </button>

  </mat-menu>

  <mat-menu #userMenu="matMenu" class="user-menu">
    <button mat-menu-item *ngIf="isAdmin" (click)="navigateToAdmin()">
      <mat-icon fontSet="fal" fontIcon="fa-cog"></mat-icon>
      <span>Admin</span>
    </button>
    <button mat-menu-item *ngIf="username === 'Meowster'" (click)="showMakeUpMirror()">
      <mat-icon fontSet="fal" fontIcon="fa-lips"></mat-icon>
      <span>Makeup Mirror</span>
    </button>
    <button mat-menu-item *ngIf="isAdmin" (click)="toggleMapStats()">
      <mat-icon fontSet="fal" fontIcon="fa-cat"></mat-icon>
      <span>Toggle Map Stats</span>
    </button>
    <button mat-menu-item (click)="viewUserAgreement()">
      <mat-icon fontSet="fal" fontIcon="fa-clipboard-list-check"></mat-icon>
      <span>View User Agreement</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon fontSet="fal" fontIcon="fa-sign-out-alt"></mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>

  <div class="menu-scrollbar-container">
    <mat-menu #showNotifications="matMenu" class="notification-menu">
      <ee-notification-menu></ee-notification-menu>
    </mat-menu>
  </div>

</div>
