<div>
  <h1 mat-dialog-title>Session Expiring</h1>

  <div mat-dialog-content>
    Your session is about to expire due to inactivity. Click the 'Continue' button below to renew your session.
    <br><br>
    You will be automatically logged out in <span class="bold">{{remainingTime}}</span>.
  </div>

  <div mat-dialog-actions fxLayoutAlign="end end">
    <button mat-raised-button (click)="close(false)">Logout</button>
    <button mat-raised-button class="key-action" mat-dialog-close="true">Continue</button>
  </div>
</div>
