import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PlannedArea} from '../../interfaces/planned-area';
import {PlannedAreaMappingService} from '../planned-area-mapping/planned-area-mapping.service';
import {first, map, tap} from 'rxjs/operators';
import {PlannedAreaAnnotation} from '../../interfaces/planned-area-annotation';
import {
  PlannedAreaOptionsDefinitionsResolverService
} from '../../resolvers/planned-area-options-definitions-resolver/planned-area-options-definitions-resolver.service';
import {GeoreferenceResults} from '../../../shared/interfaces/georeference-results';
import {GapApiCommsService} from '../gap-api-comms/gap-api-comms.service';
import {ApiCommsService} from '../../../core/services/api-comms/api-comms.service';
import {TileyService} from '../../../shared/services/tiley/tiley.service';
import {saveAs} from 'file-saver';
import {FileExportType} from '../../../shared/enums/file-export-type';
import {PlannedAreaDetails} from '../../interfaces/planned-area-details';

@Injectable({
  providedIn: 'root'
})
export class PlannedAreaDataService {

  private selectedPlannedAreaUpdateSubject: BehaviorSubject<PlannedAreaDetails> = new BehaviorSubject<PlannedAreaDetails>(null);
  private georeferenceCompleteSubject: BehaviorSubject<GeoreferenceResults> = new BehaviorSubject<GeoreferenceResults>(null);
  private selectedPlannedArea: PlannedAreaDetails = null;

  constructor(
    private plannedAreaFieldDefinitions: PlannedAreaOptionsDefinitionsResolverService,
    private plannedAreaMappingService: PlannedAreaMappingService,
    private gapApiComms: GapApiCommsService,
    private apiComms: ApiCommsService,
    private tileyService: TileyService) {
  }

  savePlannedArea(plannedAreaDetails: PlannedAreaDetails, isNew: boolean): Observable<PlannedAreaDetails> {
    let paObservable: Observable<PlannedAreaDetails>;

    // Get update geometry
    const newGeom = this.plannedAreaMappingService.getModifiedGeometry();
    if (newGeom) {
      plannedAreaDetails.geometry = newGeom;
    }

    // Create new planned area
    if (isNew) {
      paObservable = this.gapApiComms.postPlannedArea(plannedAreaDetails);
    }
    // Update existing planned area
    else {
      const paId = plannedAreaDetails.paId;
      paObservable = this.gapApiComms.putPlannedArea(paId, plannedAreaDetails);
    }

    return paObservable
      .pipe(
        tap(paData => {
          if (paData) {
            const paId = paData.paId;
            this.selectedPlannedArea = paData;
            this.doPlannedAreaGeoreference(this.selectedPlannedArea);

            if (!isNew) {
              // Refresh map to reflect changes
              this.plannedAreaMappingService.setPlannedAreaView([paData], true);
            }

            // Update planned area data (e.g. name and update dates and person)
            this.selectedPlannedAreaUpdateSubject.next(paData);
          }
        })
      );
  }

  // Delete selected planned area
  deletePlannedArea(): Observable<boolean> {
    return this.gapApiComms.deletePlannedArea(this.selectedPlannedArea.paId)
      .pipe(
        map(data => {
            return data && data.archived;
        })
      );
  }

  // Set selected planned area
  setSelectedPlannedArea(plannedArea: PlannedAreaDetails): void {
    this.selectedPlannedArea = plannedArea;
    this.doPlannedAreaGeoreference(this.selectedPlannedArea);
    this.selectedPlannedAreaUpdateSubject.next(plannedArea);
  }

  onSelectedPlannedAreaUpdate(): BehaviorSubject<PlannedAreaDetails> {
    return this.selectedPlannedAreaUpdateSubject;
  }

  // Do planned area georeference
  doPlannedAreaGeoreference(plannedArea: PlannedArea): void {
    if (plannedArea) {
      this.tileyService.doGeoreference(plannedArea.geometry)
        .pipe(first())
        .subscribe(result => {
          if (result) {
            this.georeferenceCompleteSubject.next(result);
          }
        });
    } else {
      this.georeferenceCompleteSubject.next(null);
    }
  }

  onGeoreferenceComplete(): Observable<GeoreferenceResults> {
    return this.georeferenceCompleteSubject;
  }

  createNewPlannedArea(): void {
    this.selectedPlannedArea = {
      paId: null,
      name: 'New planned area',
      geometry: null,
      createdBy: null,
      createdDate: null,
      lastUpdatedBy: null,
      lastUpdatedDate: null,
      summary: null,
      dateReceived: null,
      typeId: null,
      responsibleAgencyId: null,
      rezoningStatusId: null,
      primNoOfStudents: null,
      primNoOfNewTeachingSpaces: null,
      primSensitivityAnalysis: null,
      primGovShare: null,
      secNoOfStudents: null,
      secNoOfNewTeachingSpaces: null,
      secSensitivityAnalysis: null,
      secGovShare: null,
      links: null,
      zoningDetails: null
    };
    this.selectedPlannedAreaUpdateSubject.next(this.selectedPlannedArea);
  }

  saveAnnotation(annotation: PlannedAreaAnnotation, isNew: boolean): Observable<PlannedAreaAnnotation> {
    if (this.selectedPlannedArea) {
      const paId = this.selectedPlannedArea.paId;

      if (isNew) {
        return this.gapApiComms.postPlannedAreaAnnotation(paId, annotation);
      } else {
        return this.gapApiComms.putPlannedAreaAnnotation(paId, annotation.annotId, annotation);
      }
    }
  }

  deleteAnnotation(annotId: number): Observable<boolean> {
    if (this.selectedPlannedArea) {
      const paId = this.selectedPlannedArea.paId;

      return this.gapApiComms.deletePlannedAreaAnnotation(paId, annotId)
        .pipe(
          map(data => {
            return data && data.archived;
          })
        );
    }
  }

  exportFile(type: FileExportType, name: string, paId: number) {
    this.gapApiComms.getFileExport(type, paId)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          const ext = (type === FileExportType.SHAPE_FILE) ? 'zip' : type;
          const filename = name.replace(/[^a-zA-Z0-9_]+/g, '_') + '.' + ext;

          saveAs(data, filename);
        }
      });
  }

  exportSpreadsheet(name: string, paId: number) {
    this.gapApiComms.exportSpreadsheet(paId)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          const filename = name.replace(/[^a-zA-Z0-9_]+/g, '_') + '.xlsx';

          saveAs(data, filename);
        }
      });
  }
}
