import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {NotificationPollingMessage} from '../../interfaces/notification-polling-message';
import {PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'ee-user-notifications-dialog',
  templateUrl: './user-notifications-dialog.component.html',
  styleUrls: ['./user-notifications-dialog.component.scss']
})
export class UserNotificationsDialogComponent implements OnInit {

  @ViewChild(PerfectScrollbarDirective) scrollContainer: PerfectScrollbarDirective;

  constructor(
    private dialogRef: MatDialogRef<UserNotificationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotificationPollingMessage
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
