import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MapObject} from '../../interfaces/map-object';
import * as _ from 'lodash';
import Mustache from 'mustache';
import * as moment from 'moment';

const TOOLTIP_DEFAULT_WIDTH = 240;

@Component({
  selector: 'ee-map-tooltip',
  templateUrl: './map-tooltip.component.html',
  styleUrls: ['./map-tooltip.component.scss']
})
export class MapTooltipComponent implements OnInit {
  mapObject: MapObject = null;
  visible = false;
  toolTipText: string = null;
  tooltipWidth: number;
  displayName: string = null;

  @ViewChild('mapTooltip', {static: true}) el: ElementRef;
  constructor() { }

  ngOnInit() {
  }

  set(mapObject: MapObject) {
    this.mapObject = mapObject;
  }

  show(mapObject: MapObject, tooltipText: string, displayName: string, tooltipWidth: number, featureProps: any) {
    const properties = _.clone(featureProps);

    // Parse any string number fields as numbers
    Object.keys(properties).forEach(key => {
      if (/^-?\d+(?:\.\d+)?$/.test(properties[key])) {
        properties[key] = Number(properties[key]);

        if (properties[key] === 0) {
          properties[key] = '0';
        }
      }
    });

    properties['ee-number'] = function() {
      return function (text, render) {
        const result = render(text);
        return /^-?\d+(?:\.\d+)?$/.test(result) ? Number(result).toLocaleString() : result;
      };
    };

    properties['ee-date'] = function() {
      return function (text, render) {
        const result = render(text);
        return result && moment(result).isValid() ? moment(result).format('DD/MM/YYYY') : result;
      };
    };

    properties['ee-timestamp'] = function() {
      return function (text, render) {
        const result = render(text);
        return result && moment(Number(result)).isValid() ? moment(Number(result)).format('DD/MM/YYYY h:mm a') : result;
      };
    };

    properties['ee-currency'] = function() {
      return function (text, render) {
        const result = render(text);
        return result && !isNaN(result) ? Number(result).toLocaleString() : result;
      };
    };

    this.tooltipWidth = tooltipWidth || TOOLTIP_DEFAULT_WIDTH ;
    this.displayName = displayName;
    this.toolTipText = Mustache.render(tooltipText, properties);
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

}
