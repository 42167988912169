import {Injectable} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Chart} from 'highcharts';
import {TransportTripResult} from '../../interfaces/transport-trip-result';
import PatternFill from 'highcharts/modules/pattern-fill';
import {TransportTripModeClass} from '../../enums/transport-trip-mode-class';
import * as _ from 'lodash';
import * as moment from 'moment';
import {parseDuration, parseLength} from '../../../utils/parse-values';
import {TransportTripModeColor} from '../../enums/transport-trip-mode-color';

PatternFill(Highcharts);

@Injectable({
  providedIn: 'root'
})
export class MapMeasureTransportChartService {

  private readonly chartModeDefs = {
    walk: {
      name: 'Walk',
      color: {
        pattern: {
          path: {
            d: 'M 0 0 L 6 6 M 5 -1 L 7 1 M -1 5 L 1 7',
            strokeWidth: 3
          },
          height: 6,
          width: 6,
          r: 4,
          color: TransportTripModeColor.WALKING_A
        }
      },
      dataLabels: {
        className: 'walk-data-label'
      }
    },
    train: {
      name: 'Train',
      color: TransportTripModeColor.TRAIN
    },
    bus: {
      name: 'Bus',
      color: TransportTripModeColor.BUS
    },
    lightRail: {
      name: 'Light Rail',
      color: TransportTripModeColor.LIGHT_RAIL
    },
    metro: {
      name: 'Metro',
      color: TransportTripModeColor.METRO
    },
    ferry: {
      name: 'Ferry',
      color: TransportTripModeColor.FERRY
    },
    coach: {
      name: 'Coach',
      color: TransportTripModeColor.COACH
    },
    schoolBus: {
      name: 'School Bus',
      color: TransportTripModeColor.SCHOOL_BUS
    },
    taxi: {
      name: 'Taxi',
      color: TransportTripModeColor.TAXI
    }
  };

  private chart: Chart = null;
  private chartOptions: any = {
    chart: {
      className: 'measure-panel-transport-chart',
      // styledMode: true,
      spacingBottom: 0,
      defaultSeriesType: 'bar',
      margin: [0, 0, 14, 0],
      events: {
        load: function () {
          // Hide data labels if there is not enough space to fit
          const imgWidth = 14;
          const imgHeight = 14;

          this.series.forEach(
            (series) => {
              series.data.forEach(
                (data) => {
                  if (data && data.dataLabel) {
                    if (data.shapeArgs) {
                      if (data.shapeArgs.height - 4 > imgWidth) {
                        let chartIcon = '';
                        switch (data.series.name) {
                          case 'Train':
                            chartIcon = '/assets/images/transport-icons/train-mode.svg';
                            break;
                          case 'Bus':
                            chartIcon = '/assets/images/transport-icons/bus-mode.svg';
                            break;
                          case 'Walk':
                            chartIcon = '/assets/images/transport-icons/walk-mode.svg';
                            break;
                          case 'Ferry':
                            chartIcon = '/assets/images/transport-icons/ferry-mode.svg';
                            break;
                          case 'Light Rail':
                            chartIcon = '/assets/images/transport-icons/light-rail-mode.svg';
                            break;
                          case 'School Bus':
                            chartIcon = '/assets/images/transport-icons/school-bus-mode.svg';
                            break;
                          case 'Metro':
                            chartIcon = '/assets/images/transport-icons/metro-mode.svg';
                            break;
                          case 'Coach':
                            chartIcon = '/assets/images/transport-icons/coach-mode.svg';
                        }

                        const x = data.dataLabel.alignAttr.x;
                        const y = data.dataLabel.alignAttr.y + imgHeight + 6;

                        this.renderer.image(chartIcon, x, y, imgWidth, imgHeight).attr({
                          zIndex: 3
                        }).add();
                      }

                      // Hide data labels if they do not fit
                      if (data.dataLabel.width - 4 > data.shapeArgs.height) {
                        data.dataLabel.hide();
                      }
                    }
                  }
                }
              );
            }
          );
        }
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    yAxis: {
      visible: false,
      endOnTick: false,
      reversedStacks: false
    },
    xAxis: {
      visible: false,
      endOnTick: false
    },
    plotOptions: {
      bar: {
        stacking: 'percent',
        shadow: false,
        dataLabels: {
          align: 'left',
          enabled: true,
          useHTML: true,
          formatter: function() {
            return Highcharts.numberFormat(this.y, 0) + 'mins';
          }
        }
      }
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      outside: true,
      shared: false,
      headerFormat: '',
      useHTML: true,
      backgroundColor: '#FFFFFF',
      borderColor: '#E5EAF1',
      shadow: false,
      style: {
        width: '270px'
      },
      formatter: function() {
        const data = this.point.extra;

        let mode = '';
        let color = null;
        let includeHeader = true;

        switch (data.modeClass) {
          case TransportTripModeClass.TRAIN:
            mode = 'Train';
            color = TransportTripModeColor.TRAIN;
            break;
          case TransportTripModeClass.BUS:
            mode = 'Bus';
            color = TransportTripModeColor.BUS;
            break;
          case TransportTripModeClass.SCHOOL_BUS:
            mode = 'School Bus';
            color = TransportTripModeColor.SCHOOL_BUS;
            break;
          case TransportTripModeClass.LIGHT_RAIL:
            mode = 'Light Rail';
            color = TransportTripModeColor.LIGHT_RAIL;
            break;
          case TransportTripModeClass.METRO:
            mode = 'Metro';
            color = TransportTripModeColor.METRO;
            break;
          case TransportTripModeClass.FERRY:
            mode = 'Ferry';
            color = TransportTripModeColor.FERRY;
            break;
          case TransportTripModeClass.COACH:
            mode = 'Coach';
            color = TransportTripModeColor.COACH;
            break;
          case TransportTripModeClass.WALKING_A:
            mode = 'Walking';
            color = TransportTripModeColor.WALKING_A;
            includeHeader = false;
            break;
          case TransportTripModeClass.WALKING_B:
            mode = 'Walking';
            color = TransportTripModeColor.WALKING_B;
            includeHeader = false;
            break;
          case TransportTripModeClass.TAXI:
            mode = 'Taxi';
            color = TransportTripModeColor.TAXI;
            includeHeader = false;
            break;
        }

        const departureTime = moment(data.departureTime).format('h:mm a');
        const arrivalTime = moment(data.arrivalTime).format('h:mm a');
        const duration = parseDuration(data.duration);
        const distance = parseLength(data.distance);

        return `
          <div class="transport-results-tooltip">
            <div class="mode">${mode}</div>
            ${includeHeader ? `<div class="header">
              <div class="number" style="background-color: ${color};">${data.transportationNumber}</div>
              <div class="description">
                <div class="title" style="color: ${color};">${data.transportationDescription}</div>
                <div class="subtitle">(${data.transportationOperator})</div>
              </div>
            </div>` : ``}
            <div class="row">
              <div class="header">Departing</div>
              <div>${departureTime} from ${data.originName}</div>
            </div>
            <div class="row">
              <div class="header">Arriving</div>
              <div>${arrivalTime} from ${data.destinationName}</div>
            </div>
            <div class="row">
              <div class="header">Duration</div>
              <div>${duration}</div>
            </div>
            <div class="row">
              <div class="header">Distance</div>
              <div>${distance}</div>
            </div>
          </div>
        `;
      }
    },
    series: [],
    exporting: {
      enabled: false
    }
  };

  constructor() { }

  generateChart(container: string, result: TransportTripResult) {
    this.destroyChart();

    this.chartOptions.series = [];

    result.legs.forEach(
      leg => {
        let series = null;

        switch (leg.modeClass) {
          case TransportTripModeClass.TRAIN:
            series = _.cloneDeep(this.chartModeDefs.train);
            break;
          case TransportTripModeClass.BUS:
            series = _.cloneDeep(this.chartModeDefs.bus);
            break;
          case TransportTripModeClass.SCHOOL_BUS:
            series = _.cloneDeep(this.chartModeDefs.schoolBus);
            break;
          case TransportTripModeClass.LIGHT_RAIL:
            series = _.cloneDeep(this.chartModeDefs.lightRail);
            break;
          case TransportTripModeClass.FERRY:
            series = _.cloneDeep(this.chartModeDefs.ferry);
            break;
          case TransportTripModeClass.METRO:
            series = _.cloneDeep(this.chartModeDefs.metro);
            break;
          case TransportTripModeClass.COACH:
            series = _.cloneDeep(this.chartModeDefs.coach);
            break;
          case TransportTripModeClass.WALKING_A:
            series = _.cloneDeep(this.chartModeDefs.walk);
            break;
          case TransportTripModeClass.WALKING_B:
            series = _.cloneDeep(this.chartModeDefs.walk);
            break;
          case TransportTripModeClass.TAXI:
            series = _.cloneDeep(this.chartModeDefs.taxi);
            break;
        }

        if (series) {
          series.data = [{
            y: leg.duration / 60,
            extra: leg
          }];
          this.chartOptions.series.push(series);
        }
      }
    );

    this.chart = Highcharts.chart(container, this.chartOptions);
  }

  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }
}
