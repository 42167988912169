import { Injectable } from '@angular/core';
import {MapBasemap} from '../../../shared/interfaces/map-basemap';
import {ApiCommsService} from '../../services/api-comms/api-comms.service';
import {MapBasemapCategory} from '../../../shared/interfaces/map-basemap-category';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {MapBasemapName} from '../../../shared/enums/map-basemap-name';

@Injectable({
  providedIn: 'root'
})
export class MapBasemapResolverService implements Resolve<MapBasemapCategory[]> {

  private basemapCategories: MapBasemapCategory[] = null;
  private defaultBasemap: MapBasemap = null;

  private onBasemapDataReadySubject: BehaviorSubject<MapBasemapCategory[]> =
    new BehaviorSubject<MapBasemapCategory[]>(null);

  constructor(
    private apiCommsService: ApiCommsService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MapBasemapCategory[]> {
    return this.apiCommsService.getBasemaps()
      .pipe(
        tap(result => {
          if (result) {
            this.basemapCategories = result;

            for (const cat of this.basemapCategories) {
              for (const basemap of cat.basemaps) {
                if (basemap.default) {
                  this.defaultBasemap = basemap;
                  break;
                }
              }
            }

            this.onBasemapDataReadySubject.next(this.basemapCategories);
          }
        }),
        catchError(error => {
          console.log(error);
          return new Observable<MapBasemapCategory[]>(null);
        })
      );
  }

  onBasemapDataReady(): Observable<MapBasemapCategory[]> {
    return this.onBasemapDataReadySubject;
  }

  getDefaultBasemap(): MapBasemap {
    return this.defaultBasemap;
  }

  getBasemapByName(basemapName: MapBasemapName): MapBasemap {
    let result: MapBasemap = null;

    for (const cat of this.basemapCategories) {
      for (const basemap of cat.basemaps) {
        if (basemap.basemapName === basemapName) {
          result = basemap;
          break;
        }
      }
    }

    return result;
  }
}
