import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {PerfectScrollbarComponent, PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'ee-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(PerfectScrollbarDirective) scrollContainer: PerfectScrollbarDirective ;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      console.log('scroll container', this.scrollContainer);
      this.scrollContainer.update();
      this.scrollContainer.config.suppressScrollY = false;
    });
  }

  close(val: boolean) {
    this.dialogRef.close(val);
  }

}
