import { Injectable } from '@angular/core';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadDisclaimerService {

  constructor(
    private confirmDialogService: ConfirmationDialogService
  ) { }

  showDownloadDisclaimer(downloadFunction: () => void) {
    const hasAgreed = localStorage.getItem('agreedToDownloadDisclaimer');

    if (hasAgreed) {
      if (downloadFunction) {
        downloadFunction();
      }
    } else {
      this.showDialog(downloadFunction);
    }
  }

  private showDialog(downloadFunction) {
    this.confirmDialogService.showDialog(
      'Download Disclaimer',
      `Eagle Eye presents a range of data for analytical purpose. There are strict data privacy and governance stipulations that \
      pertain to this data. This data may not be shared, published, communicated in any form without written consent from School \
      Infrastructure NSW.<br><br> \
      By downloading this data you are confirming your acceptance of the above. User activity is tracked across Eagle Eye and you \
      consent to your download activity being included in periodical reporting of usage.<br><br> \
      School Infrastructure NSW recommends that users exercise their own skill and care with respect to their use of this application and \
      the data contained herein and that users carefully evaluate the accuracy, currency, completeness and relevance of the material for \
      their purposes.<br><br> \
      This data is not a substitute for independent professional advice and users should obtain any appropriate professional advice \
      relevant to their particular circumstances.`,
      'Agree and Download',
      null,
      true
    )
      .subscribe(val => {
        if (val && downloadFunction) {
          localStorage.setItem('agreedToDownloadDisclaimer', 'true');
          downloadFunction();
        }
      });
  }

}
