import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {InfoDialogComponent} from '../../components/info-dialog/info-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class InfoDialogService {

  constructor(
    public dialog: MatDialog,
    private sanitized: DomSanitizer
  ) { }

  showDialog(width: number, icon: string, title: string, message: string, closeCallback?: Function): void {
    // Wait until DOM is ready
    setTimeout(() => {
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        width: `${width}px`,
        data: {
          icon: icon,
          title: title,
          message: this.sanitized.bypassSecurityTrustHtml(message),
          autoFocus: false
        }
      });

      dialogRef.afterClosed().subscribe(val => {
        if (closeCallback) {
          closeCallback(val);
        }
      });
    });
  }
}
