<div class="dialog">
  <h1 mat-dialog-title>Customise Map Export</h1>
  <button mat-icon-button class="close-x-button" (click)="close()">
    <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>
  </button>

  <form [formGroup]="exportSettings" (ngSubmit)="onSubmit()">
    <mat-dialog-content>

      <div fxLayout="column" class="storm-input-container vertical">
        <label class="title">Title (Optional)</label>
        <mat-form-field class="storm-page-input" appearance="none">
          <input class="input-cell" matInput formControlName="title">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="storm-input-container vertical">
        <label class="title">Subtitle (Optional)</label>
        <mat-form-field class="storm-page-input" appearance="none">
          <textarea class="text-area short" matInput formControlName="subtitle"></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="column" class="storm-input-container vertical">
        <label class="title">Filename (Optional)</label>
        <mat-form-field class="storm-page-input" appearance="none">
          <input class="input-cell" matInput formControlName="filename">
        </mat-form-field>
      </div>

    </mat-dialog-content>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="key-action green"
              type="submit">Export</button>
    </div>
  </form>
</div>
