export enum MapReferenceLayerDefs {
  GOV_SCHOOLS = 'gov_schools',
  NON_GOV_SCHOOLS = 'non_gov_schools',
  PRIMARY_SCHOOL_CLUSTERS = 'primary_school_clusters',//no change  for #12120 as layer name is not changed
  SECONDARY_SCHOOL_CLUSTERS = 'secondary_school_clusters',
  SSP_SCHOOL_CLUSTERS = 'ssp_school_clusters',
  LGAS = 'lgas',
  SUBURBS = 'suburbs',
  SED_REGIONS = 'sed_regions',
  FED_REGIONS = 'fed_regions',
  DET_REGIONS = 'det_regions',
  NSW_DISTRICTS = 'nsw_districts',
  PRINCIPAL_NETWORKS = 'doe_principal_networks',
  MESH_BLOCKS = 'mesh_blocks_2021',
  CADASTRE_LOTS = 'cadastre',
  VEGETATION = 'vegetation',
  PLANNED_AREAS = 'planned_areas',
  GROWTH_CENTERS = 'growth_centers',
  GREENFIELD_AREAS = 'greenfield_areas',
  NEW_SCHOOLS = 'new_schools',
  SCHOOL_UPGRADES = 'upgrade_schools',
  SCHOOLS_3D = '3d_schools',
  SITE_BOUNDARY = 'site_boundary',
  ENROLMENT_LOCATIONS = 'enrolment_locations',
  SCHOOL_CATCHMENTS = 'school_catchments', // UTILITY LAYER
  SCHOOL_CATCHMENTS_PROPOSED = 'school_catchments_proposed', // UTILITY LAYER
  PRIMARY_CATCHMENT = 'primary_catchments',
  PRIMARY_CENTRAL_CATCHMENT = 'primary_central_catchments',
  SECONDARY_GIRLS_CATCHMENT = 'secondary_girls_catchments',
  SECONDARY_BOYS_CATCHMENT = 'secondary_boys_catchments',
  SECONDARY_COED_CATCHMENT = 'secondary_coed_catchments',
  SECONDARY_CENTRAL_CATCHMENT = 'secondary_central_catchments',
  INFANTS_CATCHMENT = 'infant_catchments',
  PRIMARY_CATCHMENT_PROPOSED = 'primary_catchments_proposed',
  PRIMARY_CENTRAL_CATCHMENT_PROPOSED = 'primary_central_catchments_proposed',
  SECONDARY_GIRLS_CATCHMENT_PROPOSED = 'secondary_girls_catchments_proposed',
  SECONDARY_BOYS_CATCHMENT_PROPOSED = 'secondary_boys_catchments_proposed',
  SECONDARY_COED_CATCHMENT_PROPOSED = 'secondary_coed_catchments_proposed',
  SECONDARY_CENTRAL_CATCHMENT_PROPOSED = 'secondary_central_catchments_proposed',
  INFANTS_CATCHMENT_PROPOSED = 'infant_catchments_proposed',
  UTILITY_HOUSING_SUPPLY_FORECAST = 'dpe_housing_supply_forecast_utilities',
  CATCHMENT_CAPACITY_PRIMARY_2024 = 'viz_live_in_projection_capacity_primary_2024',
  CATCHMENT_CAPACITY_SECONDARY_2024 = 'viz_live_in_projection_capacity_secondary_2024',
  CATCHMENT_CAPACITY_PRIMARY_2027 = 'viz_live_in_projection_capacity_primary_2027',//#9724
  CATCHMENT_CAPACITY_SECONDARY_2027 = 'viz_live_in_projection_capacity_secondary_2027',//#9724
  CATCHMENT_CAPACITY_PRIMARY_2036 = 'viz_live_in_projection_capacity_primary_2036',
  CATCHMENT_CAPACITY_SECONDARY_2036 = 'viz_live_in_projection_capacity_secondary_2036',
  CATCHMENT_CAPACITY_PRIMARY_2041 = 'viz_live_in_projection_capacity_primary_2041',//#9724
  CATCHMENT_CAPACITY_SECONDARY_2041 = 'viz_live_in_projection_capacity_secondary_2041',//#9724
  SA1_BOUNDARIES = 'sa1_boundaries_2021',
  SA2_BOUNDARIES = 'sa2_boundaries_2021',
  SA3_BOUNDARIES = 'sa3_boundaries_2021',
  SA4_BOUNDARIES = 'sa4_boundaries_2021',
  OPERATIONAL_DIRECTORATES = 'doe_operational_directorates',
  DPIE_REGIONS = 'dpie_regions',
  SCHOOL_FLOORPLANS = 'ams_floor_plans_utility', // UTILITY LAYER
  SCHOOL_CATCHMENTS_COMPLETE_UTILITY = 'school_catchments_complete', // UTILITY LAYER
  SCHOOL_CLUSTERS_UTILITY = 'school_clusters_utility', // UTILITY LAYER
  CATCHMENT_HISTORY_ACTUAL = 'school_catchment_audit_actuals', // UTILITY LAYER
  CATCHMENT_HISTORY_FUTURE = 'school_catchment_audit_future',
  EARLY_CHILDHOOD_SITES_SERVICE_TYPE = 'early_childhood_sites_by_service_type'
}
