import {Component, OnDestroy, OnInit} from '@angular/core';
import {delay, first, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NotificationPollingService} from '../../../shared/services/notification-polling/notification-polling.service';
import {NotificationPollingMessage} from '../../interfaces/notification-polling-message';
import {UserNotificationType} from '../../enums/user-notification-type';
import {AdminApiCommsService} from '../../../admin/services/admin-api-comms/admin-api-comms.service';
import {AppRoutes} from '../../enums/app-routes';
import {VisionAppRoutes} from '../../../vision/enums/vision-app-routes';
import {Router} from '@angular/router';
import {UserNotificationDialogService} from '../../services/user-notification-dialog/user-notification-dialog.service';
import {UserNotificationCategories} from '../../enums/user-notification-categories';
import {HubRoutes} from '../../../hub/enums/hub-routes';

@Component({
  selector: 'ee-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements OnInit, OnDestroy {

  ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  activeNotification: NotificationPollingMessage = null;

  userNotificationsData: NotificationPollingMessage[] = [];
  unreadNotificationData: NotificationPollingMessage[] = [];
  readNotificationData: NotificationPollingMessage[] = [];

  UserNotificationTypeEnum = UserNotificationType;

  hideMaintenanceNotifications = false;

  wheelTimeout = null;
  scrollingTimeout = null;
  endHit = false;
  wheelSum = 0;
  updating = false;
  teaseUpdate = false;

  currentOffset = 0;
  private readonly offsetIncrement = 50;

  constructor(
    private adminApiComms: AdminApiCommsService,
    private notificationPollService: NotificationPollingService,
    private userNotificationDialogService: UserNotificationDialogService,
    private router: Router
  ) { }

  ngOnInit() {
    this.notificationPollService.startPolling();
    this.onNotificationsDataUpdate();
  }

  ngOnDestroy() {
    this.notificationPollService.stopPolling();
  }

  onNotificationsDataUpdate() {
    this.notificationPollService.onUpdate()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.processNewUserNotificationData(data, true);
      });
  }

  processNewUserNotificationData(data: NotificationPollingMessage[], front?: boolean) {
    const currentNotificationIds = this.userNotificationsData.map(m => m.notificationId);

    if (data) {
      const newData = data.filter(m => !currentNotificationIds.includes(m.notificationId));
      const existingData = data.filter(m => currentNotificationIds.includes(m.notificationId));

      if (front) {
        this.userNotificationsData.unshift(...newData);
      } else {
        this.userNotificationsData.push(...newData);
      }

      for (const n of existingData) {
        const index = this.userNotificationsData.findIndex(m => m.notificationId === n.notificationId);

        if (index > -1) {
          this.userNotificationsData[index] = n;
        }
      }
    }

    this.unreadNotificationData = this.userNotificationsData.filter((notification) => !(notification.hasRead));
    this.readNotificationData = this.userNotificationsData.filter((notification) => notification.hasRead);

    this.checkAndShowAutoNotifications();
    this.filterReadNotifications();
  }

  checkAndShowAutoNotifications() {
    const forceShowNotifications = this.userNotificationsData
      .filter(n => !n.hasRead && n.forceShow && n.type === UserNotificationType.NEWS && !!n.detail);

    if (forceShowNotifications && forceShowNotifications.length > 0) {
      forceShowNotifications.forEach(n => {
        this.readNotifications([n.notificationId]);
        this.showUserNotificationDialog(n);
      });
    }
  }

  readNotifications(notifications: number[]) {
    this.adminApiComms.markNotificationAsRead(notifications)
      .pipe(first())
      .subscribe(() => {
        this.notificationPollService.getUserNotifications().subscribe();
      });
  }

  setActiveNotification(notification: NotificationPollingMessage) {
    this.activeNotification = notification;
  }

  clearActiveNotification() {
    this.activeNotification = null;
  }

  performNotificationAction(notification: NotificationPollingMessage) {
    if (!notification.hasRead) {
      this.readNotifications([notification.notificationId]);
    }

    if (notification.type === UserNotificationType.NEWS && notification.detail) {
      this.showUserNotificationDialog(notification);
    } else if ((notification.type === UserNotificationType.VISION_REVIEW_ASSIGNMENT ||
      notification.type === UserNotificationType.VISION_STATUS_DRAFT ||
      notification.type === UserNotificationType.VISION_STATUS_FINALISED) &&
      notification.metadata && notification.metadata.reportId) {
      this.router.navigate([AppRoutes.VISION, VisionAppRoutes.REPORTS, notification.metadata.reportId]);
    } else if (notification.type === UserNotificationType.VISION_USER_ASSIGNMENT) {
      this.router.navigate([AppRoutes.VISION, VisionAppRoutes.REPORTS, notification.metadata.reportId]);
    } else if (notification.type === UserNotificationType.VISION_REVIEW_COMMENT ||
      notification.type === UserNotificationType.VISION_ENDORSEMENT_ASSIGNMENT) {
      this.router.navigate([AppRoutes.VISION, VisionAppRoutes.REPORTS, notification.metadata.reportId,
        VisionAppRoutes.REPORT_REVIEW]);
    } else if (notification.type === UserNotificationType.SCHOOL_ALERT && notification.metadata &&
      notification.metadata.schoolId) {
      this.router.navigate([AppRoutes.HUB, HubRoutes.SCHOOL, notification.metadata.schoolId]);
    }
  }

  showUserNotificationDialog(notification: NotificationPollingMessage) {
    this.userNotificationDialogService.showDialog(notification);
  }

  markAllAsRead() {
    const unreadNotificationIds = this.userNotificationsData
      .filter((notification) => !(notification.hasRead))
      .map(n => n.notificationId);

    this.readNotifications(unreadNotificationIds);
  }

  filterReadNotifications() {
    if (this.readNotificationData) {
      this.readNotificationData.forEach(d => {
        if (d.category.category === UserNotificationCategories.MAINTENANCE) {
          d.hidden = this.hideMaintenanceNotifications;
        }
      });
    }
  }

  onScroll(event: any) {
    if (!this.endHit && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.scrollingTimeout) {
        clearTimeout(this.scrollingTimeout);
        this.scrollingTimeout = null;
      } else {
        this.scrollingTimeout = setTimeout(() => {
          this.endHit = true;
          this.scrollingTimeout = null;
        });
      }
    }
  }

  onWheel(event: any) {
    if (this.endHit && event.wheelDelta < 0 && !this.updating) {
      this.wheelSum += -event.wheelDelta;
      if (this.wheelTimeout) {
        clearTimeout(this.wheelTimeout);
        this.wheelTimeout = null;
      }
      this.wheelTimeout = setTimeout(() => {
        this.wheelSum = 0;
        this.wheelTimeout = null;
      }, 50);

      if (this.wheelSum > 250 && this.wheelSum <= 1000 && !this.teaseUpdate && !this.updating) {
        this.teaseUpdate = true;
        setTimeout(() => {
          this.teaseUpdate = false;
          this.wheelSum = 0;
        }, 500);
      }

      if (this.wheelSum > 1000 && !this.updating) {
        this.fetchMoreNotifications();
      }
    } else if (event.wheelDelta > 0) {
      this.endHit = false;
    }
  }

  private fetchMoreNotifications() {
    this.updating = true;
    this.currentOffset += this.offsetIncrement;

    this.notificationPollService.getUserNotifications(this.currentOffset)
      .pipe(first(), delay(1000))
      .subscribe(data => {
        if (data) {
          this.processNewUserNotificationData(data, false);
        } else {
          this.currentOffset -= this.offsetIncrement;
        }

        setTimeout(() => {
          this.updating = false;
          this.wheelSum = 0;
        });
      });
  }

}
