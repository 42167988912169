/// <reference types="@types/geojson" />

import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {
  GeoJSONSource, GeoJSONSourceRaw, VectorSource, Marker, Layer, Map, MapboxGeoJSONFeature, LngLat, LngLatBounds,
  LngLatLike, Style, RasterSource, FitBoundsOptions
} from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';
import {MapObject} from '../../interfaces/map-object';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import modes from '@mapbox/mapbox-gl-draw/src/modes';
import {MapboxDrawModes} from '../../enums/mapbox-draw-modes';
import {MapImage} from '../../interfaces/map-image';
import {BehaviorSubject, Subject} from 'rxjs';
import * as moment from 'moment';
import MeasureLineMode from './custom-modes/measure-line';
import MeasurePolygonMode from './custom-modes/measure-polygon';
import MeasureRadiusMode from './custom-modes/measure-radius';
import {
  MeasureTravelWalkingMode, MeasureTravelCyclingMode, MeasureTravelDrivingMode, MeasureTravelTransportMode
} from './custom-modes/measure-travel';
import DirectSelectMode from './custom-modes/direct-select';
import {MapEditMode} from '../../enums/map-edit-mode';
import {MapBasemap} from '../../interfaces/map-basemap';
import {Feature, GeoJSON, Geometry, LineString, MultiPolygon, Polygon} from 'geojson';
import {MapPsuedoLayers} from '../../enums/map-psuedo-layers';
import {MapSource} from '../../interfaces/map-source';
import {MapEvent} from '../../interfaces/map-event';
import {MapBasemapType} from '../../enums/map-basemap-type';
import {MapBasemapService} from '../map-basemap/map-basemap.service';
import * as polyline from '@mapbox/polyline';
import * as _ from 'lodash';
import {createDisplayCircle} from './custom-modes/shared';
import {MapMeasureMode} from '../../enums/map-measure-mode';
import {first} from 'rxjs/operators';
import {GeoreferenceResults} from '../../interfaces/georeference-results';
import {LoadingService} from '../../../core/services/loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {

  private mapReadySubject: Subject<string> = new Subject<string>();
  private mapsPool: MapObject[] = [];

  private readonly psuedoLayerDef: any = {
    'type': 'symbol',
    'metadata': {
      'type': 'custom'
    },
    'source': {
      'type': 'geojson',
      'data': {
        type: 'FeatureCollection',
        features: []
      }
    }
  };

  private psuedoLayerTop: Layer = Object.assign({
    'id': MapPsuedoLayers.PSUEDO_LAYER_TOP,
  }, this.psuedoLayerDef);

  private psuedoLayerSchoolEnrolment: Layer = Object.assign({
    'id': MapPsuedoLayers.PSUEDO_LAYER_SCHOOL_ENROLMENT,
  }, this.psuedoLayerDef);

  private psuedoLayerSchoolCatchment: Layer = Object.assign({
    'id': MapPsuedoLayers.PSUEDO_LAYER_SCHOOL_CATCHMENT,
  }, this.psuedoLayerDef);

  private psuedoLayerSchoolBoundary: Layer = Object.assign({
    'id': MapPsuedoLayers.PSUEDO_LAYER_SCHOOL_BOUNDARY,
  }, this.psuedoLayerDef);

  private psuedoLayerRef: Layer = Object.assign({
    'id': MapPsuedoLayers.PSUEDO_LAYER_REF
  }, this.psuedoLayerDef);

  private psuedoLayerReachabilityResults: Layer = Object.assign({
    'id': MapPsuedoLayers.PSUEDO_LAYER_REACHABILITY_RESULTS
  }, this.psuedoLayerDef);

  private psuedoLayerReachabilityMarker: Layer = Object.assign({
    'id': MapPsuedoLayers.PSUEDO_LAYER_REACHABILITY_MARKER
  }, this.psuedoLayerDef);

  private psuedoContourLayer: Layer = Object.assign({
    'id': MapPsuedoLayers.PSUEDO_CONTOUR_LAYER
  }, this.psuedoLayerDef);

  constructor(
    private mapBasemapService: MapBasemapService,
    private loadingService: LoadingService
  ) {
    this.initialiseMapboxService();
  }

  initialiseMapboxService() {
    (mapboxgl as any).accessToken = environment.mapboxAccessToken;
  }

  onMapReady(): Subject<string> {
    return this.mapReadySubject;
  }

  getMapObjectById(containerId: string): MapObject {
    let mapObject: MapObject = null;

    for (const item of this.mapsPool) {
      if (item.id === containerId) {
        mapObject = item;
        break;
      }
    }

    return mapObject;
  }

  onMapEdit(mapObject: MapObject): BehaviorSubject<MapEditMode> {
    return mapObject.onMapEdit;
  }

  createMap(containerId: string, showAttribution: boolean, basemap: MapBasemap, interactive: boolean,
            callback: Function): MapObject {
    let mapObject = this.getMapObjectById(containerId);

    if (mapObject) {
      const mapContainer: HTMLElement = document.getElementById(containerId);
      const parent: HTMLElement = mapContainer.parentElement;
      parent.removeChild(mapContainer);
      parent.appendChild(mapObject.map.getContainer());

      callback(mapObject);
    } else {
      let style: Style | string = 'mapbox://styles/mapbox/streets-v10';

      if (basemap) {
        if (basemap.styleUrl) {
          style = basemap.styleUrl;
        } else {
          style = basemap.styleJson;
        }
      }

      const map = new mapboxgl.Map({
        container: containerId,
        style: style,
        center: [147.41, -33.2],
        zoom: 5.5,
        // attributionControl: showAttribution,
        attributionControl: false,
        interactive: interactive,
      });

      const scaleControl = new mapboxgl.ScaleControl({
        maxWidth: 100,
        unit: 'metric'
      });

      map.addControl(scaleControl, 'bottom-left');

      const attributionControl = new mapboxgl.AttributionControl({
        customAttribution: ''
      });

      map.addControl(attributionControl, 'bottom-right');

      mapObject = {
        map: map,
        id: containerId,
        attributionControl: attributionControl,
        editMode: null,
        mapEvents: [],
        currentStyle: {
          layers: [],
          sources: [],
          images: []
        },
        tooltipEnabled: true,
        onSetPosition: new Subject<LngLatLike>(),
        onRotateChange: new BehaviorSubject<number>(0),
        onZoomChange: new BehaviorSubject<number>(map.getZoom()),
        currentBasemap: basemap,
        measurementReady: new Subject<boolean>(),
        onMapEdit: new BehaviorSubject<MapEditMode>(null),
        onReferenceLayersReady: new BehaviorSubject<Boolean>(null),
        onReferenceLayersChanged: new BehaviorSubject<Boolean>(null),
        onForceSearchClear: new Subject<boolean>(),
        onNearmapImageryDates: new BehaviorSubject<string[]>([]),
        onBasemapChange: new BehaviorSubject<boolean>(false),
        mapInspectorObject: {
          onVisibilityChange: new Subject<boolean>(),
          onGeoreferenceUpdate: new Subject<GeoreferenceResults>(),
          visible: false,
        },
        mapReachabilityObject: {
          onVisibilityChange: new BehaviorSubject<boolean>(false),
          onResultsUpdate: new BehaviorSubject<GeoJSON.FeatureCollection>(null),
          visible: false
        },
        contoursEnabled: false
      };

      this.mapsPool.push(mapObject);

      mapObject.map.once('styledata', () => {
        this.createEvents(mapObject, callback);
        mapObject.map.addLayer(this.psuedoLayerTop);
        mapObject.map.addLayer(this.psuedoLayerReachabilityMarker, MapPsuedoLayers.PSUEDO_LAYER_TOP);
        mapObject.map.addLayer(this.psuedoLayerSchoolEnrolment, MapPsuedoLayers.PSUEDO_LAYER_REACHABILITY_MARKER);
        mapObject.map.addLayer(this.psuedoLayerSchoolBoundary, MapPsuedoLayers.PSUEDO_LAYER_SCHOOL_ENROLMENT);
        mapObject.map.addLayer(this.psuedoLayerSchoolCatchment, MapPsuedoLayers.PSUEDO_LAYER_SCHOOL_BOUNDARY);
        mapObject.map.addLayer(this.psuedoLayerRef, MapPsuedoLayers.PSUEDO_LAYER_SCHOOL_CATCHMENT);
        mapObject.map.addLayer(this.psuedoLayerReachabilityResults, MapPsuedoLayers.PSUEDO_LAYER_REF);
        mapObject.map.addLayer(this.psuedoContourLayer, MapPsuedoLayers.PSUEDO_LAYER_REACHABILITY_RESULTS);
        this.mapReadySubject.next(containerId);
      });
    }

    return mapObject;
  }

  createEvents(mapObject: MapObject, loadCallback: Function) {
    mapObject.map.on('load', () => {
      if (loadCallback) {
        loadCallback(mapObject);
      }
    });

    // Check if layer sources have been loaded
    mapObject.map.on('dataloading', (event) => {
      if (event.dataType === 'source') {
        if (mapObject.layersLoading) {
          if (mapObject.layersLoading.indexOf(event.sourceId) === -1) {
            mapObject.layersLoading.push(event.sourceId);
          }
        } else {
          mapObject.layersLoading = [event.sourceId];
        }
      }
    });

    mapObject.map.on('data', (event) => {
      if (event.dataType === 'source') {

        if (mapObject.layersLoading) {
          const index = mapObject.layersLoading.indexOf(event.sourceId);

          if (index > -1) {
            mapObject.layersLoading.splice(index, 1);
          }
        }
      }
    });

    mapObject.map.on('rotate', () => {
      mapObject.onRotateChange.next(Math.floor(mapObject.map.getBearing()));
    });

    mapObject.map.on('zoom', () => {
      mapObject.onZoomChange.next(mapObject.map.getZoom());
    });

    // Manage click events
    mapObject.map.on('click', (e) => {
      // Get sub-layers for registered mouse move/leave events
      const layerGroups = mapObject.mapEvents
        .filter(m => m.event === 'click')
        .map(m => m.layerGroup);

      const layers = this.getSubLayersFromLayerGroup(mapObject, layerGroups);

      if (!layers || layers.length === 0) {
        return;
      }

      // Query features for given sub-layers
      const features = mapObject.map.queryRenderedFeatures(e.point, {layers: layers});
      e.features = features;

      // Find and call event for top-most feature
      const mouseClickEvents = mapObject.mapEvents.filter(m => m.event === 'click');

      if (features && features.length > 0) {
        for (const f of features) {
          const moveEvent = mouseClickEvents.find(m => m.layerGroup === f.source);

          if (moveEvent && moveEvent.callback) {
            moveEvent.callback(e);
            break;
          }
        }
      }
    });

    // Manage click events
    mapObject.map.on('mousemove', (e) => {
      // Get sub-layers for registered mouse move/leave events
      const layerGroups = mapObject.mapEvents
        .filter(m => m.event === 'mousemove' || m.event === 'mouseleave')
        .map(m => m.layerGroup);

      const layers = this.getSubLayersFromLayerGroup(mapObject, layerGroups);

      if (!layers || layers.length === 0) {
        return;
      }

      // Query features for given sub-layers
      const features = mapObject.map.queryRenderedFeatures(e.point, {layers: layers});
      e.features = features;

      // Find and call event for top-most feature
      const mouseMoveEvents = mapObject.mapEvents.filter(m => m.event === 'mousemove');
      const mouseLeaveEvents = mapObject.mapEvents.filter(m => m.event === 'mouseleave');

      const mouseLeave = () => {
        const leaveEvent = mouseLeaveEvents.find(m => m.layerGroup === mapObject.hoveringLayer);
        if (leaveEvent && leaveEvent.callback) {
          leaveEvent.callback();
        }
      };

      if (features && features.length > 0) {
        for (const f of features) {
          const moveEvent = mouseMoveEvents.find(m => m.layerGroup === f.source);

          if (moveEvent && moveEvent.callback) {
            mouseLeave();
            mapObject.hoveringLayer = f.source;
            moveEvent.callback(e);
            break;
          }
        }
      } else {
        mouseLeave();
      }
    });

    mapObject.map.on('moveend', () => {
      if (mapObject.currentBasemap.provider === 'Nearmap' || mapObject.currentBasemap.provider === 'SIX Maps') {
        this.updateAttribution(mapObject);
      }
    });
  }

  getSubLayersFromLayerGroup(mapObject: MapObject, layerGroups: string[]): string[] {
    const subLayers: string[] = [];

    // Prevent layers from being fetched if basemap is loading (they get deleted/re-added) causing an error
    if (mapObject && mapObject.map && mapObject.currentBasemap && !mapObject.currentBasemap.isLoading) {
      const style = mapObject.map.getStyle();

      if (style) {
        const mapLayers = style.layers;

        for (const l of mapLayers) {
          if (l.source && layerGroups.indexOf((l as any).source) > -1) {
            subLayers.push(l.id);
          }
        }
      }
    }

    return subLayers;
  }

  registerEvent(mapObject: MapObject, event: string, layerGroup: string,
                callback: (e?: any, features?: MapboxGeoJSONFeature) => void): MapEvent {
    const newMapEvent: MapEvent = {
      event: event,
      layerGroup: layerGroup,
      callback: callback
    };

    mapObject.mapEvents.push(newMapEvent);

    return newMapEvent;
  }

  unregisterEvent(mapObject: MapObject, mapEvent: MapEvent) {
    const index = mapObject.mapEvents.indexOf(mapEvent);

    if (index > -1) {
      mapObject.mapEvents.splice(index, 1);
    }
  }

  convertDataToGeoJson(data: any[], idProperty: string): GeoJSON.FeatureCollection<Geometry> {
    const geoJson: GeoJSON.FeatureCollection<Geometry> = {
      type: 'FeatureCollection',
      features: []
    };

    let id = 1;

    if (data) {
      for (const item of data) {
        const feature: Feature<Geometry> = {
          type: 'Feature',
          geometry: item.geometry,
          properties: {}
        };

        for (const key in item) {
          if (key === 'properties') {
            feature.properties = item[key];
          } else if (key !== 'geometry' && item.hasOwnProperty(key)) {
            feature.properties[key] = item[key];
          }
        }

        if (idProperty && feature.properties.hasOwnProperty(idProperty)) {
          feature.id = feature.properties[idProperty];
        } else {
          feature.id = id;
          id++;
        }

        geoJson.features.push(feature);
      }
    }

    return geoJson;
  }

  updateGeoJsonDataLayer(mapObject: MapObject, sourceId: string, data: any[] | string,
                         idProperty: string, layers: Layer[], beforeLayerId?: string) {
    if (mapObject.map) {
      if (typeof data === 'string') {
        this.updateGeoJsonDataSource(mapObject, sourceId, data);
      } else {
        const geoJSONData: GeoJSON.FeatureCollection<Geometry> = this.convertDataToGeoJson(data as any[], idProperty);
        this.updateGeoJsonDataSource(mapObject, sourceId, geoJSONData);
      }

      // Layers
      this.createLayers(mapObject, sourceId, layers, false, beforeLayerId);
    }
  }

  updateGeoJsonDataSource(mapObject: MapObject, sourceId: string, geoJSONData: GeoJSON.FeatureCollection<Geometry> | string) {
    const source: GeoJSONSource = mapObject.map.getSource(sourceId) as GeoJSONSource;

    if (!source) {
      // Create new data source
      mapObject.map.addSource(sourceId, {
        type: 'geojson',
        data: geoJSONData
      });
    } else {
      // Updating existing data source
      source.setData(geoJSONData);
    }
  }

  updateVectorDataLayer(mapObject: MapObject, sourceId: string, tiles: string[] | string, layers: Layer[], beforeLayerId?: string) {
    if (mapObject && mapObject.map) {
      // Source
      this.updateVectorDataSource(mapObject, sourceId, tiles);

      // Layers
      this.createLayers(mapObject, sourceId, layers, true, beforeLayerId);
    }
  }

  updateVectorDataSource(mapObject: MapObject, sourceId: string, tiles: string[] | string) {
    const source: VectorSource = mapObject.map.getSource(sourceId) as VectorSource;

    // Create new data source if it doesn't exist
    if (!source) {
      const sourceOptions: VectorSource = {
        type: 'vector'
      };

      if (tiles instanceof Array) {
        sourceOptions.tiles = tiles;
      } else {
        sourceOptions.url = tiles;
      }

      mapObject.map.addSource(sourceId, sourceOptions);
    } else {
      // Note overwriting 'source' typing as new functions in latest package 1.12.0 available in typings 1.11.1
      // NOTE - Disabled as it is not always working consistently - will monitor releases/bugs for updates
      // if (tiles instanceof Array) {
      //   (source as any).setTiles(tiles);
      // } else {
      //   (source as any).setUrl(tiles as string);
      // }

      const newStyle = mapObject.map.getStyle();
      if (tiles instanceof Array) {
        (newStyle.sources[sourceId] as VectorSource).tiles = tiles;
        if ((newStyle.sources[sourceId] as VectorSource).url) {
          (newStyle.sources[sourceId] as VectorSource).url = null;
        }
      } else {
        (newStyle.sources[sourceId] as VectorSource).url = tiles;
        if ((newStyle.sources[sourceId] as VectorSource).tiles) {
          (newStyle.sources[sourceId] as VectorSource).tiles = null;
        }
      }

      mapObject.map.setStyle(newStyle);
    }
  }

  createLayer(mapObject: MapObject, sourceId: string, layer: Layer, isVectorSource: boolean, beforeLayerId?: string) {
   
    if (mapObject.id.startsWith('admin-new-layer-map') && mapObject.map.getLayer(layer.id)) {
      mapObject.map.removeLayer(layer.id);
    }

    if (!mapObject.map.getLayer(layer.id)) {
      if (!layer.source) {
        layer.source = sourceId;
      }
      if (isVectorSource && !layer['source-layer']) {
        layer['source-layer'] = sourceId;
      }

      mapObject.map.addLayer(layer, beforeLayerId);
    }
  }

  createLayers(mapObject: MapObject, sourceId: string, layers: Layer[], isVectorSource: boolean, beforeLayerId?: string) {
    if (mapObject.map) {
      for (const layer of layers) {
        if (!layer.hasOwnProperty('metadata')) {
          layer.metadata = {};
        }
        layer.metadata.type = 'custom';

        this.createLayer(mapObject, sourceId, layer, isVectorSource, beforeLayerId);
      }
    }
  }

  checkAndInvertMapLayers(mapObject: MapObject, layers: Layer[]) {
    // Invert labels if basemap has changed from light to dark or vice versa
    const curBasemapType = mapObject.currentBasemap.type;
    const prevBasemapType = mapObject.previousBasemap ? mapObject.previousBasemap.type : null;
    const invertLabelColors =
      (!prevBasemapType && (curBasemapType === MapBasemapType.DARK || curBasemapType === MapBasemapType.SATELLITE)) ||
      (prevBasemapType && prevBasemapType === MapBasemapType.LIGHT &&
        (curBasemapType === MapBasemapType.DARK || curBasemapType === MapBasemapType.SATELLITE)) ||
      (prevBasemapType && (prevBasemapType === MapBasemapType.DARK || prevBasemapType === MapBasemapType.SATELLITE) &&
        curBasemapType === MapBasemapType.LIGHT);

    if (invertLabelColors) {
      mapObject.invertedLabels = !mapObject.invertedLabels;
    }

    // Invert all layers that have defined a label
    for (const layer of layers) {
      if (invertLabelColors && layer.type === 'symbol' && layer.layout && layer.layout['text-field'] &&
        layer.metadata && layer.metadata['ee:invert']) {
        const textColor = mapObject.map.getPaintProperty(layer.id, 'text-color');
        const textHaloColor = mapObject.map.getPaintProperty(layer.id, 'text-halo-color');

        mapObject.map.setPaintProperty(layer.id, 'text-color', textHaloColor);
        mapObject.map.setPaintProperty(layer.id, 'text-halo-color', textColor);
      }
    }
  }

  redraw(mapObject: MapObject) {
    // Re-add previous images - loadImages
    const previousImages = mapObject.currentStyle.images;
    mapObject.currentStyle.images = [];
    this.loadImages(mapObject, previousImages, () => {
      // Re-add previous sources
      for (const source of mapObject.currentStyle.sources) {
        const sourceData: any = {
          type: source.type
        };

        if (source.hasOwnProperty('data')) {
          sourceData.data = source.data;
        } else if (source.hasOwnProperty('tiles')) {
          sourceData.tiles = source.tiles;
        } else if (source.hasOwnProperty('url')) {
          sourceData.url = source.url;
        }

        mapObject.map.addSource(source.sourceId, sourceData);
      }

      // Re-add previous layers
      for (const layer of mapObject.currentStyle.layers) {
        mapObject.map.addLayer(layer);
      }
      this.checkAndInvertMapLayers(mapObject, mapObject.currentStyle.layers);

      setTimeout(() => {
        this.loadingService.decrementLoading();
        mapObject.currentBasemap.isLoading = false;
      });
    });
  }

  private saveCurrentMapStyles(mapObject: MapObject) {
    const currentStyle = mapObject.map.getStyle();
    const currentCustomLayers: Layer[] = [];
    const currentSourceIds: string[] = [];
    const currentSources: MapSource[] = [];

    for (const layer of currentStyle.layers) {
      if (layer.hasOwnProperty('metadata') && layer.metadata.hasOwnProperty('type') && layer.metadata.type === 'custom') {
        currentCustomLayers.push(layer);
        if (layer.hasOwnProperty('source')) {
          if (currentSourceIds.indexOf(layer.source as string) === -1) {
            currentSourceIds.push(layer.source as string);
          }
        }
      }
    }

    for (const sourceId of currentSourceIds) {
      if (currentStyle.sources.hasOwnProperty(sourceId)) {
        const source = currentStyle.sources[sourceId];
        const sourceData: any = {
          sourceId: sourceId,
          type: source.type
        };

        if (source.hasOwnProperty('tiles')) {
          sourceData.tiles = (source as VectorSource).tiles;
        } else if (source.hasOwnProperty('data')) {
          sourceData.data = (source as GeoJSONSourceRaw).data;
        } else if (source.hasOwnProperty('url')) {
          sourceData.url = (source as VectorSource).url;
        }

        currentSources.push(sourceData);
      }
    }

    mapObject.currentStyle.layers = currentCustomLayers;
    mapObject.currentStyle.sources = currentSources;
  }

  setBasemap(mapObject: MapObject, basemap: MapBasemap, date?: string) {
    this.loadingService.incrementLoading();
    this.saveCurrentMapStyles(mapObject);

    let style: Style | string = null;

    if (mapObject.map) {
      if (basemap.displayName !== mapObject.currentBasemap.displayName) {
        mapObject.onBasemapChange.next(true);
      }

      if (basemap.styleUrl) {
        style = basemap.styleUrl;
      } else {
        style = basemap.styleJson;
      }

      if (basemap.provider === 'Nearmap') {
        if (!(style as Style).metadata || !(style as Style).metadata.originalUrl) {
          (style as Style).metadata = {
            originalUrl: (style as Style).sources['nearmap']['tiles'][0]
          };
        }

        (style as Style).sources['nearmap']['tiles'][0] = (style as Style).metadata.originalUrl +
          (date && date !== 'Latest Image' ? `&until=${date}` : '');

        this.updateAttribution(mapObject, date);
      }

      // If basemap provider is nearmaps, just update the tile source and refresh (so we dont have to redraw the entire map)
      if (basemap.provider === 'Nearmap' && basemap.displayName === mapObject.currentBasemap.displayName) {
        (mapObject.map.getSource('nearmap') as RasterSource).tiles = [(style as Style).sources['nearmap']['tiles'][0]];
        (mapObject.map as any).style.sourceCaches['nearmap'].clearTiles();
        (mapObject.map as any).style.sourceCaches['nearmap'].update((mapObject.map as any).transform);
        mapObject.map.triggerRepaint();
        this.loadingService.decrementLoading();
      } else {
        mapObject.currentBasemap.isLoading = true;
        basemap.isLoading = true;

        mapObject.map.once('styledata', () => {
          this.redraw(mapObject);
        });

        mapObject.map.setStyle(style, {
          diff: false
        });
      }

      mapObject.previousBasemap = mapObject.currentBasemap;
      mapObject.currentBasemap = basemap;

      if (mapObject.previousBasemap.provider !== basemap.provider) {
        this.updateAttribution(mapObject);
      }
    }
  }

  private setAttribution(mapObject: MapObject, attribution: string) {
    setTimeout(() => {
      // Hack to update the annotation text
      (mapObject.attributionControl as any).options.customAttribution = attribution;
      (mapObject.attributionControl as any)._updateAttributions();
    });
  }

  private setAttributionDate(mapObject: MapObject, date: string | Date) {
    const attribution =
      `&copy; ${mapObject.currentBasemap.provider} ${date ? ` ${moment(date).format('DD/MM/YYYY')}` : ``}`;

    this.setAttribution(mapObject, attribution);
  }

  private updateAttribution(mapObject: MapObject, date?: string) {
    if (mapObject.currentBasemap.provider === 'Nearmap' || mapObject.currentBasemap.provider === 'SIX Maps') {
      if (mapObject.onAttributionUpdate) {
        mapObject.onAttributionUpdate.unsubscribe();
        mapObject.onAttributionUpdate = null;
      }

      if (date) {
        this.setAttributionDate(mapObject, date);
      } else {
        mapObject.onAttributionUpdate = this.mapBasemapService.getBasemapImageryDate(mapObject.currentBasemap.provider,
          mapObject.map.getBounds(), mapObject)
          .pipe(first())
          .subscribe((response: Date) => {
            this.setAttributionDate(mapObject, response);
          });
      }
    } else {
      this.setAttribution(mapObject, '');
    }
  }

  hideLayers(mapObject: MapObject, layers: Layer[]) {
    if (mapObject.map) {
      for (const layer of layers) {
        mapObject.map.setLayoutProperty(layer.id, 'visibility', 'none');
      }
    }
  }

  showLayers(mapObject: MapObject, layers: Layer[]) {
    if (mapObject.map) {
      for (const layer of layers) {
        mapObject.map.setLayoutProperty(layer.id, 'visibility', 'visible');
      }
    }
  }

  setFilter(mapObject: MapObject, layerId: string, filter: any[]) {
    if (mapObject.map) {
      mapObject.map.setFilter(layerId, filter);
    }
  }

  loadImages(mapObject: MapObject, images: MapImage[], callback?: () => void): void {
    const localCallback = () => {
      if (callback) {
        return callback();
      }
    };

    if (mapObject && mapObject.map) {
      const total = images.length;
      let complete = 0;

      if (images && images.length > 0) {
        images.forEach(image => {
          if (!mapObject.map.hasImage(image.id)) {
            mapObject.map.loadImage(image.url, (error, imageObject) => {
              if (error) {
                console.log('Error loading image -', error);
              } else {
                mapObject.currentStyle.images.push(image);
                mapObject.map.addImage(image.id, imageObject);
                complete++;

                if (complete === total) {
                  localCallback();
                }
              }
            });
          } else {
            complete++;
          }

          if (complete === total) {
            localCallback();
          }
        });
      } else {
        localCallback();
      }
    } else {
      localCallback();
    }
  }

  createDrawingObject(mapObject: MapObject, styles?: any): void {
    if (!mapObject.draw) {
      const options: any = {
        displayControlsDefault: false,
        controls: {},
        userProperties: true,
        modes: Object.assign({
          measure_line: MeasureLineMode,
          measure_polygon: MeasurePolygonMode,
          measure_radius: MeasureRadiusMode,
          measure_travel_walking: MeasureTravelWalkingMode,
          measure_travel_cycling: MeasureTravelCyclingMode,
          measure_travel_driving: MeasureTravelDrivingMode,
          measure_travel_transport: MeasureTravelTransportMode,
          direct_select: DirectSelectMode
        }, modes)
      };

      if (styles) {
        options.styles = styles;
      }

      mapObject.draw = new MapboxDraw(options);
    }
  }

  enableEditMode(mapObject: MapObject, editMode: MapEditMode, drawStyles?: any): void {
    if (!mapObject.editMode) {
      this.createDrawingObject(mapObject, drawStyles);

      mapObject.map.addControl(mapObject.draw);
      mapObject.draw.changeMode(MapboxDrawModes.SIMPLE_SELECT);

      mapObject.editMode = editMode;
      mapObject.onMapEdit.next(editMode);
    }
  }

  disableEditMode(mapObject: MapObject): void {
    if (mapObject.draw && mapObject.editMode) {
      mapObject.draw.trash();
      mapObject.map.removeControl(mapObject.draw);
      mapObject.draw = null;

      mapObject.editMode = null;

      mapObject.map.getCanvas().style.cursor = '';

      mapObject.onMapEdit.next(null);
    }
  }

  addFeatureToDrawControl(mapObject: MapObject, feature: any): string[] {
    const geoJsonFeatures = this.convertDataToGeoJson([feature], null);

    const featureIds = mapObject.draw.add(geoJsonFeatures);
    mapObject.draw.changeMode(MapboxDrawModes.SIMPLE_SELECT, {
      featureIds: featureIds
    });

    return featureIds;
  }

  setDrawMode(mapObject: MapObject, drawMode: MapboxDrawModes | MapMeasureMode): void {
    if (mapObject.draw) {
      mapObject.draw.changeMode(drawMode);
    }
  }

  fitToViewFeatures(map: Map, features: any[], linear?: boolean, pitch?: number, padding?: number) {
    // fix this for lines
    if (map) {
      const bounds = new mapboxgl.LngLatBounds();
      let coordinates: number[][] = null;

      for (const f of features) {
        if (f.geometry) {
          if (f.geometry.type === 'Point') {
            bounds.extend(f.geometry.coordinates);
          } else if (f.geometry.type === 'Polygon') {
            coordinates = (f.geometry as GeoJSON.Polygon).coordinates[0];

            for (const c of coordinates) {
              bounds.extend(new LngLat(c[0], c[1]));
            }
          } else if (f.geometry.type === 'MultiPolygon') {
            for (const p of f.geometry.coordinates) {
              coordinates = p[0];

              for (const c of coordinates) {
                bounds.extend(new LngLat(c[0], c[1]));
              }
            }
          } else if (f.geometry.type === 'LineString') {
            for (const c of f.geometry.coordinates) {
              bounds.extend(new LngLat(c[0], c[1]));
            }
          }
        }
      }

      const boundOptions: FitBoundsOptions = {
        padding: padding ? padding : 20,
        linear: linear
      };

      if (pitch) {
        boundOptions.pitch = pitch;
      }

      if (!bounds.isEmpty()) {
        map.fitBounds(bounds, boundOptions);
      }
    }
  }

  fitToBounds(mapObject: MapObject, bounds: number[], center: LngLatLike) {
    const lngLatBounds: LngLatBounds = new LngLatBounds(new LngLat(bounds[0], bounds[1]),
      new LngLat(bounds[2], bounds[3]));

    if (mapObject.map) {
      mapObject.map.fitBounds(lngLatBounds, {
        padding: 20
      });
      mapObject.onSetPosition.next(center);
    }
  }

  panAndZoomTo(mapObject: MapObject, coords: LngLatLike, zoom: number) {
    if (mapObject.map) {
      mapObject.map.jumpTo({
        center: coords,
        zoom: zoom
      });
      mapObject.onSetPosition.next(coords);
    }
  }

  flyTo(mapObject: MapObject, coords: LngLatLike, zoom: number) {
    if (mapObject.map) {
      mapObject.map.flyTo({
        center: coords,
        zoom: zoom
      });
      mapObject.onSetPosition.next(coords);
    }
  }

  panTo(map: Map, coords: number[]) {
    if (map) {
      map.jumpTo({
        center: new LngLat(coords[0], coords[1])
      });
    }
  }

  createDragMarker(map: Map, el?: HTMLElement): Marker {
    const markerOptions: mapboxgl.MarkerOptions = {
      draggable: true,
      element: el
    };

    const dragMarker = new mapboxgl.Marker(markerOptions)
      .setLngLat(map.getCenter())
      .addTo(map);

    return dragMarker;
  }

  zoomIn(mapObject: MapObject) {
    if (mapObject.map) {
      const maxZoom = mapObject.map.getMaxZoom();
      const zoom = mapObject.map.getZoom() + 1;

      if (zoom <= maxZoom) {
        mapObject.map.zoomTo(zoom);
      } else {
        mapObject.map.zoomTo(maxZoom);
      }
    }
  }

  zoomOut(mapObject: MapObject) {
    if (mapObject.map) {
      const minZoom = mapObject.map.getMinZoom();
      const zoom = mapObject.map.getZoom() - 1;

      if (zoom >= minZoom) {
        mapObject.map.zoomTo(zoom);
      } else {
        mapObject.map.zoomTo(minZoom);
      }
    }
  }

  resetMapView(mapObject: MapObject) {
    if (mapObject.map) {
      mapObject.map.setPitch(0);
      mapObject.map.resetNorth();
    }
  }

  convertLineStringToEncodedPolyline(lineString: LineString): string {
    return polyline.fromGeoJSON(lineString);
  }

  convertEncodedPolylineToLineString(encodedPolyline: string): LineString {
    return polyline.toGeoJSON(encodedPolyline);
  }

  convertPolygonToEncodedPolyline(polygon: Polygon | MultiPolygon): string {
    let coords = null;

    if (polygon.type === 'Polygon') {
      coords = _.cloneDeep((polygon as Polygon).coordinates[0]);
    } else if (polygon.type === 'MultiPolygon') {
      coords = _.cloneDeep((polygon as MultiPolygon).coordinates[0][0]);
    }

    for (let i = 0; i < coords.length; i++) {
      coords[i] = [coords[i][1], coords[i][0]];
    }

    return polyline.encode(coords);
  }

  convertEncodedPolylineToPolygon(encodedPolyline: string): Polygon {
    const coords = polyline.decode(encodedPolyline);

    for (let i = 0; i < coords.length; i++) {
      coords[i] = [coords[i][1], coords[i][0]];
    }

    return {
      type: 'Polygon',
      coordinates: [coords]
    };
  }

  getCircleGeomFromRadiusEncodedPolyline(encodedPolyline: string): Polygon {
    const lineGeom = this.convertEncodedPolylineToLineString(encodedPolyline);

    const circle = createDisplayCircle({
      type: 'Feature',
      geometry: lineGeom
    }, null);

    return circle.geometry;
  }

}
