import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {TransportTripLegResult, TransportTripResult} from '../../interfaces/transport-trip-result';
import {TransportTripModeClass} from '../../enums/transport-trip-mode-class';
import {Feature, LineString} from 'geojson';
import {length} from '@turf/turf';

@Injectable({
  providedIn: 'root'
})
export class TransportApiService {

  constructor(private http: HttpClient) { }

  getTrip(origin: number[], destination: number[], date: string, time: string, timing: 'arr' | 'dep',
          modes: TransportTripModeClass[]): Observable<TransportTripResult> {
    const url = environment.backendServer.mainPath + environment.backendServer.transport +
      `/trip?origin=${origin[0]},${origin[1]}&destination=${destination[0]},${destination[1]}&timing=${timing}&date=${date}&time=${time}&` +
      `modes=${JSON.stringify(modes)}`;

    return this.http.get<any>(url, {
      headers: {
        'Authorization': `apikey ${environment.tileyApiKey}`
      }
    })
      .pipe(
        map(response => {
          // Only receiving one journey
          if (response && response.journeys && response.journeys.length > 0) {

            // Reformat response and calculate totals
            let totalDuration = 0;
            let departureTime = null;
            let arrivalTime = null;
            const legs: TransportTripLegResult[] = [];
            const allGeometry: Feature<LineString> = {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: []
              },
              properties: {}
            };

            for (let i = 0; i < response.journeys[0].legs.length; i++) {
              const leg = response.journeys[0].legs[i];
              totalDuration += leg.duration;

              if (i === 0) {
                departureTime = leg.origin.departureTimePlanned;
              }
              if (i === response.journeys[0].legs.length - 1) {
                arrivalTime = leg.destination.arrivalTimePlanned;
              }

              const geometry: Feature<LineString> = {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: []
                },
                properties: {}
              };

              // If no coords available (on-demand services?)
              if (leg.coords) {
                for (const coord of leg.coords) {
                  geometry.geometry.coordinates.push([coord[1], coord[0]]);
                  allGeometry.geometry.coordinates.push([coord[1], coord[0]]);
                }
              } else {
                geometry.geometry.coordinates.push([leg.origin.coord[1], leg.origin.coord[0]]);
                geometry.geometry.coordinates.push([leg.destination.coord[1], leg.destination.coord[0]]);
                allGeometry.geometry.coordinates.push([leg.origin.coord[1], leg.origin.coord[0]]);
                allGeometry.geometry.coordinates.push([leg.destination.coord[1], leg.destination.coord[0]]);
              }

              const legDistance = length(geometry, {units: 'kilometers'});

              // Reformat leg params
              legs.push({
                modeClass: (leg.transportation && leg.transportation.product && leg.transportation.product.class) ?
                  leg.transportation.product.class : ((leg.transportation && leg.transportation.product && leg.transportation.product.name
                    && leg.transportation.product.name === 'Taxi') ? TransportTripModeClass.TAXI : null),
                modeName: (leg.transportation && leg.transportation.product && leg.transportation.product.name) ?
                  leg.transportation.product.name : null,
                geometry: geometry.geometry,
                distance: legDistance,
                duration: leg.duration,
                originName: (leg.origin && leg.origin.name) ? leg.origin.name : null,
                destinationName: (leg.destination && leg.destination.name) ? leg.destination.name : null,
                transportationProvider: (leg.transportation && leg.transportation.name) ? leg.transportation.name : null,
                transportationOperator: (leg.transportation && leg.transportation.operator && leg.transportation.operator.name) ?
                  leg.transportation.operator.name : null,
                transportationNumber: (leg.transportation && leg.transportation.disassembledName) ?
                  leg.transportation.disassembledName : null,
                transportationDescription: (leg.transportation && leg.transportation.description) ? leg.transportation.description : null,
                departureTime: (leg.origin && leg.origin.departureTimePlanned) ? leg.origin.departureTimePlanned : null,
                arrivalTime: (leg.destination && leg.destination.arrivalTimePlanned) ? leg.destination.arrivalTimePlanned : null
              });
            }

            const totalDistance = length(allGeometry, {units: 'kilometers'});

            return {
              totalDistance: totalDistance,
              totalDuration: totalDuration,
              departureTime: departureTime,
              arrivalTime: arrivalTime,
              geometry: allGeometry.geometry,
              legs: legs
            };
          } else {
            return null;
          }
        })
      );
  }
}
