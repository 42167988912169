<div class="map-toolbar-container">
  <div class="toolbar" id="mapToolbar">
    <!-- Primary button -->
    <button mat-button class="primary add-planned-area-button" *ngIf="showToolbarTool('gapAdd') && isUserGapEditor"
            (click)="addPlannedArea()" [disabled]="otherEdit || !mapObject"
            eeTooltip tooltipText="Add a new planned area" tooltipClass="toolbar-primary-tooltip">
      <mat-icon fontSet="far" fontIcon="fa-plus"></mat-icon>
    </button>
    <button mat-button class="primary" *ngIf="showToolbarTool('hubHome')"
            (click)="navigateToHub()" [disabled]="!mapObject"
            eeTooltip tooltipText="Home" tooltipClass="toolbar-primary-tooltip">
      <mat-icon fontSet="far" fontIcon="fa-home"></mat-icon>
    </button>

    <!-- Other buttons -->
    <button mat-button *ngIf="showToolbarTool('visionCancelButton')"
            class="toolbar-cancel-button"
            (click)="cancelEditing()" [disabled]="!mapObject"
            eeTooltip tooltipText="Cancel editing" tooltipClass="toolbar-tooltips tooltip-alert" [dontShowOnClick]="true">
      <mat-icon fontSet="far" fontIcon="fa-ban"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('referenceLayers')"
            class="btn-drop-down-arrow toolbar-reference-button" [ngClass]="{'enabled': referenceLayerPanelEnabled}"
            (click)="toggleReferenceLayerPanel()" [disabled]="!mapObject"
            eeTooltip tooltipText="Reference Layers" tooltipClass="toolbar-tooltips">
      <mat-icon fontSet="far" fontIcon="fa-layer-group"></mat-icon>
      <span class="new-layer-count" *ngIf="newLayerCountVisible && newLayerCount > 0">{{ newLayerCount }}</span>
    </button>
    <button mat-button *ngIf="showToolbarTool('basemaps')"
            class="btn-drop-down-arrow" [ngClass]="{'enabled': basemapPanelEnabled}"
            (click)="toggleBasemapPanel()" [disabled]="!mapObject"
            eeTooltip tooltipText="Map Type" tooltipClass="toolbar-tooltips">
      <mat-icon fontSet="far" fontIcon="fa-globe-asia"></mat-icon>
    </button>
    <button mat-button
            class="btn-drop-down-arrow toolbar-annotation-button" *ngIf="showToolbarTool('gapAnnotation') && isUserGapEditor"
            [disabled]="otherEdit || !mapObject || !showToolbarTool('gapAnnotationEnabled')" [matMenuTriggerFor]="annotationMenu"
            eeTooltip tooltipText="Add annotation" tooltipClass="toolbar-tooltips">
      <mat-icon fontSet="far" fontIcon="fa-thumbtack"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('inspectorTool')"
            class="toolbar-inspector-button"
            (click)="toggleMapInspectorTool()" [disabled]="(otherEdit && !inspectorEdit) || !mapObject"
            eeTooltip tooltipText="Map information tool" tooltipClass="toolbar-tooltips">
      <mat-icon fontSet="far" fontIcon="fa-info"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('lineMeasureTool')"
            class="toolbar-line-button"
            (click)="toggleLineMeasureTool()" [disabled]="(otherEdit && !measureEdit) || !mapObject"
            eeTooltip tooltipText="Line measuring tool" tooltipClass="toolbar-tooltips">
      <mat-icon fontSet="far" fontIcon="fa-ruler"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('areaMeasureTool')"
            class="toolbar-area-button"
            (click)="toggleAreaMeasureTool()" [disabled]="(otherEdit && !measureEdit) || !mapObject"
            eeTooltip tooltipText="Area measuring tool" tooltipClass="toolbar-tooltips">
      <mat-icon fontSet="far" fontIcon="fa-draw-square"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('radiusMeasureTool')"
            class="toolbar-radius-button"
            (click)="toggleRadiusMeasureTool()" [disabled]="(otherEdit && !measureEdit) || !mapObject"
            eeTooltip tooltipText="Radius measuring tool" tooltipClass="toolbar-tooltips">
      <mat-icon fontSet="far" fontIcon="fa-draw-circle"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('travelTools')"
            class="btn-drop-down-arrow toolbar-travel-button"
            [disabled]="(otherEdit && !measureEdit) || !mapObject" [matMenuTriggerFor]="measureToolTravelMenu"
            eeTooltip tooltipText="Travel distance / time measuring tool" tooltipClass="toolbar-tooltips">
      <mat-icon fontSet="far" fontIcon="fa-route"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('threeDMode')"
            class="toolbar-3d-button" [ngClass]="{'enabled': schools3dLayer?.enabled}"
            (click)="toggleSchools3dView()" [disabled]="!mapObject"
            eeTooltip tooltipText="3D School Visualisation" tooltipClass="toolbar-tooltips">
      <mat-icon [svgIcon]="'3d-mode'" class="icon-3d-view"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('streetView')"
            class="toolbar-sv-button" [ngClass]="{'enabled': streetViewEnabled}"
            (click)="toggleStreetView()" [disabled]="!mapObject"
            eeTooltip tooltipText="Street View" tooltipClass="toolbar-tooltips">
      <mat-icon fontSet="far" fontIcon="fa-street-view"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('mapExport')"
            class="toolbar-export-button"
            (click)="exportMapImage()" [disabled]="!mapObject"
            eeTooltip tooltipText="Export map image" tooltipClass="toolbar-tooltips" >
      <mat-icon fontSet="far" fontIcon="fa-image"></mat-icon>
    </button>
    <button mat-button *ngIf="showToolbarTool('fullscreen')"
            class="toolbar-export-button" [ngClass]="{'enabled': mapObject && mapObject.isFullscreen}"
            (click)="toggleFullscreen()" [disabled]="!mapObject"
            eeTooltip tooltipText="Toggle fullscreen" tooltipClass="toolbar-tooltips" >
      <mat-icon fontSet="far" [fontIcon]="mapObject && mapObject.isFullscreen ? 'fa-compress' : 'fa-expand'"></mat-icon>
    </button>
  </div>

  <ee-map-reference-layer-panel class="menu" *ngIf="referenceLayerPanelEnabled" (click)="toggleReferencePanel()"
                                  [isOpen]="isPanelOpen" [mapObject]="mapObject"
                                  (closeButtonClick)="referenceLayerPanelClosed($event)"></ee-map-reference-layer-panel>

  <ee-map-basemap-panel class="menu" *ngIf="basemapPanelEnabled" [mapObject]="mapObject"
                        (closeButtonClick)="basemapPanelClosed($event)"></ee-map-basemap-panel>

  <mat-menu #measureToolTravelMenu="matMenu" class="toolbar-travel-tools">
    <button mat-menu-item (click)="enableTravelTool(MapMeasureModeEnum.MEASURE_TRAVEL_CYCLING)">
      <mat-icon fontSet="far" fontIcon="fa-bicycle" class="menu-icon"></mat-icon>
      <span>Cycling</span>
    </button>
    <button mat-menu-item (click)="enableTravelTool(MapMeasureModeEnum.MEASURE_TRAVEL_WALKING)">
      <mat-icon fontSet="far" fontIcon="fa-walking" class="menu-icon"></mat-icon>
      <span>Walking</span>
    </button>
    <button mat-menu-item (click)="enableTravelTool(MapMeasureModeEnum.MEASURE_TRAVEL_DRIVING)">
      <mat-icon fontSet="far" fontIcon="fa-car" class="menu-icon"></mat-icon>
      <span>Driving</span>
    </button>
    <button mat-menu-item (click)="enableTravelTool(MapMeasureModeEnum.MEASURE_TRAVEL_TRANSPORT)">
      <mat-icon fontSet="far" fontIcon="fa-bus" class="menu-icon"></mat-icon>
      <span>Transport</span>
    </button>
    <button mat-menu-item (click)="enableReachabilityTool()" [disabled]="(otherEdit && !reachabilityEdit) || !mapObject">
      <mat-icon fontSet="far" fontIcon="fa-chart-network" class="menu-icon"></mat-icon>
      <span>Reachability</span>
    </button>
  </mat-menu>

  <mat-menu #annotationMenu="matMenu" direction="left" class="toolbar-annotation-menu">
    <ng-template matMenuContent>
      <button mat-menu-item *ngFor="let type of annotationTypes;" (click)="addAnnotation(type)">
        <mat-icon [svgIcon]="type.icon"></mat-icon>
        <span>{{type.label}}</span>
      </button>
    </ng-template>
  </mat-menu>
</div>
