import { Injectable } from '@angular/core';
import {Chart, ExportingOptions} from 'highcharts';
import * as moment from 'moment';
import {DownloadDisclaimerService} from '../../../core/services/download-disclaimer/download-disclaimer.service';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  private chartExportingOptions: ExportingOptions = {
    enabled: false,
    fallbackToExportServer: false,
    chartOptions: {
      title: {
        text: ''
      },
      credits: {
        enabled: true,
        href: null,
        text: ''
      }
    }
  };

  constructor(
    private downloadDiscaimerService: DownloadDisclaimerService
  ) { }

  exportChart(module: string, title: string, subtitle: string, chartObject: Chart, source?: string) {
    this.downloadDiscaimerService.showDownloadDisclaimer(() => {
      if (chartObject) {
        const chartOptions = {
          title: {
            text: title
          },
          subtitle: {
            text: subtitle
          },
          credits: {
            enabled: true,
            text: `Eagle Eye © School Infrastructure NSW. Exported on ${moment().format('DD/MM/YYYY')}.` +
              (source ? ` Source: ${source}` : ''),
          },
          plotOptions: {
            errorbar: {
              whiskerLength: '60%'
            }
          },
          chart: {
            spacingBottom: 16,
            events: {
              load: function() {
                let plotLineSeriesAdded = false;

                for (const s of this.series) {
                  // Ensure only one legend entry for 'Score Range'
                  if (s.name === 'Score Range' && !plotLineSeriesAdded) {
                      (s as any).update({
                          showInLegend: true
                      });
                      plotLineSeriesAdded = true;
                  } else if (s.name === 'Score Range' && plotLineSeriesAdded) {
                      (s as any).update({
                          showInLegend: false
                      });
                  } else if (s.name === 'Max Score' || s.name === 'Min Score') {
                      (s as any).update({
                          showInLegend: false 
                      });
                  } else {
                      (s as any).update({
                          showInLegend: s.visible
                      });
                  }
              }
              }
            }
          },
        };

        this.chartExportingOptions.filename =
          `EE-export - ${module} - ${title} - ${subtitle} - ${moment().format('YYYYMMDD')}`;
        chartObject.exportChartLocal(this.chartExportingOptions, chartOptions);
      }
    });
  }
}
