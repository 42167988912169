import { Injectable } from '@angular/core';
import {ApiCommsService} from '../../services/api-comms/api-comms.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ShapeDownload} from '../../../shared/interfaces/shape-download';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShapeDownloadsResolverService implements Resolve<ShapeDownload[]> {

  private onShapeDownloadsReadySubject: BehaviorSubject<ShapeDownload[]> = new BehaviorSubject<ShapeDownload[]>(null);

  constructor(
    private apiCommsService: ApiCommsService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ShapeDownload[]> {
    return this.apiCommsService.getShapeDownloads()
      .pipe(
        tap(data => {
          if (data) {
            this.onShapeDownloadsReadySubject.next(data);
          }
        })
      );
  }

  onUpdate(): Observable<ShapeDownload[]> {
    return this.onShapeDownloadsReadySubject;
  }
}
