import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InfoDialogService} from '../../../core/services/info-dialog/info-dialog.service';
import {FeatureCollection} from 'geojson';
import {MapDataImportService} from '../../services/map-data-import/map-data-import.service';

@Component({
  selector: 'ee-map-data-import-upload-dialog',
  templateUrl: './map-data-import-upload-dialog.component.html',
  styleUrls: ['./map-data-import-upload-dialog.component.scss']
})
export class MapDataImportUploadDialogComponent implements OnInit {

  isDragging = false;
  stopDraggingTimeout = null;

  fileSelected = false;
  selectedFile: string = null;
  mapData: FeatureCollection = null;

  constructor(
    public dialogRef: MatDialogRef<MapDataImportUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private infoDialogService: InfoDialogService,
    private mapDataImportService: MapDataImportService
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  processFile(files: FileList) {
    this.mapDataImportService.processFile(files, (filename, data, error?) => {
      if (error) {
        this.showErrorMessage(error);
      } else {
        this.fileSelected = true;
        this.selectedFile = filename;
        this.mapData = data;
      }
    });
  }

  private showErrorMessage(message: string) {
    this.infoDialogService.showDialog(360, 'exclamation-triangle', 'Invalid File Format', message);
  }

  import() {
    this.dialogRef.close(this.mapData);
  }

  uploadDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
    if (this.stopDraggingTimeout) {
      clearTimeout(this.stopDraggingTimeout);
      this.stopDraggingTimeout = null;
    }
  }

  uploadDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
    if (this.stopDraggingTimeout) {
      clearTimeout(this.stopDraggingTimeout);
      this.stopDraggingTimeout = null;
    }
  }

  uploadDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.stopDraggingTimeout = setTimeout(() => {
      this.isDragging = false;
    }, 50);
  }

  uploadDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
    if (event && event.dataTransfer && event.dataTransfer.files) {
      this.processFile(event.dataTransfer.files);
    }
  }

}
