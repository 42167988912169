import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';

import {SharedModule} from './shared/shared.module';
import {DragulaModule} from 'ng2-dragula';

// Note sometimes when you add new components they end up in here instead of the proper sub module

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CoreModule,
        SharedModule.forRoot(),
        DragulaModule.forRoot()
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
