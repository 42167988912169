import {Injectable} from '@angular/core';
import {MapObject} from '../../interfaces/map-object';
import {MapReferenceLayer} from '../../interfaces/map-reference-layer';
import {MapboxService} from '../mapbox/mapbox.service';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {first} from 'rxjs/operators';
import {MapPsuedoLayers} from '../../enums/map-psuedo-layers';
import {MapReferenceLayerResolverService} from '../../../core/resolvers/map-reference-layer-resolver/map-reference-layer-resolver.service';
import {Observable} from 'rxjs';
import {TileyService} from '../tiley/tiley.service';
import {MapLayerActionType} from '../../enums/map-layer-action-type';
import {MapReferenceLayerFilter} from '../../interfaces/map-reference-layer-filter';
import * as _ from 'lodash';
import {MapReferenceLayerFilterOperation} from '../../enums/map-reference-layer-filter-operation';
import {MapReferenceLayerType} from '../../enums/map-reference-layer-type';
import {MapTooltipService} from '../map-tooltip/map-tooltip.service';
import {AppRoutes} from '../../../core/enums/app-routes';
import {MapReferenceLayerCategory} from '../../interfaces/map-reference-layer-category';
import * as moment from 'moment';
import {HubRoutes} from '../../../hub/enums/hub-routes';
import {MapLayerPaletteGroupData} from '../../interfaces/map-layer-palette-group-data';
import tinycolor from 'tinycolor2';
import {MapLayerPaletteData} from '../../interfaces/map-layer-palette-data';
import {LoadingService} from '../../../core/services/loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class MapReferenceLayersService {

  constructor(
    private mapReferenceLayersDataService: MapReferenceLayerResolverService,
    private mapboxService: MapboxService,
    private tileyService: TileyService,
    private mapTooltipService: MapTooltipService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  assignReferenceLayersToMapObject(mapObject: MapObject) {
    this.mapReferenceLayersDataService.onReferenceLayerCategoriesReady()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          if (!mapObject.referenceLayerCategories) {
            mapObject.referenceLayerCategories = _.cloneDeep(result);
            mapObject.enabledReferenceLayers = [];
            mapObject.onReferenceLayersReady.next(true);
          }
        }
      });
  }

  reorderEnabledLayers(mapObject: MapObject, layers: MapReferenceLayer[]) {
    mapObject.enabledReferenceLayers = layers;

    let previousLayerId: string = MapPsuedoLayers.PSUEDO_LAYER_REF;

    for (const refLayer of layers) {
      if (refLayer.enabled) {
        for (let i = refLayer.layerStyling.length - 1; i >= 0; i--) {
          const layer = refLayer.layerStyling[i];
          mapObject.map.moveLayer(layer.id, previousLayerId);
          previousLayerId = layer.id;
        }
      }
    }
  }

  toggleLayer(mapObject: MapObject, layer: MapReferenceLayer, actionType: MapLayerActionType) {
    if (!layer.enabled) {
      this.addLayer(mapObject, layer, actionType);
    } else {
      this.removeLayer(mapObject, layer, actionType);
    }
  }

  addLayer(mapObject: MapObject, layer: MapReferenceLayer, actionType: MapLayerActionType, callback?: () => void) {
    this.loadingService.incrementLoading();

    if (actionType === MapLayerActionType.PROGRAMMATIC) {
      layer.progammaticEnabled = true;
    } else if (actionType === MapLayerActionType.SEARCH) {
      layer.searchEnabled = true;
    } else if (actionType === MapLayerActionType.USER_TOGGLE) {
      layer.userEnabled = true;
    }

    // TO DO - Maybe remove the callback and provide a layer order or something instead?
    layer.visible = true;
    layer.enabled = true;

    const preCallback = () => {
      // TO DO - dunno what im supposed to do here?
      const subLayers = mapObject.map.getStyle().layers.filter(l => l['source-layer'] === layer.layerName);
      this.mapboxService.checkAndInvertMapLayers(mapObject, subLayers);
      this.checkLayerStylingOverwrites(mapObject, layer);
      this.loadingService.decrementLoading();

      if (callback) {
        callback();
      }
    };

    if (!layer.initialised) {
      this.initialiseLayer(mapObject, layer, preCallback);
    }
    else {
      this.setInitialLayerOrder(mapObject, layer);
      this.updateLayer(mapObject, layer, () => {
        this.mapboxService.showLayers(mapObject, layer.layerStyling);
        preCallback();
      });
    }
  }

  updateLayer(mapObject: MapObject, layer: MapReferenceLayer, callback?: () => void) {
    if (mapObject) {
      const datasetName = layer.datasetName.toLowerCase();

      // Load GeoJSON url source if provided
      if (datasetName.startsWith('http://') || datasetName.startsWith('https://')) {
        this.mapboxService.updateGeoJsonDataLayer(mapObject, layer.layerName, layer.datasetName, null,
          layer.layerStyling, MapPsuedoLayers.PSUEDO_LAYER_REF);

        // If refresh interval is set, configure so data source updates
        if (layer.refreshInterval && !layer.refreshIntervalTimer) {
          layer.refreshDate = moment().toDate();
          layer.refreshIntervalTimer = setInterval(() => {
            this.mapboxService.updateGeoJsonDataSource(mapObject, layer.layerName, layer.datasetName);
            layer.refreshDate = moment().toDate();
          }, layer.refreshInterval);

          layer.refreshTimeRemainingInterval = setInterval(() => {
            if (!layer.refreshTimeRemaining || layer.refreshTimeRemaining === 0) {
              layer.refreshTimeRemaining = layer.refreshInterval;
            } else {
              layer.refreshTimeRemaining -= 5000;
            }
          }, 5000);
        }

        layer.initialised = true;
        if (callback) {
          callback();
        }
      }
      // Otherwise load data as vector tiles
      else {
        this.refreshReferenceLayerTiles(mapObject, layer)
          .pipe(first())
          .subscribe(success => {
            if (success) {
              if (callback) {
                callback();
              }
              this.reorderEnabledLayers(mapObject, mapObject.enabledReferenceLayers);
            }
          });
      }
    }
  }

  removeLayer(mapObject: MapObject, layer: MapReferenceLayer, actionType: MapLayerActionType) {
    if (!layer) {
      return;
    }

    if (actionType === MapLayerActionType.USER_TOGGLE ||
      (actionType === MapLayerActionType.PROGRAMMATIC && !layer.userEnabled) ||
      (actionType === MapLayerActionType.SEARCH && !layer.userEnabled && !layer.progammaticEnabled)) {
        layer.visible = false;
        layer.enabled = false;

        layer.userEnabled = false;
        layer.progammaticEnabled = false;
        layer.searchEnabled = false;

        // Remove layer from array
        const index = mapObject.enabledReferenceLayers.indexOf(layer);

        if (index > - 1) {
          mapObject.enabledReferenceLayers.splice(index, 1);
        }

        if (layer.initialised) {
          this.mapboxService.hideLayers(mapObject, layer.layerStyling);
        }

        if (layer.refreshIntervalTimer) {
          clearInterval(layer.refreshIntervalTimer);
          clearInterval(layer.refreshTimeRemainingInterval);
          layer.refreshIntervalTimer = null;
          layer.refreshTimeRemainingInterval = null;
        }

        mapObject.onReferenceLayersChanged.next(true);
    } else {
      // I really don't understand why there is a problem with this enum (why do i need the +?)
      switch (+actionType) {
        case MapLayerActionType.USER_TOGGLE:
          layer.userEnabled = false;
          break;
        case MapLayerActionType.PROGRAMMATIC:
          layer.progammaticEnabled = false;
          break;
        case MapLayerActionType.SEARCH:
          layer.searchEnabled = false;
          break;
      }
      this.updateLayer(mapObject, layer);
    }
  }

  private initialiseLayer(mapObject: MapObject, layer: MapReferenceLayer, callback?: () => void) {
    const doUpdateLayer = () => {
      // Refresh / load reference layer tiles
      this.updateLayer(mapObject, layer, () => {
        setTimeout(() => {
          this.setInitialLayerOrder(mapObject, layer);
          this.initialiseReferenceLayerEvents(mapObject, layer); // All ref layers are now interactive

          this.reorderEnabledLayers(mapObject, mapObject.enabledReferenceLayers);
          if (callback) {
            callback();
          }
          mapObject.onReferenceLayersChanged.next(true);
        });
      });
    };

    // Load any images
    if (layer.imageName) {
      const images = layer.imageName.split(',').map(l => {
        return {
          id: l,
          url: `${environment.assetsFolder}${l}.png`
        };
      });

      this.mapboxService.loadImages(mapObject, images, () => {
        doUpdateLayer();
      });
    } else {
      doUpdateLayer();
    }
  }

  toggleLayerVisibility(mapObject: MapObject, layer: MapReferenceLayer) {
    if (!layer.visible) {
      this.showLayer(mapObject, layer);
    } else {
      this.hideLayer(mapObject, layer);
    }
  }

  showLayer(mapObject: MapObject, layer: MapReferenceLayer) {
    layer.visible = true;
    this.mapboxService.showLayers(mapObject, layer.layerStyling);
    mapObject.onReferenceLayersChanged.next(true);
  }

  hideLayer(mapObject: MapObject, layer: MapReferenceLayer) {
    layer.visible = false;
    this.mapboxService.hideLayers(mapObject, layer.layerStyling);
    mapObject.onReferenceLayersChanged.next(true);
  }

  private convertTypeToInteger(type: MapReferenceLayerType): number {
    let result = -1;

    switch (type) {
      case MapReferenceLayerType.POINT:
        result = 0;
        break;
      case MapReferenceLayerType.SCHOOLS_3D:
        result = 1;
        break;
      case MapReferenceLayerType.LINE:
        result = 2;
        break;
      case MapReferenceLayerType.POLYGON:
        result = 3;
        break;
    }

    return result;
  }

  private setInitialLayerOrder(mapObject: MapObject, layer: MapReferenceLayer) {
    if (mapObject && mapObject.enabledReferenceLayers.indexOf(layer) === -1) {
      const layerType = this.convertTypeToInteger(layer.layerType);
      let index = 0;

      if (layerType > 0) {
        const currentLayers = mapObject.enabledReferenceLayers;
        for (let i = 0; i < currentLayers.length; i++) {
          const currentLayerType = this.convertTypeToInteger(currentLayers[i].layerType);
          if (layerType <= currentLayerType) {
            break;
          }
          index++;
        }
      }

      mapObject.enabledReferenceLayers.splice(index, 0, layer);
    }
  }

  private initialiseReferenceLayerEvents(mapObject: MapObject, layer: MapReferenceLayer) {

    this.mapboxService.registerEvent(mapObject, 'click', layer.layerName, (e) => {
      this.onLayerClick(e, mapObject, layer);
    });

    this.mapboxService.registerEvent(mapObject, 'mousemove', layer.layerName, (e) => {
      this.onLayerMouseMove(e, mapObject, layer);
    });

    this.mapboxService.registerEvent(mapObject, 'mouseleave', layer.layerName, () => {
      this.onLayerMouseLeave(mapObject, layer);
    });
  }

  // Update query and determine whether tiles need to be updated
  public refreshReferenceLayerTiles(mapObject: MapObject, layer: MapReferenceLayer): Observable<boolean> {
    return new Observable(observer => {
      // Prepare request
      const columns = (layer.idColumn ? `${layer.idColumn} AS id` : '') + (layer.idColumn && layer.extraColumns ? ',' : '') +
        (layer.extraColumns ? layer.extraColumns : '');

      // Base Filter
      let filter = layer.baseFilter ? layer.baseFilter : null;

      // Combine custom and search filter
      let combinedFilter = null;
      if (layer.enableFilter) {
        combinedFilter = layer.customFilter && layer.searchFilter ?
          {a: layer.customFilter, b: layer.searchFilter, op: MapReferenceLayerFilterOperation.OR} :
          (layer.customFilter ? layer.customFilter : (layer.searchFilter ? layer.searchFilter : null));
      }

      if (layer.enableFilter && combinedFilter) {
        const customFilterString = this.buildFilterString(combinedFilter);
        filter = (filter ? filter : '') + (` ${layer.baseFilter ? 'AND ' : ''} ${customFilterString}`);
      }

      // Get URL end point for layer with selected columns and filters applied
      this.tileyService.getUrl(layer.layerName, layer.datasetName, columns, filter)
        .subscribe((tileUrl: string) => {
          if (tileUrl) {
            this.mapboxService.updateVectorDataLayer(mapObject, layer.layerName, [tileUrl],
              layer.layerStyling, MapPsuedoLayers.PSUEDO_LAYER_REF);

            layer.initialised = true;
            observer.next(true);
            observer.complete();
          }
        });
    });
  }

  private onLayerClick(e: any, mapObject: MapObject, layer: MapReferenceLayer) {
    if (!mapObject.editMode && !layer.interactiveOverride) {
      if (layer.interactive) {
        if (e.features && e.features.length > 0 && e.features[0].hasOwnProperty('properties') &&
          e.features[0].properties.hasOwnProperty('id')) {
          const id = e.features[0].properties.id;
          this.router.navigate([layer.interactiveRoute, id]);
        }
      } else {
        if (e.features && e.features.length > 0 && e.features[0].hasOwnProperty('properties')) {
          const layerName = layer.layerName;
          const tileId = e.features[0].properties.tile_id;
          if (tileId) {
            this.router.navigate([AppRoutes.HUB, HubRoutes.CUSTOM_REF, layerName, tileId]);
          }
        }
      }
    }
  }

  private onLayerMouseMove(e: any, mapObject: MapObject, layer: MapReferenceLayer) {
    if (!mapObject.editMode && !layer.interactiveOverride) {
      mapObject.map.getCanvas().style.cursor = 'pointer';

      let coordinates = null;

      if (layer.hasTooltip && mapObject.tooltipEnabled) {
        if (e.features && e.features.length > 0 && e.features[0].properties && e.features[0].geometry) {
          if (e.features[0].geometry.type === 'Point') {
            coordinates = {
              lat: e.features[0].geometry.coordinates[1],
              lng: e.features[0].geometry.coordinates[0]
            };
          } else {
            coordinates = e.lngLat;
          }
          this.showMapToolTip(mapObject, layer.tooltipText, layer.displayName, e.features[0].properties, coordinates);
        }
      }

      if (layer.interactive) {
        if (e.features && e.features[0] && e.features[0].properties && e.features[0].properties['id']) {
          this.highlightFeature(mapObject, layer, e.features[0].properties['id']);
        }
      }
    }
  }

  private onLayerMouseLeave(mapObject: MapObject, layer: MapReferenceLayer) {
    if (!mapObject.editMode && !layer.interactiveOverride && layer.enabled) {
      mapObject.map.getCanvas().style.cursor = '';
      if (layer.interactive) {
        this.clearHighlightFeature(mapObject, layer);
      }

      if (layer.hasTooltip && mapObject.tooltipEnabled) {
        this.clearMapToolTip(mapObject);
      }
    }

  }

  public getLayerByLayerName(mapObject: MapObject, layerName: string): MapReferenceLayer {
    let layer: MapReferenceLayer = null;

    const checkSubCategories = (subCategories: MapReferenceLayerCategory[]) => {
      for (const subCat of subCategories) {
        for (const refLayer of subCat.layers) {
          if (refLayer.layerName === layerName) {
            layer = refLayer;
            break;
          }
        }

        if (layer) {
          break;
        }

        checkSubCategories(subCat.subCategories);
      }
    };

    if (mapObject && mapObject.referenceLayerCategories) {
      checkSubCategories(mapObject.referenceLayerCategories);
    }

    return layer;
  }

  getIdBasedFilter(layer: MapReferenceLayer, id: string | string[] | number | number[]): MapReferenceLayerFilter {
    let filterString: string = null;

    if (layer && layer.idColumn) {
      if (Array.isArray(id)) {
        const newId = JSON.stringify(id).replace(/"/g, '\'');
        filterString = `${layer.idColumn}::integer = ANY(ARRAY[${newId}])`;
      } else {
        filterString = `${layer.idColumn}::integer = ${id}`;
      }
    }

    return {
      a: filterString
    };
  }

  getTileIdBasedFilter(layer: MapReferenceLayer, tileId: string | number): MapReferenceLayerFilter {
    let filterString: string = null;

    if (layer) {
      if (tileId) {
        filterString = `tile_id = ${tileId}::integer`;
      }
    }

    return {
      a: filterString
    };
  }

  applyFilter(mapObject: MapObject, layer: MapReferenceLayer, filter: MapReferenceLayerFilter) {
    if (layer) {
      layer.customFilter = filter;
      layer.enableFilter = true;
    }
  }

  removeFilter(mapObject: MapObject, layer: MapReferenceLayer) {
    // Clear filter
    if (layer) {
      layer.customFilter = null;
      layer.enableFilter = false;
    }
  }

  applySearchFilter(layer: MapReferenceLayer, filter: MapReferenceLayerFilter) {
    layer.searchFilter = filter;
  }

  removeSearchFilter(layer: MapReferenceLayer) {
    layer.searchFilter = null;
  }

  // Recursively build a filter string
  private buildFilterString(filter: MapReferenceLayerFilter): string {
    let filterString = '';

    if (filter) {
      // Parse 'a' param
      if (filter.a) {
        if (typeof filter.a === 'string') {
          filterString = filter.a;
        } else {
          filterString = '(' + this.buildFilterString(filter.a) + ')';
        }
      }

      // Parse operation
      if (filter.op && filter.b) {
        filterString += ` ${filter.op} `;
      }

      // Parse 'b' param
      if (filter.b) {
        if (typeof filter.b === 'string') {
          filterString += filter.b;
        } else {
          filterString += '(' + this.buildFilterString(filter.b) + ')';
        }
      }
    }

    return filterString;
  }

  public getAllReferenceLayers(mapObject: MapObject): MapReferenceLayer[] {
    const layers: MapReferenceLayer[] = [];

    const checkSubCategories = (subCategories: MapReferenceLayerCategory[]) => {
      for (const subCat of subCategories) {
        for (const refLayer of subCat.layers) {
          layers.push(refLayer);
        }

        checkSubCategories(subCat.subCategories);
      }
    };

    if (mapObject && mapObject.referenceLayerCategories) {
      checkSubCategories(mapObject.referenceLayerCategories);
    }

    return layers;
  }

  toggleFilter(mapObject: MapObject, layer: MapReferenceLayer) {
    if (!layer.enableFilter) {
      this.turnOnFilter(mapObject, layer);
    } else {
      this.turnOffFilter(mapObject, layer);
    }
  }

  turnOnFilter(mapObject: MapObject, layer: MapReferenceLayer) {
    layer.enableFilter = true;
    this.updateLayer(mapObject, layer);
  }

  turnOffFilter(mapObject: MapObject, layer: MapReferenceLayer) {
    layer.enableFilter = false;
    this.updateLayer(mapObject, layer);
  }

  highlightFeature(mapObject: MapObject, layer: MapReferenceLayer, id: number, persistent?: boolean) {
    if (persistent) {
      layer.persistentId = id;
    }

    for (const l of layer.layerStyling) {
      if (l.metadata && l.metadata['ee:type']) {
        if (l.metadata['ee:type'] === 'default') {
          if (!layer.persistentId || layer.persistentId === id) {
            mapObject.map.setFilter(l.id, ['!=', 'id', id]);
          } else {
            mapObject.map.setFilter(l.id, ['any',
              ['!=', 'id', id],
              ['!=', 'id', layer.persistentId]
            ]);
          }
        } else if (l.metadata['ee:type'] === 'hover') {
          if (!layer.persistentId || layer.persistentId === id) {
            mapObject.map.setFilter(l.id, ['==', 'id', id]);
          } else {
            mapObject.map.setFilter(l.id, ['any',
              ['==', 'id', id],
              ['==', 'id', layer.persistentId]
            ]);
          }
        }
      }
    }
  }

  clearHighlightFeature(mapObject: MapObject, layer: MapReferenceLayer, removePersistence?: boolean) {
    if (removePersistence) {
      layer.persistentId = null;
    }

    for (const l of layer.layerStyling) {
      if (l.metadata && l.metadata['ee:type']) {
        if (l.metadata['ee:type'] === 'default') {
          mapObject.map.setFilter(l.id, ['!=', 'id', layer.persistentId ? layer.persistentId : '']);
        } else if (l.metadata['ee:type'] === 'hover') {
          mapObject.map.setFilter(l.id, ['==', 'id', layer.persistentId ? layer.persistentId : '']);
        }
      }
    }
  }

  showMapToolTip(mapObject: MapObject, tooltipText: string, displayName: string, featureProps: any, coordinates: any) {
    this.mapTooltipService.showTooltip(mapObject, tooltipText, displayName, featureProps, coordinates);
  }

  clearMapToolTip(mapObject: MapObject) {
    this.mapTooltipService.hideTooltip(mapObject);
  }

  private getSublayerLabelFromId(id: string, type: string): string {
    id = id.replace(/\_/g, '-');

    // const markerHaloHoverIds = ['-halo-hover'];
    // const markerHoverIds = ['-marker-hover'];
    const markerIds = ['-marker', '-circle'];
    // const lineCaseHoverIds = ['-line-casing-hover', '-case-hover', '-line-base-hover', '-stroke-base-hover'];
    // const lineHoverIds = ['-line-hover', '-stroke-hover'];
    // const lineCaseIds = ['-line-case', '-case', '-outline', '-line-casing', '-base-stroke', '-line-base', '-base', '-stroke-base'];
    const lineIds = ['-stroke', '-line'];
    const fillIds = ['fill'];
    const labelIds = ['-label'];

    if (type === 'fill' && (fillIds.filter(i => id.includes(i)).length > 0)) {
      return 'Area';
      // } else if (type === 'circle' && (markerHaloHoverIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Circle Halo (Hover)';
      // } else if (type === 'circle' && (markerHoverIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Circle (Hover)';
    } else if (type === 'circle' && (markerIds.filter(i => id.includes(i)).length > 0)) {
      return 'Circle';
      // } else if (type === 'line' && (lineCaseHoverIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Outline (Hover)';
      // } else if (type === 'line' && (lineHoverIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Line (Hover)';
      // } else if (type === 'line' && (lineCaseIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Outline';
    } else if (type === 'line' && (lineIds.filter(i => id.includes(i)).length > 0)) {
      return 'Line';
    } else if (type === 'symbol' && (labelIds.filter(i => id.includes(i)).length > 0)) {
      return 'Label';
    }

    return id;
  }

  private getPropertyLabel(property: string): string {
    const colorProperties = ['fill-color', 'circle-color', 'line-color', 'text-color'];
    const opacityProperties = ['fill-opacity'];
    const sizeProperties = ['text-size'];

    if (colorProperties.includes(property)) {
      return 'Color';
    } else if (opacityProperties.includes(property)) {
      return 'Opacity';
    } else if (sizeProperties.includes(property)) {
      return 'Size';
    }
  }

  private checkAndAddPaletteItem(paletteGroup: MapLayerPaletteGroupData, layerName: string, sublayer: any,
                                 type: string, propertyParent: string, property: string) {
    const colorProperties = ['fill-color', 'circle-color', 'line-color', 'text-color'];

    if (sublayer.type === type && ((propertyParent && sublayer[propertyParent] && sublayer[propertyParent][property])
      || (!propertyParent && sublayer[property]))) {
      let value = sublayer[propertyParent][property];

      if (colorProperties.includes(property)) {
        const color = tinycolor(value);
        value = color.isValid() ? color.toHexString() : value;
      }

      paletteGroup.paletteData.push({
        label: this.getSublayerLabelFromId(sublayer.id, type),
        type: type,
        propertyLabel: this.getPropertyLabel(property),
        propertyParent: propertyParent,
        property: property,
        value: value,
      });
    }
  }

  getLayerPaletteData(mapObject: MapObject, layer: MapReferenceLayer) {
    // Check and return existing palette group if it exists
    const existingPaletteGroup = this.getPaletteGroupFromLocalStorage(layer.layerName);

    if (existingPaletteGroup) {
      return existingPaletteGroup;
    }

    // Generate new list
    const excludeIds = ['-halo-hover', '-marker-hover', '-line-casing-hover', '-case-hover', '-line-base-hover', '-stroke-base-hover',
      '-line-hover', '-stroke-hover', '-line-case', '-case', '-outline', '-line-casing', '-base-stroke', '-line-base', '-base',
      '-stroke-base', '-image-hover', '-label-hover'];

    const paletteGroupList: MapLayerPaletteGroupData[] = [];

    if (layer && layer.layerStyling) {
      for (const sublayer of layer.layerStyling) {
        const cleanId = sublayer.id.replace(/\_/g, '-');

        if (!(excludeIds.filter(i => cleanId.includes(i)).length > 0)) {
          const paletteGroup: MapLayerPaletteGroupData = {
            layer: layer.layerName,
            subLayer: sublayer.id,
            paletteData: []
          };

          this.checkAndAddPaletteItem(paletteGroup, layer.layerName, sublayer, 'fill', 'paint', 'fill-color');
          this.checkAndAddPaletteItem(paletteGroup, layer.layerName, sublayer, 'fill', 'paint', 'fill-opacity');

          this.checkAndAddPaletteItem(paletteGroup, layer.layerName, sublayer, 'circle', 'paint', 'circle-color');

          this.checkAndAddPaletteItem(paletteGroup, layer.layerName, sublayer, 'line', 'paint', 'line-color');

          this.checkAndAddPaletteItem(paletteGroup, layer.layerName, sublayer, 'symbol', 'layout', 'text-size');
          this.checkAndAddPaletteItem(paletteGroup, layer.layerName, sublayer, 'symbol', 'paint', 'text-color');

          if (paletteGroup.paletteData.length) {
            paletteGroupList.push(paletteGroup);
          }
        }
      }
    }

    return paletteGroupList;
  }

  resetLayerStyling(mapObject: MapObject, layer: MapReferenceLayer, paletteGroup: MapLayerPaletteGroupData[]) {
    if (layer && layer.originalLayerStyling) {
      const styling = layer.originalLayerStyling;

      paletteGroup.forEach(g => {
        const sublayer = styling.find(l => l.id === g.subLayer);

        // If can be inverted - reset both label and halo
        if (sublayer.metadata && sublayer.metadata['ee:invert'] && sublayer.layout && sublayer.layout['text-field']) {
          mapObject.map.setPaintProperty(sublayer.id, 'text-color', sublayer['paint']['text-color']);
          mapObject.map.setPaintProperty(sublayer.id, 'text-halo-color', sublayer['paint']['text-halo-color']);
        }

        if (g.paletteData && g.paletteData.length) {
          g.paletteData.forEach(i => {
            if (sublayer && sublayer[i.propertyParent] && sublayer[i.propertyParent][i.property]) {
              if (i.propertyParent === 'paint') {
                mapObject.map.setPaintProperty(g.subLayer, i.property, sublayer[i.propertyParent][i.property]);
              } else if (i.propertyParent === 'layout') {
                mapObject.map.setLayoutProperty(g.subLayer, i.property, sublayer[i.propertyParent][i.property]);

                // Reset min zoom
                if (i.type === 'symbol' && layer.subLayerMinZoom && layer.subLayerMinZoom[g.subLayer]) {
                  mapObject.map.setLayerZoomRange(g.subLayer, layer.subLayerMinZoom[g.subLayer], 22);
                  layer.subLayerMinZoom[g.subLayer] = null;
                }
              }
            }
          });
        }

        // If can be inverted and inversion is enabled - re-invert after reset
        if (mapObject.invertedLabels && sublayer.metadata && sublayer.metadata['ee:invert'] && sublayer.layout && sublayer.layout['text-field']) {
          const textColor = mapObject.map.getPaintProperty(sublayer.id, 'text-color');
          const textHaloColor = mapObject.map.getPaintProperty(sublayer.id, 'text-halo-color');

          mapObject.map.setPaintProperty(sublayer.id, 'text-color', textHaloColor);
          mapObject.map.setPaintProperty(sublayer.id, 'text-halo-color', textColor);
        }
      });

      layer.layerStyling = styling;
      layer.legendStyling = layer.originalLegendStyling;
      layer.originalLayerStyling = null;
      layer.originalLegendStyling = null;
      layer.stylingOverwritten = false;
      localStorage.setItem('ee-map-layer-palette-' + layer.layerName, null);
    }
  }

  updateLayerStyling(mapObject: MapObject, layer: MapReferenceLayer, paletteGroup: MapLayerPaletteGroupData[]) {
    if (paletteGroup) {
      const styling = _.cloneDeep(layer.layerStyling);

      if (!layer.stylingOverwritten) {
        layer.originalLayerStyling = _.cloneDeep(layer.layerStyling);
        layer.originalLegendStyling = _.cloneDeep(layer.legendStyling);
        layer.stylingOverwritten = true;
      }

      // Save palette data to storage
      localStorage.setItem('ee-map-layer-palette-' + layer.layerName, JSON.stringify(paletteGroup));

      // Set new layer styling based on palette data
      paletteGroup.forEach(g => {
        const sublayer = styling.find(l => l.id === g.subLayer);
        if (g.paletteData && g.paletteData.length) {
          g.paletteData.forEach(i => {
            if (i.changed) {
              // patch for inverted

              if (sublayer && sublayer[i.propertyParent] && sublayer[i.propertyParent][i.property]) {
                sublayer[i.propertyParent][i.property] = i.value;
              }

              if (i.propertyParent === 'paint') {
                mapObject.map.setPaintProperty(g.subLayer, i.property, i.value);
              } else if (i.propertyParent === 'layout') {
                mapObject.map.setLayoutProperty(g.subLayer, i.property, i.value);

                // Check and override min zoom for any labels if applied
                if (i.type === 'symbol' && sublayer.minzoom) {
                  if (!layer.subLayerMinZoom) {
                    layer.subLayerMinZoom = {};
                  }
                  layer.subLayerMinZoom[g.subLayer] = sublayer.minzoom;

                  mapObject.map.setLayerZoomRange(g.subLayer, 0, 22);
                }
              }


            }
          });
        }
      });

      layer.layerStyling = styling;

      // Update legend styling based on the changes in palette data
      const findPaletteItem = (label: string, propertyLabel: string): MapLayerPaletteData => {
        for (const group of paletteGroup) {
          if (group && group.paletteData) {
            for (const item of group.paletteData) {
              if (item.label === label && item.propertyLabel === propertyLabel) {
                return item;
              }
            }
          }
        }
      };

      const circleColor = findPaletteItem('Circle', 'Color');

      if (circleColor) {
        layer.legendStyling.fillColor = circleColor.value as string;
      } else {
        const areaColor = findPaletteItem('Area', 'Color');
        const areaOpacity = findPaletteItem('Area', 'Opacity');
        const lineColor = findPaletteItem('Line', 'Color');

        if (areaColor) {
          const color = tinycolor(areaColor.value as string);

          if (areaOpacity && areaOpacity.value && !isNaN(areaOpacity.value as any)) {
            color.setAlpha(Number(areaOpacity.value));
          }

          layer.legendStyling.fillColor = color.isValid() ? color.toRgbString() : areaColor.value as string;

          if (lineColor) {
            layer.legendStyling.strokeColor = lineColor.value as string;
          }
        } else {
          if (lineColor) {
            layer.legendStyling.fillColor = lineColor.value as string;
          }
        }
      }
    }
  }

  private getPaletteGroupFromLocalStorage(layerName: string): MapLayerPaletteGroupData[] {
    const paletteGroupJSON = localStorage.getItem('ee-map-layer-palette-' + layerName);
    return (paletteGroupJSON ? JSON.parse(paletteGroupJSON) : null) as MapLayerPaletteGroupData[];
  }

  private checkLayerStylingOverwrites(mapObject: MapObject, layer: MapReferenceLayer) {
    if (layer) {
      const paletteGroup = this.getPaletteGroupFromLocalStorage(layer.layerName);

      if (paletteGroup) {
        mapObject.map.once('idle', () => {
          this.updateLayerStyling(mapObject, layer, paletteGroup);
        });
      }
    }
  }
}
