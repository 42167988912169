import {ErrorHandler, Injectable} from '@angular/core';
import {NotificationPanelService} from '../../../shared/services/notification-panel/notification-panel.service';
import {NotificationPopupType} from '../../enums/notification-popup-type';
import * as StackTrace from 'stacktrace-js';
import {UserEventCategories} from '../../../shared/enums/user-event-categories';
import {UserEvents} from '../../../shared/enums/user-events';
import {UserEventService} from '../user-event/user-event.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    private notificationPanelService: NotificationPanelService,
    private userEventService: UserEventService,
    private router: Router
  ) { }

  handleError(error) {
    if (error) {
      StackTrace.fromError(error)
        .then(stack => {
          let stackString = '';

          // Include the first stack item as part of the error message

          if (stack && stack.length > 0) {
            stackString = stack[0].toString();
          }

          this.notificationPanelService.showNotification({
            type: NotificationPopupType.ERROR,
            title: 'Client Error',
            message: error.name + ': ' + error.message + '. ' + stackString
          });

          this.userEventService.logUserEvent({
            category: UserEventCategories.ERROR,
            event: UserEvents.CLIENT_ERROR,
            metadata: {
              name: error.name,
              message: error.message.replace(/'/g, '\'\''),
              stack: stackString,
              url: this.router.url
            }
          });
        });

      throw error;
    }
  }
}
