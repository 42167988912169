import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {PlannedAreaFieldDefinitions} from '../../interfaces/planned-area-field-definitions';
import {GapApiCommsService} from '../../services/gap-api-comms/gap-api-comms.service';
import {PlannedAreaOptionDefinitions} from '../../interfaces/planned-area-option-definitions';

@Injectable({
  providedIn: 'root'
})
export class PlannedAreaOptionsDefinitionsResolverService implements Resolve<PlannedAreaOptionDefinitions> {

  private plannedAreaOptionsDefinitionsSubject: BehaviorSubject<PlannedAreaOptionDefinitions>;

  constructor(private gapApiComms: GapApiCommsService) {
    this.plannedAreaOptionsDefinitionsSubject = new BehaviorSubject<PlannedAreaOptionDefinitions>(null);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<PlannedAreaOptionDefinitions> {
    return this.gapApiComms.getOptionsDefinitions()
      .pipe(
        tap(
          data => {
            this.plannedAreaOptionsDefinitionsSubject.next(data);
          }
        ),
        catchError(
          error => {
            console.log(error);
            return new Observable<PlannedAreaOptionDefinitions>(null);
          }
        )
      );
  }

  getSubject(): BehaviorSubject<PlannedAreaOptionDefinitions> {
    return this.plannedAreaOptionsDefinitionsSubject;
  }
}
