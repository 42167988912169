import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private onLoadingChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isLoading = false;

  private loadingMessage: string = null;

  requestCount = 0;
  private stopLoadingTimeout = null;

  private readonly exclusionList = [
    '/eagle-eye/search?',
    '/eagle-eye/user-event',
    '/notifications/current-user',
    'https://api.nearmap.com/coverage/v2/poly/',
    'https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer/0/query',
    environment.tileyServer + '/search'
  ];

  constructor() {
  }

  private inExclusionList(url: string) {
    const urlStem = url.replace(environment.backendServer.mainPath, '');
    for (const e of this.exclusionList) {
      if (urlStem.startsWith(e)) {
        return true;
      }
    }

    return false;
  }

  private startLoading() {
    this.isLoading = true;
    this.onLoadingChangeSubject.next(this.isLoading);
  }

  private stopLoading() {
    this.isLoading = false;
    this.onLoadingChangeSubject.next(this.isLoading);
  }

  incrementLoading(url?: string) {
    if (url && this.inExclusionList(url)) {
      return;
    }

    if (this.stopLoadingTimeout) {
      clearTimeout(this.stopLoadingTimeout);
    }

    this.requestCount++;

    if (this.requestCount === 1) {
      this.startLoading();
    }
  }

  decrementLoading(url?: string) {
    if (url && this.inExclusionList(url)) {
      return;
    }

    if (this.requestCount > 0) {
      this.requestCount--;
    }

    if (this.requestCount === 0) {
      this.stopLoadingTimeout = setTimeout(() => {
        this.stopLoading();
      }, 50);
    }
  }

  setLoadingMessage(message: string) {
    this.loadingMessage = message;
  }

  clearLoadingMessage() {
    this.loadingMessage = null;
  }

  getLoadingMessage(): string {
    return this.loadingMessage;
  }

  onUpdate(): BehaviorSubject<boolean> {
    return this.onLoadingChangeSubject;
  }
}
