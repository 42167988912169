import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiCommsService} from '../../services/api-comms/api-comms.service';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {tap} from 'rxjs/operators';
import {DocumentLinks} from '../../interfaces/document-links';

@Injectable({
  providedIn: 'root'
})
export class DocumentLinksResolverService implements Resolve<DocumentLinks[]> {

  private onDocumentLinksReadySubject: BehaviorSubject<DocumentLinks[]> = new BehaviorSubject<DocumentLinks[]>(null);

  constructor(private apiCommsService: ApiCommsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DocumentLinks[]> {
    return this.apiCommsService.getDocumentLinks()
      .pipe(
        tap(schoolDates => {
          this.onDocumentLinksReadySubject.next(schoolDates);
        })
      );
  }

  onDocumentLinksReady(): Observable<DocumentLinks[]> {
    return this.onDocumentLinksReadySubject;
  }
}
