<div class="info-component">
  <button mat-icon-button class="close-x-button" (click)="close(false)">
    <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>
  </button>

  <h1 mat-dialog-title>
    <mat-icon fontSet="fas" [fontIcon]="'fa-' + data.icon"></mat-icon>
    {{data.title}}
  </h1>

  <!-- <div mat-dialog-content [perfectScrollbar]>
    <div [innerHTML]="data.message"></div>
  </div> -->

  <div mat-dialog-content [innerHTML]="data.message"
    [perfectScrollbar]="{suppressScrollX : true, suppressScrollY : true}">
  </div>

  <div mat-dialog-actions fxLayoutAlign="end end">
    <button mat-raised-button class="primary" (click)="close(false)">OK</button>
  </div>
</div>