import { Injectable } from '@angular/core';
import {NotificationPollingMessage} from '../../interfaces/notification-polling-message';
import {UserNotificationsDialogComponent} from '../../components/user-notifications-dialog/user-notifications-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationDialogService {

  constructor(
    private dialog: MatDialog
  ) { }

  showDialog(notification: NotificationPollingMessage) {
    this.dialog.open(UserNotificationsDialogComponent, {
      width: '680px',
      data: notification
    }).afterClosed().subscribe();
  }
}
