import { Injectable } from '@angular/core';
import {NotificationMessage} from '../../../core/interfaces/notification-message';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationPanelService {

  private notificationSubject: Subject<NotificationMessage> = new Subject<NotificationMessage>();

  constructor() { }

  onNotification(): Subject<NotificationMessage> {
    return this.notificationSubject;
  }

  showNotification(notificationMessage: NotificationMessage) {
    this.notificationSubject.next(notificationMessage);
  }
}
