<div class="snowfall-container">
    <div
      *ngFor="let flake of flakes"
      class="snowflake"
      [style.left]="flake.left + 'vw'"
      [style.animation-duration]="flake.animationDuration + 's'"
      [style.animation-delay]="flake.animationDelay + 's'"
      [style.width.px]="flake.size"
      [style.height.px]="flake.size"
      [ngClass]="flake.colorClass"
    ></div>
  </div>