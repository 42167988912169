import { Injectable } from '@angular/core';
import {ApiCommsService} from '../api-comms/api-comms.service';
import {UserEvent} from '../../interfaces/user-event';

@Injectable({
  providedIn: 'root'
})
export class UserEventService {

  constructor(private apiCommsService: ApiCommsService) { }

  logUserEvent(userEvent: UserEvent) {
    this.apiCommsService.postUserEvent(userEvent).subscribe();
  }
}
