import { Component, OnInit } from '@angular/core';
import {ApiCommsService} from '../../services/api-comms/api-comms.service';
import {first, tap} from 'rxjs/operators';
import {MaintenanceModeService} from '../../services/maintenance-mode/maintenance-mode.service';
import {MaintenanceModeSettings} from '../../interfaces/maintenance-mode-settings';
import {Router} from '@angular/router';
import {AppRoutes} from '../../enums/app-routes';

@Component({
  selector: 'ee-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  maintenanceModeSettings: MaintenanceModeSettings = null;
  gifUrl = null;

  constructor(
    private apiCommsService: ApiCommsService,
    private router: Router,
    private maintenanceModeService: MaintenanceModeService
  ) { }

  ngOnInit() {
    this.getMaintenanceModeSettings();
    this.getGiphy();
  }

  private getMaintenanceModeSettings() {
    this.maintenanceModeService.getMaintenanceModeSettings()
      .pipe(
        first(),
        tap(response => {
          if (response && !response.maintenanceModeActive) {
            this.router.navigate([AppRoutes.LOGIN]);
          }
        })
      )
      .subscribe(response => {
        this.maintenanceModeSettings = response;
      });
  }

  private getGiphy() {
    this.apiCommsService
      .getGiphyImage('sleep')
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.gifUrl = result;
        }
      });
  }
}
