import {Injectable} from '@angular/core';
import {MapObject} from '../../interfaces/map-object';
import {MapBasemapService} from '../map-basemap/map-basemap.service';
import {StreetViewService} from '../street-view/street-view.service';
import {MapMeasureToolsService} from '../map-measure-tools/map-measure-tools.service';
import {StreetViewObject} from '../../interfaces/street-view-object';
import {MapMeasureMode} from '../../enums/map-measure-mode';
import {MapInspectorService} from '../map-inspector/map-inspector.service';
import {MapSearchService} from '../map-search/map-search.service';
import {UserEventCategories} from '../../enums/user-event-categories';
import {UserEvents} from '../../enums/user-events';
import {UserEventService} from '../../../core/services/user-event/user-event.service';
import {MapImageExportService} from '../map-image-export/map-image-export.service';
import {MapReachabilityService} from '../map-reachability/map-reachability.service';
import {MatDialog} from '@angular/material/dialog';
import {MapExportDialogComponent} from '../../components/map-export-dialog/map-export-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MapToolbarService {

  private streetViewEnabled = false;

  constructor(
    private mapBasemapService: MapBasemapService,
    private mapMeasureToolsService: MapMeasureToolsService,
    private streetViewService: StreetViewService,
    private mapInspectorService: MapInspectorService,
    private mapReachabilityService: MapReachabilityService,
    private mapSearchService: MapSearchService,
    private mapImageExportService: MapImageExportService,
    private userEventService: UserEventService,
    public dialog: MatDialog,
  ) {}

  enableStreetView(streetViewObject: StreetViewObject, mapObject: MapObject) {
    this.streetViewEnabled = true;
    this.streetViewService.enable(streetViewObject, mapObject);

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.STREET_VIEW_ON
    });
  }

  disableStreetView(streetViewObject: StreetViewObject) {
    this.streetViewEnabled = false;
    this.streetViewService.disable(streetViewObject);

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.STREET_VIEW_OFF
    });
  }

  enableLineMeasureTool(mapObject: MapObject) {
    this.mapMeasureToolsService.enableMeasureTool(mapObject, MapMeasureMode.MEASURE_LINE);

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.MEASURE_LINE
    });
  }

  enableAreaMeasureTool(mapObject: MapObject) {
    this.mapMeasureToolsService.enableMeasureTool(mapObject, MapMeasureMode.MEASURE_POLYGON);

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.MEASURE_POLYGON
    });
  }

  enableTravelTool(mapObject: MapObject, mode: MapMeasureMode) {
    this.mapMeasureToolsService.enableMeasureTool(mapObject, mode);

    let eventMode = null;

    switch (mode) {
      case MapMeasureMode.MEASURE_TRAVEL_WALKING:
        eventMode = UserEvents.MEASURE_TRAVEL_WALKING;
        break;
      case MapMeasureMode.MEASURE_TRAVEL_CYCLING:
        eventMode = UserEvents.MEASURE_TRAVEL_CYCLING;
        break;
      case MapMeasureMode.MEASURE_TRAVEL_DRIVING:
        eventMode = UserEvents.MEASURE_TRAVEL_DRIVING;
        break;
      case MapMeasureMode.MEASURE_TRAVEL_TRANSPORT:
        eventMode = UserEvents.MEASURE_TRAVEL_TRANSPORT;
        break;
    }

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: eventMode
    });
  }

  enableReachabilityTool(mapObject: MapObject) {
    this.mapReachabilityService.enable(mapObject);

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.MEASURE_TRAVEL_REACHABILITY
    });
  }

  enableRadiusMeasureTool(mapObject: MapObject) {
    this.mapMeasureToolsService.enableMeasureTool(mapObject, MapMeasureMode.MEASURE_RADIUS);

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.MEASURE_RADIUS
    });
  }

  enableInspectorTool(mapObject: MapObject) {
    this.mapInspectorService.enable(mapObject);

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.MAP_INSPECTOR
    });
  }

  //#10993
  captureAndSaveImage(mapObject: MapObject) {
    const dialogRef = this.dialog.open(MapExportDialogComponent, {
      width: '380px'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        const title = result && result.title ? result.title : null;
        const subtitle = result && result.subtitle ? result.subtitle : null;
        const filename = result && result.filename ? result.filename : null;
        if(result){
        this.mapImageExportService.captureAndSaveImage(mapObject, title, subtitle, filename);}
      });

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.IMAGE_EXPORT
    });
  }

  toggleFullscreen(mapObject: MapObject) {
    const oldBounds = mapObject.map.getBounds();
   // const fixedZoomLevel = 5.5;
    if (mapObject && mapObject.hasOwnProperty('isFullscreen')) {
      mapObject.isFullscreen = !mapObject.isFullscreen;
    } else {
      mapObject.isFullscreen = true;
    }

    setTimeout(() => {
      mapObject.map.once('resize', () => {
        const vPadding = 0;
        const hPadding = 0;

        // mapObject.map.fitBounds(oldBounds, {
        //   animate: false,
        //   padding: {
        //     top: vPadding,
        //     bottom: vPadding,
        //     left: hPadding,
        //     right: hPadding
        //   }
        // });
        //mapObject.map.setZoom(fixedZoomLevel);
      });
      mapObject.map.resize();
    });
  }

  forceClearSearch(mapObject: MapObject) {
    this.mapSearchService.forceClearSearch(mapObject);
  }
}
