import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ee-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  errorMessage: string = null;
  gifUrl = null;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.errorMessage = this.route.snapshot.queryParams.message;
  }

  reportError() {
    const link = document.createElement('a');
    link.href = `mailto:sci@det.nsw.edu.au?subject=Eagle%20Eye%20-%20Error&body=${this.errorMessage}`;
    link.click();
    link.remove();
  }

}
