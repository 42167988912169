<div class="toolbar-panel">
    <button mat-icon-button class="panel-close-button" (click)="close()">
        <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
    </button>
    <h3>Reference Layers</h3>
    <div class="newline">
      <span *ngIf="hasData()" class="show-reference-layer-label" (click)="toggleShowLayers()">
        {{ showNewLayers ? 'Show All Reference Layers' : 'Show New Reference Layers' }}
      </span>
    </div>
    <div class="search-container">
      <mat-form-field class="ref-search" [floatLabel]="'never'">
        <input matInput type="search"
               placeholder="Start typing to search for a reference layer" [formControl]="searchValue">
        <mat-icon matSuffix fontSet="far" fontIcon="fa-times" (click)="clearSearch()" *ngIf="showClear"></mat-icon>
      </mat-form-field>
    </div>

    <mat-accordion [multi]="true" [perfectScrollbar] #refLayerList>
      <!-- <ng-container *ngTemplateOutlet="tree; context:{node: referenceLayerCategories}"></ng-container> -->
      <ng-container *ngTemplateOutlet="tree; context:{node: filteredReferenceLayerCategories || referenceLayerCategories}"></ng-container>
      <ng-template #tree let-node="node">
        <ng-container *ngFor="let cat of node;">
          <mat-expansion-panel *ngIf="cat.selectable" [expanded]="cat.expanded" [hidden]="cat.hiddenBySearch" (afterCollapse)="updateScrollContainer()">
            <mat-expansion-panel-header>
              <mat-panel-title class="category-title">
                <mat-icon fontSet="far" [fontIcon]="'fa-' + cat.icon"></mat-icon>
                <span>{{cat.category}}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <mat-selection-list>
                <ng-container *ngFor="let layer of cat.layers;">
                  <mat-list-option *ngIf="layer.selectable" (click)="!layer.loading && toggleLayer(layer)"
                                   [checkboxPosition]="'before'" [selected]="layer.enabled" [hidden]="layer.hiddenBySearch"
                                   [disabled]="layer.loading">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <mat-progress-spinner *ngIf="layer.loading"
                                            class="ref-loading spinner" mode="indeterminate"></mat-progress-spinner>
                      <div class="label" [ngClass]="{'interactive': layer.interactive}">
                        {{layer.displayName}}
                      </div>
                      <div fxFlex="1 0 5px"></div>
                      <div *ngIf="layer.isNew" class="red-indicator">New</div>
                      <mat-icon fontSet="fas" fontIcon="fa-filter" class="icon" *ngIf="layer.customFilter"></mat-icon>
                      <mat-icon fontSet="fas" fontIcon="fa-info-circle" class="icon info"
                                eeTooltip [tooltipText]="layer.tooltipHTML" tooltipClass="wide-tooltip ref-layer" [fixedOnClick]="true"></mat-icon>
                      <mat-icon fontSet="fas" fontIcon="fa-star" class="icon favorite" 
                                [ngClass]="{'favorite-active': layer.favorite}" 
                                (click)="toggleFavorite(layer); $event.stopPropagation();"></mat-icon>
                      </div>
                  </mat-list-option>
                </ng-container>
              </mat-selection-list>
              <ng-container *ngIf="cat.subCategories">
                <ng-container *ngTemplateOutlet="tree; context: {node: cat.subCategories}"></ng-container>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </ng-template>

    </mat-accordion>
    <div *ngIf="!hasAtLeastOneResult && searchValue.value" class="no-results">
      There are no results for <span class="bold italic">{{searchValue.value}}</span>
    </div>
</div>
