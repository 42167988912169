export enum OtpTripModes {
  TRAIN = 'RAIL',
  METRO = 'SUBWAY',
  LIGHT_RAIL = 'TRAM',
  BUS = 'BUS',
  BICYCLE = 'BICYCLE',
  FERRY = 'FERRY',
  CAR = 'CAR',
  WALK = 'WALK'
}
