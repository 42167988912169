import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {CurrentUserResolverService} from '../../resolvers/current-user-resolver/current-user-resolver.service';
import {catchError, map} from 'rxjs/operators';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {AuthService} from '../../services/auth/auth.service';
import {ApiCommsService} from '../../services/api-comms/api-comms.service';
import {UserAgreementService} from '../../services/user-agreement/user-agreement.service';

@Injectable({
  providedIn: 'root'
})
export class UserAgreementGuard implements CanActivate {

  constructor(
    private currentUserResolver: CurrentUserResolverService,
    private userAgreementService: UserAgreementService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.currentUserResolver.getCurrentUserData()
      .pipe(map((data) => {
        if (data && !data.tocAgreement) {
          this.userAgreementService.showAndUpdateUserDialog();
        }

        return true;
      }));
  }
}
