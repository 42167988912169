<div fxLayout="row" fxLayoutAlign="center center" *ngIf="mapObject?.currentBasemap?.provider==='Nearmap'">
    <button mat-button class="map-date-selector-change" [ngClass]="{'disabled': prevDisabled || mapObject?.currentBasemap?.isLoading}"
            (click)="previousImageInList()" [disabled]="prevDisabled || mapObject?.currentBasemap?.isLoading">
        <mat-icon fontSet="fas" fontIcon="fa-chevron-left"></mat-icon>
    </button>
    <mat-form-field>
        <mat-select [(value)]="selectedDate" (selectionChange)="updateSelectedImageDate()" class="map-date-selector-panel" [disabled]="mapObject?.currentBasemap?.isLoading">
            <mat-option *ngFor="let date of imageDates" [value]="date" style="font-family: 'Open Sans', sans-serif; font-size: 11px;">
                {{date !== 'Latest Image' ? (date | date: 'dd MMM yy') : 'Latest Image'}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-button class="map-date-selector-change" [ngClass]="{'disabled': nextDisabled || mapObject?.currentBasemap?.isLoading}"
            (click)="nextImageInList()" [disabled]="nextDisabled || mapObject?.currentBasemap?.isLoading">
        <mat-icon fontSet="fas" fontIcon="fa-chevron-right"></mat-icon>
    </button>
</div>
