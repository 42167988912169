import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MaintenanceModeResponse} from '../../../admin/interfaces/maintenance-mode-response';
import {MaintenanceModeSettings} from '../../interfaces/maintenance-mode-settings';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceModeService {

  constructor(private http: HttpClient) { }

  private readonly maintenanceUrl = environment.backendServer.mainPath + environment.backendServer.maintenance;

  checkMaintenanceMode(): Observable<boolean> {
    const url = this.maintenanceUrl + '/check';

    return this.http.get<MaintenanceModeResponse>(url)
      .pipe(
        map(response => {
          return response && response.maintenanceMode;
        })
      );
  }

  getMaintenanceModeSettings(): Observable<MaintenanceModeSettings> {
    const url = this.maintenanceUrl + '/settings';

    return this.http.get<MaintenanceModeSettings>(url);
  }

  updateMaintenanceModeSettings(maintenanceModeSettings: MaintenanceModeSettings): Observable<MaintenanceModeSettings> {
    const url = this.maintenanceUrl + '/update';

    return this.http.put<MaintenanceModeSettings>(url, maintenanceModeSettings);
  }
}
