import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'ee-map-export-dialog',
  templateUrl: './map-export-dialog.component.html',
  styleUrls: ['./map-export-dialog.component.scss']
})
export class MapExportDialogComponent implements OnInit {

  exportSettings = new FormGroup({
    title: new FormControl(''),
    subtitle: new FormControl(''),
    filename: new FormControl('')
  });

  constructor(
    private dialogRef: MatDialogRef<MapExportDialogComponent>,
    @Inject (MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.exportSettings.valid) {
      this.dialogRef.close({
        title: this.exportSettings.get('title').value,
        subtitle: this.exportSettings.get('subtitle').value,
        filename: this.exportSettings.get('filename').value
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

}
