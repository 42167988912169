export enum AppRoutes {
  LOGIN = 'login',
  UNSUBSCRIBE = 'unsubscribe',
  HUB = 'hub',
  GAP = 'gap',
  VISION = 'vision',
  STORM = 'phoenix',
  TAM = 'tam',
  PRO = 'pro',
  MEDIA = 'media',
  ADMIN = 'admin',
  MAINTENANCE = 'maintenance',
  RESTRICTED = 'restricted',
  RESTRICTED_MODULE = 'restricted-module',
  ERROR = 'error'
}
