export enum HubViewType {
  NSW = 'NSW',
  LGA = 'LGA',
  FED = 'FED',
  SED = 'SED',
  DET = 'DET',
  PRINCIPAL_NETWORK = 'PRINCIPAL_NETWORK',
  OPERATIONAL_DIRECTORATE = 'OPERATIONAL_DIRECTORATE',
  DPIE_REGION = 'DPIE_REGION',
  CLUSTER = 'CLUSTER',//#12120
  CUSTOM = 'CUSTOM',
  CUSTOM_REF_LAYER = 'CUSTOM_REF_LAYER',
  REACHABILITY = 'REACHABILITY'
}
