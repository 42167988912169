export enum UserRoles {
  ADMIN = 'admin',
  GAP_VIEWER = 'paViewer',
  GAP_EDITOR = 'paEditor',
  TAM_VIEWER = 'tamViewer',
  HUB_VIEWER = 'hubViewer',
  VISION_SNAPSHOT = 'visionSnapshot',
  VISION_VIEWER = 'visionViewer',
  VISION_PLANNER = 'visionPlanner',
  VISION_REVIEWER = 'visionReviewer',
  VISION_ADMIN = 'visionAdmin',
  VISION_TEMPLATE = 'visionTemplate',
  VISION_ENDORSER = 'visionEndorser'
}
