import {ApplicationRef, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {MapObject} from '../../../shared/interfaces/map-object';
import {PlannedAreaMappingService} from '../planned-area-mapping/planned-area-mapping.service';
import {PlannedAreaAnnotation} from '../../interfaces/planned-area-annotation';
import {AnnotationMarkerComponent} from '../../components/annotation-marker/annotation-marker.component';
import {PlannedAreaAnnotationTypes} from '../../interfaces/planned-area-annotation-types';
import * as mapboxgl from 'mapbox-gl';
import Marker = mapboxgl.Marker;
import {PlannedAreaAnnotationMarker} from '../../interfaces/planned-area-annotation-marker';
import {AnnotationMarkerPopupComponent} from '../../components/annotation-marker-popup/annotation-marker-popup.component';
import {MarkerComponent} from '../../../shared/interfaces/marker-component';
import {Observable, Subject, Subscription, zip} from 'rxjs';
import {MapboxService} from '../../../shared/services/mapbox/mapbox.service';
import {MapImage} from '../../../shared/interfaces/map-image';
import {MapboxDrawModes} from '../../../shared/enums/mapbox-draw-modes';
import {
  PlannedAreaAnnotationTypesResolverService
} from '../../resolvers/planned-area-annotations-types-resolver/planned-area-annotations-types-resolver.service';
import {
  PlannedAreaAnnotationResolverService
} from '../../resolvers/planned-area-annotations-resolver/planned-area-annotations-resolver.service';
import {environment} from '../../../../environments/environment';
import {MapEditMode} from '../../../shared/enums/map-edit-mode';
import {Geometry} from 'geojson';

@Injectable({
  providedIn: 'root'
})
export class PlannedAreaAnnotationService {

  private mapObject: MapObject = null;
  private annotationUpdateSubject: Subject<PlannedAreaAnnotation> = new Subject<PlannedAreaAnnotation>();

  private annotationTypes: PlannedAreaAnnotationTypes[];
  private annotationMarkers: PlannedAreaAnnotationMarker[] = [];
  private annotationSubscription: Subscription = null;

  private selectedAnnotation: PlannedAreaAnnotationMarker;
  private updatedAnnotationGeom: Geometry;

  private isNew = false;
  private editMode = false;

  private eventsCreated = false;

  private drawStyles = [{
    'id': 'annotation-image-edit-circle',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'true']],
    'paint': {
      'circle-radius': 18,
      'circle-color': '#FFFFFF'
    }
  }, {
    'id': 'annotation-image',
    'type': 'symbol',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature']],
    'layout': {
      'icon-image': 'annotation-{user_annotTypeId}',
      'icon-size': 1,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    }
  }];

  constructor(private plannedAreaAnnotationTypesResolver: PlannedAreaAnnotationTypesResolverService,
              private plannedAreaAnnotationsResolverService: PlannedAreaAnnotationResolverService,
              private plannedAreaMappingService: PlannedAreaMappingService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private injector: Injector,
              private appRef: ApplicationRef,
              private mapboxService: MapboxService) {
    this.onMapReady();
  }

  // Wait until map is ready before creating annotations
  onMapReady() {
    this.plannedAreaMappingService.onMapReady()
      .subscribe(
        mapReady => {
          if (mapReady) {
            this.mapObject = this.plannedAreaMappingService.getMapObject();
            this.watchAnnotationTypes();
            this.watchAnnotations();
          }
        }
      );
  }

  watchAnnotationTypes() {
    this.plannedAreaAnnotationTypesResolver.getSubject()
      .subscribe(
        result => {
          if (result) {
            this.annotationTypes = result;
            this.buildMapImages();
          }
        }
      );
  }

  watchAnnotations() {
    if (!this.annotationSubscription) {
      this.annotationSubscription = zip(
        this.plannedAreaAnnotationTypesResolver.getSubject(),
        this.plannedAreaAnnotationsResolverService.getSubject()
      ).subscribe(
        result => {
          if (result && result[0] && result[1]) {
            this.updateAnnotations(result[1]);
          }
        }
      );
    }
  }

  buildMapImages() {
    const mapImages: MapImage[] = [];

    for (const type of this.annotationTypes) {
      mapImages.push({
        id: 'annotation-' + type.annotTypeId,
        url: environment.assetsFolder + type.icon + '.png'
      });
    }

    if (mapImages) {
      this.mapboxService.loadImages(this.mapObject, mapImages);
    }
  }

  getAnnotationByTypeId(annotTypeId: number): PlannedAreaAnnotationTypes {
    let annotType: PlannedAreaAnnotationTypes = null;

    for (const type of this.annotationTypes) {
      if (type.annotTypeId === annotTypeId) {
        annotType = type;
        break;
      }
    }

    return annotType;
  }

  updateAnnotations(annotations: PlannedAreaAnnotation[]) {
    this.clearAnnotations();
    for (const annotation of annotations) {
      this.createAnnotation(annotation);
    }
  }

  clearAnnotations() {
    for (const annotation of this.annotationMarkers) {
      if (annotation.popup) {
        annotation.popup.marker.remove();
        annotation.popup.componentRef.destroy();
      }
      if (annotation.icon) {
        annotation.icon.marker.remove();
        annotation.icon.componentRef.destroy();
      }
    }

    this.annotationMarkers = [];
  }

  removeAnnotation(annotation: PlannedAreaAnnotationMarker) {
    const index = this.annotationMarkers.indexOf(annotation);

    if (index >= 0) {
      if (annotation.popup) {
        annotation.popup.marker.remove();
        annotation.popup.componentRef.destroy();
      }
      if (annotation.icon) {
        annotation.icon.marker.remove();
        annotation.icon.componentRef.destroy();
      }

      this.annotationMarkers.splice(index, 1);
    }
  }

  createIconMarkerComponent(annotation: PlannedAreaAnnotation): MarkerComponent<AnnotationMarkerComponent> {
    const annotType = this.getAnnotationByTypeId(annotation.annotTypeId);
    // Create DOM component
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(AnnotationMarkerComponent)
      .create(this.injector);

    // Attach marker component to app
    this.appRef.attachView(componentRef.hostView);
    componentRef.instance.set(this.mapObject, annotation.annotId, annotType.icon);

    const coords = (annotation.geometry as GeoJSON.Point).coordinates;

    const marker = new Marker(componentRef.instance.el.nativeElement)
      .setLngLat([coords[0], coords[1]])
      .addTo(this.mapObject.map);

    return {
      componentRef: componentRef,
      marker: marker
    };
  }

  createPopupMarkerComponent(annotation: PlannedAreaAnnotation,
                             markerClickSubject: Observable<boolean>): MarkerComponent<AnnotationMarkerPopupComponent> {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(AnnotationMarkerPopupComponent)
      .create(this.injector);

    this.appRef.attachView(componentRef.hostView);
    componentRef.instance.set(this.mapObject, annotation, markerClickSubject, this.annotationUpdateSubject, this.isNew);

    this.onAnnotationDelete(componentRef.instance.onDelete());
    this.onAnnotationEdit(componentRef.instance.onEdit());
    this.onAnnotationCancel(componentRef.instance.onCancel());
    this.onAnnotationSave(componentRef.instance.onSave());

    const coords = (annotation.geometry as GeoJSON.Point).coordinates;

    const marker = new Marker(componentRef.instance.el.nativeElement, {
      offset: [125 + 16 + 8, 0]
    })
      .setLngLat([coords[0], coords[1]])
      .addTo(this.mapObject.map);

    return {
      componentRef: componentRef,
      marker: marker
    };
  }

  createAnnotation(annotation: PlannedAreaAnnotation): PlannedAreaAnnotationMarker {
    const icon = this.createIconMarkerComponent(annotation);
    const clickEventSubject = icon.componentRef.instance.getClickEventSubject();
    const popup = this.createPopupMarkerComponent(annotation, clickEventSubject);

    const annotationMarker = {
      annotation: annotation,
      icon: icon,
      popup: popup
    };

    this.annotationMarkers.push(annotationMarker);

    return annotationMarker;
  }

  getAnnotationMarkerById(annotId: number): PlannedAreaAnnotationMarker {
    for (const item of this.annotationMarkers) {
      if (item.annotation.annotId === annotId) {
        return item;
      }
    }
  }

  disableEditMode() {
    this.mapboxService.disableEditMode(this.mapObject);
    this.editMode = false;

    this.selectedAnnotation = null;
  }

  onAnnotationDelete(observable: Observable<number>) {
    observable.subscribe(
      annotId => {
        if (this.editMode && this.selectedAnnotation && this.selectedAnnotation.annotation.annotId === annotId) {
          this.removeAnnotation(this.selectedAnnotation);
          this.disableEditMode();
        }
      }
    );
  }

  onAnnotationEdit(observable: Observable<number>) {
    observable.subscribe(
      annotId => {
        // Hide annotation
        this.selectedAnnotation = this.getAnnotationMarkerById(annotId);
        this.updatedAnnotationGeom = this.selectedAnnotation.annotation.geometry;
        this.selectedAnnotation.icon.componentRef.instance.hide();

        // Set up and enable drawing mode
        this.mapboxService.enableEditMode(this.mapObject, MapEditMode.ANNOTATIONS, this.drawStyles);

        // Add annotation geometry to edit
        const featureIds = this.mapboxService.addFeatureToDrawControl(this.mapObject, this.selectedAnnotation.annotation);

        this.createEvents();
        this.editMode = true;
      }
    );
  }

  onAnnotationCancel(observable: Observable<number>) {
    observable.subscribe(
      annotId => {
        if (this.editMode && this.selectedAnnotation && this.selectedAnnotation.annotation.annotId === annotId) {
          if (this.isNew) {
            this.selectedAnnotation.popup.marker.remove();
            this.selectedAnnotation.popup.componentRef.destroy();
            this.isNew = false;
          } else {
            this.updateSelectedAnnotationGeometry({
              type: 'Point',
              coordinates: this.selectedAnnotation.icon.marker.getLngLat().toArray()
            });
            this.selectedAnnotation.popup.marker.setLngLat(this.selectedAnnotation.icon.marker.getLngLat());
            this.selectedAnnotation.icon.componentRef.instance.show();
          }

          this.disableEditMode();
        }
      }
    );
  }

  onAnnotationSave(observable: Observable<PlannedAreaAnnotation>) {
    observable.subscribe(
      annotation => {
        if (this.editMode && this.selectedAnnotation && (this.selectedAnnotation.annotation.annotId === annotation.annotId || this.isNew)) {
          if (this.isNew) {
            this.selectedAnnotation.popup.marker.remove();
            this.selectedAnnotation.popup.componentRef.destroy();
            this.isNew = false;
          } else {
            this.removeAnnotation(this.selectedAnnotation);
          }

          // Disable edit mode
          this.disableEditMode();

          // Re create annotation
          annotation.geometry = this.updatedAnnotationGeom;
          this.selectedAnnotation = this.createAnnotation(annotation);
          this.selectedAnnotation.popup.componentRef.instance.show();
        }
      }
    );
  }

  updateSelectedAnnotationGeometry(geometry: Geometry) {
    this.updatedAnnotationGeom = geometry;
    const coords = (this.updatedAnnotationGeom as GeoJSON.Point).coordinates;
    this.selectedAnnotation.popup.marker.setLngLat([coords[0], coords[1]]);

    const updatedAnnotation: PlannedAreaAnnotation = JSON.parse(JSON.stringify(this.selectedAnnotation.annotation));
    updatedAnnotation.geometry = this.updatedAnnotationGeom;

    this.annotationUpdateSubject.next(updatedAnnotation);
  }

  updateNewAnnotation(feature: any) {
    this.selectedAnnotation.annotation.geometry = feature.geometry;
    this.updatedAnnotationGeom = this.selectedAnnotation.annotation.geometry;

    this.mapObject.draw.setFeatureProperty(feature.id, 'annotTypeId', this.selectedAnnotation.annotation.annotTypeId);

    this.selectedAnnotation.popup = this.createPopupMarkerComponent(this.selectedAnnotation.annotation, null);
  }

  createEvents() {
    if (!this.eventsCreated) {

      this.mapObject.map.on('draw.update', (e) => {
        if (this.editMode && e.action && e.action === 'move' && e.features && e.features.length === 1 && this.selectedAnnotation) {
          this.updateSelectedAnnotationGeometry(e.features[0].geometry);
        }
      });

      this.mapObject.map.on('draw.create', (e) => {
        if (this.editMode && this.isNew && e.features && e.features.length === 1) {
          this.updateNewAnnotation(e.features[0]);
        }
      });

      this.eventsCreated = true;
    }
  }

  createNewAnnotation(annotTypeId: number) {
    this.isNew = true;

    // Create new empty annotation
    this.selectedAnnotation = {
      annotation: {
        annotId: null,
        label: '',
        description: null,
        annotTypeId: annotTypeId,
        geometry: null,
        createdBy: null,
        createdDate: null,
        lastUpdatedBy: null,
        lastUpdatedDate: null
      },
      icon: null,
      popup: null
    };

    // Set up and enable drawing mode
    this.mapboxService.enableEditMode(this.mapObject, MapEditMode.ANNOTATIONS, this.drawStyles);
    this.mapboxService.setDrawMode(this.mapObject, MapboxDrawModes.DRAW_POINT);

    this.createEvents();
    this.editMode = true;
  }
}
