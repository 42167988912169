<div #annotationPopup class="popup-container" [ngClass]="{'visible': visible && !otherEdit}" (click)="catchClick($event)">
  <button mat-icon-button class="close-x-button" (click)="close($event)" *ngIf="!editMode">
    <mat-icon fontSet="far" fontIcon="fa-times" class="icon"></mat-icon>
  </button>
  <div class="popup" fxLayout="column">
    <div class="text-container">
      <mat-form-field class="pa-map-annotation">
        <textarea matInput cdkTextareaAutosize  [(ngModel)]="annotation.label"
                  [disabled]="!editMode" class="annotation-label"></textarea>
      </mat-form-field>
    </div>
    <div class="buttons-container" fxLayout="row" fxLayoutAlign="end end">
      <button mat-icon-button (click)="cancel($event)" *ngIf="editMode">
        <mat-icon fontSet="far" fontIcon="fa-ban" class="icon"></mat-icon>
      </button>
      <button mat-icon-button (click)="delete($event)" *ngIf="editMode && !isNew && isUserGapEditor">
        <mat-icon fontSet="far" fontIcon="fa-trash-alt" class="icon"></mat-icon>
      </button>
      <button mat-icon-button (click)="edit($event)" *ngIf="!editMode && isUserGapEditor">
        <mat-icon fontSet="far" fontIcon="fa-pen" class="icon"></mat-icon>
      </button>
      <button mat-icon-button (click)="save($event)" *ngIf="editMode && isUserGapEditor">
        <mat-icon fontSet="far" fontIcon="fa-save" class="icon"></mat-icon>
      </button>
    </div>
  </div>
</div>