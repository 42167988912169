import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {StreetViewService} from '../../services/street-view/street-view.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ee-street-view',
  templateUrl: './street-view.component.html',
  styleUrls: ['./street-view.component.scss']
})
export class StreetViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() streetViewId: string;
  @Output() createCallback = new EventEmitter();
  @Input() showAddressControl = true;

  imageAvailable = false;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private streetViewService: StreetViewService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.configureStreetViewService();
  }

  private configureStreetViewService() {
    const streetViewObject = this.streetViewService.createPanorama(this.streetViewId, null, this.showAddressControl);
    this.createCallback.emit(streetViewObject);

    if (streetViewObject.onStatusChange) {
      streetViewObject.onStatusChange
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(imageAvailable => {
          setTimeout(() => {
            this.imageAvailable = imageAvailable;
            this.streetViewService.triggerResize(streetViewObject);
          });
        });
    }
  }
}
