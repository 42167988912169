import { Component, OnInit } from '@angular/core';
import {first} from 'rxjs/operators';
import {ApiCommsService} from '../../services/api-comms/api-comms.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ee-restricted-page',
  templateUrl: './restricted-page.component.html',
  styleUrls: ['./restricted-page.component.scss']
})
export class RestrictedPageComponent implements OnInit {

  gifUrl = null;
  moduleError = false;

  constructor(
    private apiCommsService: ApiCommsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getGiphy();

    this.moduleError =  this.route.snapshot.data ? this.route.snapshot.data.module : false;
  }

  private getGiphy() {
    this.apiCommsService
      .getGiphyImage('denied')
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.gifUrl = result;
        }
      });
  }

  requestAccess() {
    const link = document.createElement('a');
    link.href = 'mailto:sci@det.nsw.edu.au?subject=Eagle%20Eye%20-%20Request%20Access';
    link.click();
    link.remove();
  }

}
