import { Component } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {AppService} from './core/services/app/app.service';

@Component({
  selector: 'ee-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor(
    private titleService: Title,
    private appService: AppService
  ) {
    if (environment.environment && !environment.production) {
      const title = environment.preview ? 'PREVIEW' : environment.environment;
      this.titleService.setTitle(this.titleService.getTitle() + ' - ' + title);
    }
  }

  onActivate(event: any) {
    this.appService.setRouterOutletActive();
  }
}
