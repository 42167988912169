import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from '../../services/loading/loading.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ee-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  isLoading = false;
  loadingMessage: string = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private loadingService: LoadingService) {
  }

  ngOnInit() {
    this.loadingService.onUpdate()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(isLoading => {
        // Wait for DOM to be ready
        setTimeout(() => {
          this.isLoading = isLoading;
          this.loadingMessage = this.loadingService.getLoadingMessage();
        });
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
