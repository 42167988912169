<div class="toolbar-panel">
  <button mat-icon-button class="panel-close-button" (click)="close()">
    <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
  </button>
  <h3 class="map-ref-layer-title">Map Type</h3>

  <div class="scroll-container" [perfectScrollbar]>
    <div *ngFor="let cat of basemapCategories;">
      <h4 class="map-ref-layer-title">{{cat.category}}</h4>

      <div class="basemap-image-group">
        <div *ngFor="let basemap of cat.basemaps;" class="basemap-image-container" (click)="setBasemap(basemap)"
             [ngClass]="{'active': mapObject.currentBasemap === basemap, 'disabled': mapObject.currentBasemap.isLoading}">
          <img [src]="'assets/images/basemaps/' + basemap.image">
          <div class="basemap-type-name">{{basemap.displayName}}</div>
        </div>
      </div>
    </div>

    <div>
      <h4 class="map-ref-layer-title">Extras</h4>
      <div class="contour-map-toggle" fxLayout="row" fxLayoutAlign="start center">
        <div class="text" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon fontSet="fas" fontIcon="fa-mountains"></mat-icon>
          <span>Enable topographic contour lines</span>
        </div>
        <div fxFlex=""></div>
        <mat-slide-toggle class="toggle-slider" [(ngModel)]="mapObject.contoursEnabled" (change)="toggleMapContours($event)"></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
