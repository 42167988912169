import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import * as mbxClient from '@mapbox/mapbox-sdk';
import * as mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import * as mbxDirections from '@mapbox/mapbox-sdk/services/directions';
import {MapSearchResult} from '../../interfaces/map-search-result';
import {Observable} from 'rxjs';
import {MapSearchType} from '../../enums/map-search-type';

@Injectable({
  providedIn: 'root'
})
export class MapboxSdkService {

  private mapboxClient: any = null;

  private geocodingService: any = null;
  private mapMatchingService: any = null;
  private directionsService: any = null;

  constructor() {
    this.initialiseMapboxClient();
  }

  initialiseMapboxClient() {
    this.mapboxClient = mbxClient({
      accessToken: environment.mapboxAccessToken
    });

    this.geocodingService = mbxGeocoding(this.mapboxClient);
    this.directionsService = mbxDirections(this.mapboxClient);
  }

  // PUT BACK IN map-search.service AND ADD TO PIPE -> MAP
  parseGeocodingResults(searchResults: any): MapSearchResult[] {
    const results: MapSearchResult[] = [];

    for (const item of searchResults) {
      const result: MapSearchResult = {
        id: item.id,
        idColumn: null,
        result: item.place_name,
        tag: MapSearchType.LOCATION,
        geometry: item.geometry,
        center: item.center
      };

      if (item.bbox) {
        result.boundingBox = item.bbox;
      }

      results.push(result);
    }

    return results;
  }

  geocode(query: string): Observable<MapSearchResult[]> {
    return new Observable(observer => {
      if (query) {
        this.geocodingService.forwardGeocode({
          query: query,
          countries: ['au'],
        })
        .send()
        .then(response => {
          if (response && response.hasOwnProperty('body') && response.body.hasOwnProperty('features')) {
            observer.next(this.parseGeocodingResults(response.body.features));
          } else {
            observer.next([]);
          }
          observer.complete();
        },
        error => {
          console.log(error);
          observer.next([]);
          observer.complete();
        });
      }
    });
  }

  getDirections(profile: string, waypoints: {coordinates: number[]}[]): Observable<any> {
    return new Observable(observer => {
      this.directionsService.getDirections({
        profile: profile,
        waypoints: waypoints,
        geometries: 'geojson'
      })
      .send()
      .then(response => {
        if (response && response.body && response.body.code && response.body.code === 'Ok' &&
          response.body.routes && response.body.routes.length > 0) {
          observer.next(response.body);
          observer.complete();
        } else {
          observer.error('Invalid response');
        }
      },
      error => {
        console.log('error', error);
        observer.error(error);
      });
    });
  }
}
