import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HubCardData} from '../../interfaces/hub-card-data';
import {HubSchoolData} from '../../interfaces/hub-school-data';
import {HubViewType} from '../../enums/hub-view-type';
import {HubSchoolTableData} from '../../interfaces/hub-school-table-data';
import {HubSchoolAlertData} from '../../interfaces/hub-school-alert-data';
import {HubSchoolItemData} from '../../interfaces/hub-school-item-data';
import {GeoJSON, Geometry} from 'geojson';
import {HubStudentReachabilityIntervalData} from '../../interfaces/hub-student-reachability-interval-data';
import {HubSchoolListData} from '../../interfaces/hub-school-list-data';

@Injectable({
  providedIn: 'root'
})
export class HubApiCommsService {

  private readonly hubUrl = environment.backendServer.mainPath + environment.backendServer.hub;

  constructor(private http: HttpClient) { }

  // All schools
  getAllSchools(): Observable<HubSchoolItemData[]> {
    const url = `${this.hubUrl}/schools`;
    return this.http.get<HubSchoolItemData[]>(url);
  }

  // NSW Data

  getNSWData(): Observable<HubCardData> {
    const url = `${this.hubUrl}/nsw/summary`;
    return this.http.get<HubCardData>(url);
  }

  // SED Region data

  getSEDRegionDataById(id: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/sed-region/${id}/summary`;
    return this.http.get<HubCardData>(url);
  }

  // FED Region data

  getFEDRegionDataById(id: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/fed-region/${id}/summary`;
    return this.http.get<HubCardData>(url);
  }

  // AMU Region data

  getDETRegionDataById(id: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/det-region/${id}/summary`;
    return this.http.get<HubCardData>(url);
  }

  // Local government area data

  getLGADataById(id: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/lga/${id}/summary`;
    return this.http.get<HubCardData>(url);
  }

  // School clusters

  getSchoolClusterDataById(id: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/school-community-group/${id}/summary`;
    return this.http.get<HubCardData>(url);
  }

  // Principal Networks
  getPrincipalNetworkDataById(id: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/principal-network/${id}/summary`;
    return this.http.get<HubCardData>(url);
  }

  // Principal Networks
  getOperationalDirectorateDataById(id: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/school-performance-directorate/${id}/summary`;
    return this.http.get<HubCardData>(url);
  }

  // DPIE Region
  getDPIERegionDataById(id: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/dpie-region/${id}/summary`;
    return this.http.get<HubCardData>(url);
  }

  // Custom

  getCustomDataByGeometry(geometry: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/custom`;
    return this.http.post<HubCardData>(url, {
      geometry: geometry
    });
  }

  getCustomRefDataByGeometry(layerName: string, tileId: string): Observable<HubCardData> {
    const url = `${this.hubUrl}/custom-ref/${layerName}/${tileId}`;
    return this.http.get<HubCardData>(url);
  }

  getHubDataFromReachabilityGeom(geometry: Geometry): Observable<HubCardData> {
    const url = `${this.hubUrl}/custom-geometry`;
    return this.http.post<HubCardData>(url, {
      geometry: geometry
    });
  }

  getTableData(url: string, isExport: boolean, isPostRequest: boolean, payload?: any) {
    url = `${this.hubUrl}/${url}`;

    const options = {};

    if (isExport) {
      options['responseType'] = 'blob';
    }

    if (isPostRequest) {
      return this.http.post<HubSchoolTableData | Blob>(url, payload, options);
    } else {
      return this.http.get<HubSchoolTableData | Blob>(url, options);
    }
  }

  getHubViewSchoolList(viewType: HubViewType, id: number, payload?: any): Observable<HubSchoolListData[]> {
    let url = this.hubUrl;
    let isPostRequest = false;

    if (viewType === HubViewType.NSW) {
      url += '/nsw';
    } else if (viewType === HubViewType.SED) {
      url += `/sed-region/${id}`;
    } else if (viewType === HubViewType.FED) {
      url += `/fed-region/${id}`;
    } else if (viewType === HubViewType.DET) {
      url += `/det-region/${id}`;
    } else if (viewType === HubViewType.LGA) {
      url += `/lga/${id}`;
    } else if (viewType === HubViewType.PRINCIPAL_NETWORK) {
      url += `/principal-network/${id}`;
    } else if (viewType === HubViewType.OPERATIONAL_DIRECTORATE) {
      url += `/school-performance-directorate/${id}`;
    } else if (viewType === HubViewType.DPIE_REGION) {
      url += `/dpie-region/${id}`;
    } else if (viewType === HubViewType.CUSTOM) {
      url += `/custom`;
      isPostRequest = true;
    } else if (viewType === HubViewType.CUSTOM_REF_LAYER) {
      url += `/custom-ref/${payload.layerName}/${id}`;
      payload = null;
    } else if (viewType === HubViewType.REACHABILITY) {
      url += '/custom-geometry';
      isPostRequest = true;
    }

    url += '/schools';

    if (isPostRequest) {
      return this.http.post<HubSchoolListData[]>(url, payload);
    } else {
      return this.http.get<HubSchoolListData[]>(url);
    }
  }

  // Schools

  getSchoolExport(id: number): Observable<Blob> {
    const url = `${this.hubUrl}/school/${id}/export`;
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  getSchoolDataById(id: number): Observable<HubSchoolData> {
    const url = `${this.hubUrl}/school/${id}`;
    return this.http.get<HubSchoolData>(url);
  }

  getSchoolAlertsById(id: number): Observable<HubSchoolAlertData[]> {
    const url = `${this.hubUrl}/school/${id}/alerts`;
    return this.http.get<HubSchoolAlertData[]>(url);
  }

  getStudentReachability(featureCollection: GeoJSON.FeatureCollection): Observable<HubStudentReachabilityIntervalData[]> {
    const url = `${this.hubUrl}/student-reachability`;
    return this.http.post<any>(url, {
      featureCollection: featureCollection
    });
  }

  getStudentReachabilityExport(featureCollection: GeoJSON.FeatureCollection): Observable<Blob> {
    const url = `${this.hubUrl}/student-reachability/export`;
    return this.http.post(url, {
      featureCollection: featureCollection
    }, {
      responseType: 'blob'
    });
  }

  getOtpResults(date: string, time: string, mode: string[], lat: number, lng: number, cutOffs: string): Observable<any> {
    const url = environment.backendServer.mainPath + environment.backendServer.isochrone +
      `?fromPlace=${lat},${lng}&mode=${mode}&date=${date}&time=${time}&maxWalkDistance=1000&walkSpeed=1.4&arriveBy=1${cutOffs}`;

    return this.http.get<GeoJSON.FeatureCollection>(url, {
      headers:  new HttpHeaders().append('Accept', 'application/json')
    });
  }

  getOtpResultsKmlExport(geoJson: GeoJSON.FeatureCollection): Observable<Blob> {
    const url = environment.backendServer.mainPath + '/otp/kml-export';

    return this.http.post(url, {
      geoJson: geoJson
    }, {responseType: 'blob'});
  }

  downloadLink(type: string, schoolId: number) {
    var downloadType = type;
    var useId = schoolId; 

    const form = document.createElement('form');
    form.style.display = 'none';

    form.setAttribute('action', 'https://amswebapps.det.nsw.edu.au/amsWebApps/aotw/aotw_filedownload.php');
    form.setAttribute('method', 'post');
    form.setAttribute('target', '_blank'); 

    const downloadTypeInput = document.createElement('input');
    downloadTypeInput.setAttribute('type', 'hidden');
    downloadTypeInput.setAttribute('name', 'downloadType');
    downloadTypeInput.setAttribute('value', downloadType);
    form.appendChild(downloadTypeInput);

    const useIdInput = document.createElement('input');
    useIdInput.setAttribute('type', 'hidden');
    useIdInput.setAttribute('name', 'useId');
    useIdInput.setAttribute('value', useId.toString());
    form.appendChild(useIdInput);

    document.body.appendChild(form);
    form.submit();
    // const apiUrl = 'https://amswebapps.det.nsw.edu.au/amsWebApps/aotw/aotw_filedownload.php';
    // this.http.post(apiUrl, postData, { responseType: 'arraybuffer' })
    //   .subscribe(
    //     (response) => {
    //       const blob = new Blob([response], { type: 'application/octet-stream' });
    //       const url = window.URL.createObjectURL(blob);
    //       window.open(url);
    //     },
    //     (error) => {
    //       console.error('Error:', error);
    //     }
    //   );
  }
}
