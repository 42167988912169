import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {TileySearchQuery} from '../../interfaces/tiley-search-query';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Geometry} from 'geojson';
import {Observable} from 'rxjs';
import {GeoreferenceResults} from '../../interfaces/georeference-results';
import {ApiResponse} from '../../../core/interfaces/api-response';
import {ReferenceSearchGeometry} from '../../interfaces/reference-search-geometry';
import {TileyTilesetRequest} from '../../interfaces/tiley-tileset-request';
import {TileyTilesetResponse} from '../../interfaces/tiley-tileset-response';
import {MapSearchResultItem} from '../../interfaces/map-search-result-item';

@Injectable({
  providedIn: 'root'
})
export class TileyService {

  constructor(private http: HttpClient) { }

  getUrl(id: string, dataset: string, columns: string, filter: string): Observable<string> {
    const url = `${environment.tileyServer}/tiles/getTileUrl?apiKey=${environment.tileyApiKey}`;
    const body: TileyTilesetRequest = {
      id: id,
      dataset: dataset,
      columns: columns,
      filter: filter
    };

    return this.http.post<TileyTilesetResponse>(url, body)
      .pipe(
        map(result => {
          return result && result.url ? result.url : null;
        })
      );
  }

  performSearch(keyword: string, limit: number, query: TileySearchQuery[]): Observable<MapSearchResultItem[]> {
    const url = `${environment.tileyServer}/search?apiKey=${environment.tileyApiKey}`;
    return this.http.post<ApiResponse<MapSearchResultItem[]>>(url, {
      keyword: keyword,
      limit: limit,
      q: JSON.stringify(query)
    })
      .pipe(
        map(result => {
          return result && result.success && result.data ? result.data : null;
        })
      );
  }

  getBoundingBox(dataset: string, idColumn: string, id: string): Observable<ReferenceSearchGeometry> {
    const url = `${environment.tileyServer}/bbox/${dataset}/${id}?apiKey=${environment.tileyApiKey}&idColumn=${idColumn}`;

    return this.http.get<ApiResponse<ReferenceSearchGeometry>>(url)
      .pipe(
        map(result => {
          return result && result.success && result.data ? result.data : null;
        })
      );
  }

  doGeoreference(geometry: Geometry, doLots?: boolean): Observable<GeoreferenceResults> {
    const geom = JSON.stringify(geometry);
    const url = `${environment.tileyServer}/query/?apiKey=${environment.tileyApiKey}`;

    return this.http.post<any>(url, {
      q: `SELECT georef_master('${geom}', ${doLots ? 'TRUE' : 'FALSE'}) AS result`
    })
      .pipe(
        map(result => {
          return result && result.success && result.data && result.data[0] && result.data[0].result ?
            result.data[0].result : null;
        })
      );
  }

  query(query: string): Observable<any> {
    const url = `${environment.tileyServer}/query/?apiKey=${environment.tileyApiKey}`;

    return this.http.post<any>(url, {
      q: query
    });
  }

  clearCache(datasetName: string): Observable<any> {
    const url = `${environment.tileyServer}/flushCache?dataset=${datasetName}&apiKey=${environment.tileyApiKey}`;
    return this.http.get(url);
  }

}
