import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const now = moment();
    const createdDate = moment(value);
    const hours = now.diff(createdDate, 'hours');
    const minutes = now.diff(createdDate, 'minutes');

    if (hours < 24) {
      if (hours > 1) {
        return (hours + ' hours ago');
      }
      if (hours === 1) {
        return (hours + ' hour ago');
      }
      if (hours < 1) {
        if (minutes < 1) {
          return 'Just now';
        } else {
          return (minutes + ' minutes ago');
        }
      }
    } else {
      return moment(value).format('D-MMM');
    }
  }

}
