import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {TooltipDirection} from '../../enums/tooltip-direction';

@Component({
  selector: 'ee-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent implements OnInit {
  top = '0';
  left = '0';
  visible = false;
  reversed = false;
  fixed = false;

  @Input() body = '';
  @Input() tooltipClass = null;
  @Input() tooltipDirection = TooltipDirection.LEFT;
  @ViewChild('tooltipContainer', { static: true }) el: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  setPosition(top: number, left: number, direction: TooltipDirection, elementHeight: number) {
    const margin = 4;
    const width = this.el.nativeElement.offsetWidth;
    const height = this.el.nativeElement.clientHeight;
    const docHeight = document.documentElement.scrollHeight;

    if (top + height > docHeight) {
      this.top = (top - height + margin + elementHeight) + 'px';
      this.reversed = true;
    } else {
      this.top = (top - margin) + 'px';
      this.reversed = false;
    }

    if (direction === TooltipDirection.LEFT) {
      this.left = (left - width) + 'px';
    } else if (direction === TooltipDirection.RIGHT) {
      this.left = left + 'px';
    }

  }

  show() {
    this.visible = true;
  }

  hide() {
    if (!this.fixed) {
      this.visible = false;
    }
  }

  getVisibility(): boolean {
    return this.visible;
  }

  toggleFixed(): void {
    this.fixed = !this.fixed;
    this.visible = this.fixed;
  }
}
