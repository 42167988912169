import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MapObject} from '../../interfaces/map-object';
import {MapBasemapCategory} from '../../interfaces/map-basemap-category';
import {MapBasemap} from '../../interfaces/map-basemap';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MapBasemapResolverService} from '../../../core/resolvers/map-basemap-resolver/map-basemap-resolver.service';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {UserEventCategories} from '../../enums/user-event-categories';
import {UserEvents} from '../../enums/user-events';
import {UserEventService} from '../../../core/services/user-event/user-event.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MapContourToolService} from '../../services/map-contour-tool/map-contour-tool.service';

@Component({
  selector: 'ee-map-basemap-panel',
  templateUrl: './map-basemap-panel.component.html',
  styleUrls: ['./map-basemap-panel.component.scss']
})
export class MapBasemapPanelComponent implements OnInit, OnDestroy {
  @Input() mapObject: MapObject = null;
  @Output() closeButtonClick = new EventEmitter();

  basemapCategories: MapBasemapCategory[] = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private mapBasemapDataService: MapBasemapResolverService,
    private mapboxService: MapboxService,
    private userEventService: UserEventService,
    private mapContourToolService: MapContourToolService
  ) {}

  ngOnInit() {
    this.onBasemapDataReady();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private onBasemapDataReady() {
    this.mapBasemapDataService.onBasemapDataReady()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result) {
          this.basemapCategories = result;
        }
      });
  }

  close() {
    this.closeButtonClick.emit(true);
  }

  setBasemap(basemap: MapBasemap) {
    if (!this.mapObject.currentBasemap.isLoading) {
      this.mapboxService.setBasemap(this.mapObject, basemap);

      this.userEventService.logUserEvent({
        category: UserEventCategories.BASEMAP,
        event: UserEvents.BASEMAP_CHANGED,
        metadata: {
          basemap: basemap.basemapName
        }
      });
    }
  }

  toggleMapContours(event: MatSlideToggleChange) {
    if (event.checked) {
      this.mapContourToolService.enable(this.mapObject);
    } else {
      this.mapContourToolService.disable(this.mapObject);
    }
  }
}
