import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ee-app-snow',
  templateUrl: './app-snow.component.html',
  styleUrls: ['./app-snow.component.scss']
})
export class AppSnowComponent implements OnInit {

  constructor() { }

  flakes: Array<any> = [];
  

  ngOnInit() {
    this.createSnowflakes();
  }

  startSnowfall() {
    this.createSnowflakes();
  }

  private createSnowflakes() {
    const numFlakes = 80;

    // for (let i = 0; i < numFlakes; i++) {
    //   this.flakes.push({
    //     left: Math.random() * 100,
    //     animationDuration: Math.random() * 15 + 10,
    //     animationDelay: Math.random(),
    //   });
    // }

    for (let i = 0; i < numFlakes; i++) {
      const delayMultiplier = 2; // Adjust this multiplier to control the delay
      const delay = Math.random() * delayMultiplier; // Random delay between 0 and 2 seconds
      const duration = Math.random() * 15 + 10;

      this.flakes.push({
        left: Math.random() * 100,
        animationDuration: duration,
        animationDelay: delay,
      });
    }
  }

}
