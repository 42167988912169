import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MakeUpMirrorComponent} from '../../components/make-up-mirror/make-up-mirror.component';

@Injectable({
  providedIn: 'root'
})
export class MakeUpMirrorService {

  constructor(public dialog: MatDialog) { }

  showDialog() {
    setTimeout(() => {
      this.dialog.open(MakeUpMirrorComponent, {
        width: '688px',
      });
    });
  }
}
