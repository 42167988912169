import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MapboxService} from '../../../shared/services/mapbox/mapbox.service';
import {takeUntil} from 'rxjs/operators';
import {MapObject} from '../../../shared/interfaces/map-object';

@Component({
  selector: 'ee-annotation-marker',
  templateUrl: './annotation-marker.component.html',
  styleUrls: ['./annotation-marker.component.scss']
})
export class AnnotationMarkerComponent implements OnInit, OnDestroy {
  mapObject: MapObject = null;
  visible = true;
  icon: string = null;
  annotId: number = null;
  otherEdit = false;

  private clickEventSubject: Subject<boolean> = new Subject<boolean>();
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  @ViewChild('annotationMarker', { static: true }) el: ElementRef;

  constructor(private mapboxService: MapboxService) {
  }

  set (mapObject: MapObject, annotId: number, icon: string) {
    this.mapObject = mapObject;
    this.annotId = annotId;
    this.icon = icon;
    this.onMapEdit();
  }

  ngOnInit() {
  }

  onMapEdit() {
    this.mapboxService.onMapEdit(this.mapObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(mode => {
        this.otherEdit = (mode !== null);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  clickEvent(e) {
    if (!this.otherEdit) {
      e.preventDefault();
      this.clickEventSubject.next(true);
    }
  }

  getClickEventSubject(): Subject<boolean> {
    return this.clickEventSubject;
  }

  hide() {
    this.visible = false;
  }

  show() {
    this.visible = true;
  }
}
