import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MapObject} from '../../interfaces/map-object';
import {Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';
import {MapReachabilityService} from '../../services/map-reachability/map-reachability.service';
import {OtpTripModes} from '../../enums/otp-trip-modes';
// import {OtpTimingOptions} from '../../enums/otp-timing-options';
import {OtpTripPeriodOptions} from '../../enums/otp-trip-period-options';
import {AppRoutes} from '../../../core/enums/app-routes';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {HubStudentReachabilityIntervalData} from '../../../hub/interfaces/hub-student-reachability-interval-data';
import {InfoDialogService} from '../../../core/services/info-dialog/info-dialog.service';
import {HubRoutes} from '../../../hub/enums/hub-routes';
import {DownloadDisclaimerService} from '../../../core/services/download-disclaimer/download-disclaimer.service';
import {GeoJSON} from 'geojson';
import * as moment from 'moment';
import {saveAs} from 'file-saver';

@Component({
  selector: 'ee-map-reachability-panel',
  templateUrl: './map-reachability-panel.component.html',
  styleUrls: ['./map-reachability-panel.component.scss']
})
export class MapReachabilityPanelComponent implements OnInit, OnDestroy, OnChanges {

  private chartTimeout = null;

  readonly reachabilityTooltipText =
    `Visualises range of travel based on origin and mode.<br><br> \
    The maximum walking distance is 1 km for multi modal travel.<br><br> \
    For modal comparisons, individual analysis must be undertaken. For example, to compare the reach between car, bus \
    and train in an area, select each mode in isolation and conduct separate analysis.<br><br> \
    <span class="small-text"><span class="bold">Source:</span> Public transport data sourced from Transport for NSW. \
    Driving, walking and cycling data sourced from Open Street Maps.</span>
  `;

  readonly analysisTooltipText =
    `The Reachability Chart summarises the number of students that live within the journey time via the specified mode \
    of transport.<br><br> \
    The chart depicts the number of students which are reachable within the journey time via the specified mode of \
    transport. For example, 800 for Walking within 15 minutes in the chart articulates that 800 enrolled students \
    are able to reach the location selected within 15 minutes by walking.<br><br> \
    <span class="small-text"><span class="bold">Source:</span> Student locations sourced from ERN, Department of \
    Education.</span>`;

  @Input() mapObject: MapObject;

  visible = false;
  otpOptionsHasChanged = false;
  isFirst = true;
  hasResult = false;
  showNoResultMessage = false;
  analysisLoaded = false;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  analysisExpanded = false;

  OtpTripPeriodEnum = OtpTripPeriodOptions;

  reachabilityAnalysisResults: HubStudentReachabilityIntervalData[] = null;
  reachabilityResults: GeoJSON.FeatureCollection = null;

  reachabilityModeOptions = [{
    value: OtpTripModes.TRAIN,
    image: 'assets/images/transport-icons/train-mode.svg',
    label: 'Train'
  }, {
    value: OtpTripModes.BUS,
    image: 'assets/images/transport-icons/bus-mode.svg',
    label: 'Bus'
  }, {
    value: OtpTripModes.FERRY,
    image: 'assets/images/transport-icons/ferry-mode.svg',
    label: 'Ferry'
  }, {
    value: OtpTripModes.LIGHT_RAIL,
    image: 'assets/images/transport-icons/light-rail-mode.svg',
    label: 'Light Rail'
  }, {
    value: OtpTripModes.METRO,
    image: 'assets/images/transport-icons/metro-mode.svg',
    label: 'Metro'
  }, {
    value: OtpTripModes.CAR,
    icon: 'fa-car',
    label: 'Car'
  }, {
    value: OtpTripModes.BICYCLE,
    icon: 'fa-bicycle',
    label: 'Bicycle'
  }, {
    value: OtpTripModes.WALK,
    icon: 'fa-walking',
    label: 'Walk',
    disabled: true
  }];

  reachabilityGrades = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  selectedReachabilityGrades = this.reachabilityGrades.map(r => r);

  constructor(
    private mapReachabilityService: MapReachabilityService,
    private infoDialogService: InfoDialogService,
    private router: Router,
    private downloadDisclaimerService: DownloadDisclaimerService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.chartTimeout) {
      clearTimeout(this.chartTimeout);
    }

    this.close();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapObject && this.mapObject) {
      this.watchPanelVisibility();
      this.watchResultUpdate();
    }
  }

  private watchPanelVisibility() {
    this.mapObject.mapReachabilityObject.onVisibilityChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        this.visible = visible;
        if (this.visible) {
          this.showNoResultMessage = false;
        }
      });
  }

  private watchResultUpdate() {
    this.mapObject.mapReachabilityObject.onResultsUpdate
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        this.reachabilityResults = value;

        if (value && value.features && value.features.length > 0) {
          this.hasResult = value.features.some(f => {
            return !!f.geometry;
          });
        } else {
          this.hasResult = false;
        }

        if (!this.isFirst) {
          this.showNoResultMessage = !this.hasResult;
        }
        this.isFirst = false;

        this.analysisLoaded = false;
        if (this.hasResult && this.analysisExpanded) {
          this.updateReachabilityAnalysisResults();
        }
      });
  }

  updateAnalysis() {
    if (this.hasResult && !this.analysisLoaded) {
      this.updateReachabilityAnalysisResults();
    }
  }

  updateOtpOptions() {
    this.otpOptionsHasChanged = true;

    const bicycleIndex = this.mapObject.mapReachabilityObject.otpOptions.mode.indexOf(OtpTripModes.BICYCLE);
    const walkIndex = this.mapObject.mapReachabilityObject.otpOptions.mode.indexOf(OtpTripModes.WALK);

    // If mode includes bicycle and walk - remove walk
    if (bicycleIndex > -1 && walkIndex > -1) {
      setTimeout(() => {
        const modes = this.mapObject.mapReachabilityObject.otpOptions.mode.map(m => m);
        modes.splice(walkIndex, 1);
        this.mapObject.mapReachabilityObject.otpOptions.mode = modes;
      });
    } else if (bicycleIndex === -1 && walkIndex === -1) {
      setTimeout(() => {
        const modes = this.mapObject.mapReachabilityObject.otpOptions.mode.map(m => m);
        modes.push(OtpTripModes.WALK);
        this.mapObject.mapReachabilityObject.otpOptions.mode = modes;
      });
    }
  }

  setTime(time: number) {
    this.mapObject.mapReachabilityObject.otpOptions.time = time;
    this.updateOtpOptions();
  }

  analyseInHub() {
    if (this.mapObject.mapReachabilityObject.currentLocation && this.mapObject.mapReachabilityObject.otpOptions) {
      const queryParams: any = _.cloneDeep(this.mapObject.mapReachabilityObject.otpOptions);
      queryParams.location = this.mapObject.mapReachabilityObject.currentLocation;

      this.router.navigate([AppRoutes.HUB, HubRoutes.REACHABILITY], {
        queryParams: queryParams
      });
    }
  }

  updateOtpResults() {
    this.otpOptionsHasChanged = false;
    this.mapReachabilityService.updateResults(this.mapObject);
  }

  updateReachabilityAnalysisResults() {
    this.analysisLoaded = true;
    this.mapReachabilityService.calculateStudentReachability(this.mapObject)
      .pipe(first())
      .subscribe(response => {
        if (response) {
          this.reachabilityAnalysisResults = response;
          this.updateReachabilityAnalysisChart();
        }
      });
  }

  updateReachabilityAnalysisChart() {
    this.chartTimeout = setTimeout(() => {
      this.mapReachabilityService.updateReachabilityAnalysisChart('reachability-analysis-chart',
        this.selectedReachabilityGrades, this.reachabilityAnalysisResults);
    });
  }

  exportReachabilityAnalysisChart() {
    const modes = this.mapObject.mapReachabilityObject.otpOptions.mode.join(', ');
    this.mapReachabilityService.exportReachabilityAnalysisChart(modes);
  }

  exportReachabilityAnalysisData() {
    this.downloadDisclaimerService.showDownloadDisclaimer(() => {
      const modes = this.mapObject.mapReachabilityObject.otpOptions.mode.join(', ');
      this.mapReachabilityService.exportReachabilityAnalysisData(this.mapObject, modes);
    });
  }

  showReachabilityAnalysisExplanation() {
    const message = 'Click here to do my job? <br><br>But you don\'t know how to do your job.';

    this.infoDialogService.showDialog(480, 'question-circle', 'Explanation', message);
  }

  downloadGeoJson() {
    if (this.reachabilityResults) {
      this.downloadDisclaimerService.showDownloadDisclaimer(() => {
        const fileName = `EE-export - HUB - Reachability Geometry` +
          ` - ${moment().format('YYYYMMDD')}.geojson`;

        const blob = new Blob([JSON.stringify(this.reachabilityResults)], {
          type: 'text/plain;charset=utf-8'
        });

        saveAs(blob, fileName);
      });
    }
  }

  downloadKml() {
    if (this.reachabilityResults) {
      this.downloadDisclaimerService.showDownloadDisclaimer(() => {
        this.mapReachabilityService.getOtpResultsKmlExport(this.reachabilityResults);
      });
    }
  }

  close() {
    this.mapReachabilityService.disable(this.mapObject);
  }

}
