import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable, Subject, Subscription, zip} from 'rxjs';
import {MapSearchResult} from '../../interfaces/map-search-result';
import {MapboxService} from '../mapbox/mapbox.service';
import {MapObject} from '../../interfaces/map-object';
import {first, take} from 'rxjs/operators';
import {ApiCommsService} from '../../../core/services/api-comms/api-comms.service';
import {MapImage} from '../../interfaces/map-image';
import {MapboxSdkService} from '../mapbox-sdk/mapbox-sdk.service';
import {ReferenceSearchGeometry} from '../../interfaces/reference-search-geometry';
import {MapSearchType} from '../../enums/map-search-type';
import {MapReferenceLayersService} from '../map-reference-layers/map-reference-layers.service';
import {MapReferenceLayerDefs} from '../../enums/map-reference-layer-defs';
import {TileySearchQuery} from '../../interfaces/tiley-search-query';
import {TileyService} from '../tiley/tiley.service';
import {MapLayerActionType} from '../../enums/map-layer-action-type';
import {MapSearchResultItem} from '../../interfaces/map-search-result-item';
import Layer = mapboxgl.Layer;

@Injectable({
  providedIn: 'root'
})
export class MapSearchService {

  private currentSearchValue: string = null;
  private searchSubscription: Subscription = null;
  private onMapResultsLoadingSubject: Subject<boolean> = new Subject<boolean>();
  private onMapResultsSubject: Subject<any> = new Subject<any>();
  private onAdvancedSearchVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private advancedSearchVisible = false;

  private readonly mapSearchMarkerSourceId = 'mapSearchMarkerResults';
  private readonly mapSearchMarkerLayers: Layer[] = [{
    id: 'map-search-marker-point',
    type: 'symbol',
    layout: {
      'icon-image': 'map-search-marker',
      'icon-size': 1
    }
  }];
  private readonly mapSearchMarkerImages: MapImage[] = [{
    id: 'map-search-marker',
    url: environment.assetsFolder + 'map-search-marker.png'
  }];

  private readonly searchItems: TileySearchQuery[] = [{
    id: 'governmentSchools',
    dataset: 'schools',
    layer: MapReferenceLayerDefs.GOV_SCHOOLS,
    idColumn: 'school_id',
    searchColumn: ['school_name', 'school_id'],
    resultColumn: 'school_name',
    tag: MapSearchType.GOV_SCHOOL,
    order: 1
  }, {
    id: 'newSchools',
    dataset: 'school_projects',
    layer: MapReferenceLayerDefs.NEW_SCHOOLS,
    idColumn: 'school_id',
    searchColumn: ['project_name', 'school_id'],
    resultColumn: 'project_name',
    filter: 'type = \'New\'',
    tag: MapSearchType.NEW_SCHOOL,
    order: 2
  }, {
    id: 'upgradeSchools',
    dataset: 'school_projects',
    layer: MapReferenceLayerDefs.SCHOOL_UPGRADES,
    idColumn: 'school_id',
    searchColumn: ['project_name', 'school_id'],
    resultColumn: 'project_name',
    filter: 'type = \'Upgrade\'',
    tag: MapSearchType.UPGRADE_SCHOOL,
    order: 3
  }, {
    id: 'nonGovernmentSchools',
    dataset: 'ams_non_gov_schools',
    layer: MapReferenceLayerDefs.NON_GOV_SCHOOLS,
    idColumn: 'school_number',
    searchColumn: ['name'],
    resultColumn: 'name',
    tag: MapSearchType.NON_GOV_SCHOOL,
    order: 4
  }, {
    id: 'clusters',
    dataset: 'sde_school_cluster',
    layer: MapReferenceLayerDefs.PRIMARY_SCHOOL_CLUSTERS,
    idColumn: 'cluster_code',
    searchColumn: ['cluster_name', 'cluster_code'],
    resultColumn: 'cluster_name',
    'meta': 'cluster_type',
    tag: MapSearchType.CLUSTER,
    order: 5
  }, {
    id: 'schoolCatchments',
    dataset: 'sde_catchment',
    layer: MapReferenceLayerDefs.SCHOOL_CATCHMENTS,
    idColumn: 'school_id',
    searchColumn: ['school_name', 'school_id'],
    resultColumn: 'school_name',
    tag: MapSearchType.CATCHMENT,
    order: 6
  }, {
    id: 'principalNetworks',
    dataset: 'doe_principal_networks',
    layer: MapReferenceLayerDefs.PRINCIPAL_NETWORKS,
    idColumn: 'principal_network_code',
    searchColumn: ['principal_network'],
    resultColumn: 'principal_network',
    tag: MapSearchType.PRINCIPAL_NETWORKS,
    order: 7
  }, {
    id: 'operationalDirectorates',
    dataset: 'doe_operational_directorates',
    layer: MapReferenceLayerDefs.OPERATIONAL_DIRECTORATES,
    idColumn: 'operational_directorate_code',
    searchColumn: ['operational_directorate'],
    resultColumn: 'operational_directorate',
    tag: MapSearchType.OPERATIONAL_DIRECTORATES,
    order: 8
  }, {
    id: 'dpieRegion',
    dataset: 'dpie_regions',
    layer: MapReferenceLayerDefs.DPIE_REGIONS,
    idColumn: 'cadid',
    searchColumn: ['regdis'],
    resultColumn: 'regdis',
    tag: MapSearchType.DPIE_REGIONS,
    order: 9
  }, {
    id: 'lgas',
    dataset: 'sde_lga',
    layer: MapReferenceLayerDefs.LGAS,
    idColumn: 'cadid',
    searchColumn: ['label'],
    resultColumn: 'label',
    tag: MapSearchType.LGA,
    order: 10
  }, {
    id: 'stateElectorate',
    dataset: 'sde_state_elect',
    layer: MapReferenceLayerDefs.SED_REGIONS,
    idColumn: 'cadid',
    searchColumn: ['label'],
    resultColumn: 'label',
    tag: MapSearchType.STATE_ELECTORATE_DISTRICTS,
    order: 11
  }, {
    id: 'detRegions',
    dataset: 'sde_det_region',
    layer: MapReferenceLayerDefs.DET_REGIONS,
    idColumn: 'region_code',
    searchColumn: ['region_name'],
    resultColumn: 'region_name',
    tag: MapSearchType.DET_REGIONS,
    order: 12
  }, {
    id: 'plannedAreas',
    dataset: 'udp_gcc_precincts',
    layer: MapReferenceLayerDefs.PLANNED_AREAS,
    idColumn: 'record_id',
    searchColumn: ['record_id', 'name'],
    resultColumn: 'name',
    tag: MapSearchType.PLANNED_AREA_PRECINT,
    order: 13
  }, {
    id: 'sa1Boundaries',
    dataset: 'abs_sa1_2021_nsw',
    layer: MapReferenceLayerDefs.SA1_BOUNDARIES,
    idColumn: 'sa1_code_2021',
    searchColumn: ['sa1_code_2021'],
    resultColumn: 'sa1_code_2021',
    tag: MapSearchType.SA1_BOUNDARIES,
    order: 14,
    fuzzy: false
  }, {
    id: 'sa2Boundaries',
    dataset: 'abs_sa2_2021_nsw',
    layer: MapReferenceLayerDefs.SA2_BOUNDARIES,
    idColumn: 'sa2_code_2021',
    searchColumn: ['sa2_code_2021', 'sa2_name_2021'],
    resultColumn: 'sa2_name_2021',
    tag: MapSearchType.SA2_BOUNDARIES,
    order: 15,
    fuzzy: false
  }, {
    id: 'sa3Boundaries',
    dataset: 'abs_sa3_2021_nsw',
    layer: MapReferenceLayerDefs.SA3_BOUNDARIES,
    idColumn: 'sa3_code_2021',
    searchColumn: ['sa3_code_2021', 'sa3_name_2021'],
    resultColumn: 'sa3_name_2021',
    tag: MapSearchType.SA3_BOUNDARIES,
    order: 16,
    fuzzy: false
  }, {
    id: 'sa4Boundaries',
    dataset: 'abs_sa4_2021_nsw',
    layer: MapReferenceLayerDefs.SA4_BOUNDARIES,
    idColumn: 'sa4_code_2021',
    searchColumn: ['sa4_code_2021', 'sa4_name_2021'],
    resultColumn: 'sa4_name_2021',
    tag: MapSearchType.SA4_BOUNDARIES,
    order: 17,
    fuzzy: false
  }, {
    id: 'meshBlocks',
    dataset: 'abs_mesh_block_2021',
    layer: MapReferenceLayerDefs.MESH_BLOCKS,
    idColumn: 'mb_code_2021',
    searchColumn: ['mb_code_2021'],
    resultColumn: 'mb_code_2021',
    tag: MapSearchType.MESH_BLOCKS,
    order: 18,
    fuzzy: false
  }, {
    id: 'cadastreLots',
    dataset: 'dpie_cadastre',
    layer: MapReferenceLayerDefs.CADASTRE_LOTS,
    idColumn: 'cadid',
    searchColumn: ['lsp_name'],
    resultColumn: 'lsp_name',
    tag: MapSearchType.CADASTRE_LOTS,
    order: 19,
    fuzzy: false
  }];

  private selectedSearchItems: TileySearchQuery[] = this.searchItems.filter(i => i.order <= 13);

  constructor(
    private mapboxService: MapboxService,
    private mapboxSdkService: MapboxSdkService,
    private apiCommsService: ApiCommsService,
    private tileyService: TileyService,
    private mapReferenceLayerService: MapReferenceLayersService
  ) { }

  mapboxSearch(searchValue: string): Observable<MapSearchResult[]> {
    return this.mapboxSdkService.geocode(searchValue);
  }

  referenceSearch(searchValue: string): Observable<any> {
    return this.tileyService.performSearch(searchValue, 10, this.selectedSearchItems);
  }

  getCoordinateResult(searchValue: string): MapSearchResult {
    if (searchValue) {
      const result = /(^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?))\s*[,\s]\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/
        .exec(searchValue);

      if (!result) {
        return null;
      } else {
        return {
          id: null,
          idColumn: null,
          center: [Number(result[2]), Number(result[1])],
          geometry: {
            type: 'Point',
            coordinates: [Number(result[2]), Number(result[1])]
          },
          tag: MapSearchType.COORDINATES,
          result: `${result[1]}, ${result[2]}`
        };
      }
    }
  }

  search(searchValue: string): void {
    this.currentSearchValue = searchValue;

    if (searchValue) {
      // Cancel existing subscription if exists
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
        this.searchSubscription = null;
      }

      this.onMapResultsLoadingSubject.next(true);

      const coordinateResult = this.getCoordinateResult(searchValue);

      this.searchSubscription = zip(
        this.mapboxSearch(searchValue),
        this.referenceSearch(searchValue)
      )
      .pipe(take(1))
      .subscribe(([mapboxResults, tileyResults]) => {
        // Combine reference and location results together
        if (mapboxResults || tileyResults || coordinateResult) {
          tileyResults = tileyResults ? tileyResults : [];

          if (mapboxResults) {
            tileyResults.push({
              tag: MapSearchType.LOCATION,
              order: tileyResults.length + 1,
              results: mapboxResults
            });
          }

          if (coordinateResult) {
            tileyResults.push({
              tag: MapSearchType.COORDINATES,
              order: tileyResults.length + 1,
              results: [coordinateResult]
            });
          }

          this.onMapResultsSubject.next(tileyResults);
        } else {
          this.onMapResultsSubject.next(null);
        }
      });
    }  else {
      this.onMapResultsSubject.next(null);
    }
  }

  clearSearch(): void {
    this.currentSearchValue = null;
  }

  onSearchLoading(): Observable<boolean> {
    return this.onMapResultsLoadingSubject;
  }

  onSearchComplete(): Observable<MapSearchResultItem[]> {
    return this.onMapResultsSubject;
  }

  selectSearchResult(mapObject: MapObject, result: MapSearchResult) {
    this.clearSearchResultLayer(mapObject);

    mapObject.currentSearchResult = result;
    if (result.tag === MapSearchType.LOCATION || result.tag === MapSearchType.COORDINATES) {
      // If search result is a Mapbox location
      this.showLocationResult(mapObject, result);
    } else {
      // If search result is from the reference datasets
      this.showReferenceResult(mapObject, result);
    }
  }

  private showLocationResult(mapObject: MapObject, result: MapSearchResult) {
    if (result.boundingBox) {
      this.mapboxService.fitToBounds(mapObject, result.boundingBox, result.center);
    } else {
      this.mapboxService.flyTo(mapObject, result.center, 16);
    }

    const mapSearchData = [{
      geometry: result.geometry
    }];
    this.mapboxService.loadImages(mapObject, this.mapSearchMarkerImages, () => {
      this.mapboxService.updateGeoJsonDataLayer(mapObject, this.mapSearchMarkerSourceId, mapSearchData, null, this.mapSearchMarkerLayers);
    });
  }

  private showReferenceResult(mapObject: MapObject, result: MapSearchResult) {
    // Only show the layer (if its off) or change the filter once the map navigation has finished
    if (mapObject && mapObject.map) {
      mapObject.map.once('idle', () => {
        const layer = this.getLayerFromSearchResult(mapObject, result);

        // If a layer was identified - apply filter and add to map
        if (layer) {
          this.mapReferenceLayerService.applySearchFilter(layer,
            this.mapReferenceLayerService.getIdBasedFilter(layer, String(result.id)));

          this.mapReferenceLayerService.addLayer(mapObject, layer, MapLayerActionType.SEARCH);
        }
      });
    }

    // Get geometry for selected search results
    this.tileyService.getBoundingBox(result.dataset, result.idColumn, result.id as string)
      .pipe(first())
      .subscribe((response: ReferenceSearchGeometry) => {
        if (response && response.boundingBox) {
          if (response.boundingBox.type === 'Point') {
            const coords = (response.boundingBox as GeoJSON.Point).coordinates;
            this.mapboxService.flyTo(mapObject, [coords[0], coords[1]], 16);
          } else {
            this.mapboxService.fitToViewFeatures(mapObject.map, [{geometry: response.boundingBox}]); // need to fix this
          }
        }
      });
  }

  clearSearchResultLayer(mapObject: MapObject) {
    if (mapObject.currentSearchResult) {
      if (mapObject.currentSearchResult.tag === MapSearchType.LOCATION) {
        this.mapboxService.updateGeoJsonDataLayer(mapObject, this.mapSearchMarkerSourceId, [], null, this.mapSearchMarkerLayers);
      } else {
        // something in here is causing issues
        const layer = this.getLayerFromSearchResult(mapObject, mapObject.currentSearchResult);

        if (layer) {
          this.mapReferenceLayerService.clearHighlightFeature(mapObject, layer, true);
          this.mapReferenceLayerService.removeSearchFilter(layer);
          this.mapReferenceLayerService.removeLayer(mapObject, layer, MapLayerActionType.SEARCH);
        }
      }
      mapObject.currentSearchResult = null;
    }
  }

  forceClearSearch(mapObject: MapObject) {
    if (mapObject.onForceSearchClear) {
      mapObject.onForceSearchClear.next(true);
    }
  }

  private getLayerFromSearchResult(mapObject: MapObject, result: MapSearchResult) {
    // Determine which reference layer to use based on result type
    let layerName = null;

    if (result && result.tag && result.tag === MapSearchType.CLUSTER && result.meta) {
      switch (result.meta) {
        case 'Primary':
          layerName = MapReferenceLayerDefs.PRIMARY_SCHOOL_CLUSTERS;
          break;
        case 'Secondary':
          layerName = MapReferenceLayerDefs.SECONDARY_SCHOOL_CLUSTERS;
          break;
        case 'SSP':
          layerName = MapReferenceLayerDefs.SSP_SCHOOL_CLUSTERS;
          break;
      }
    } else {
      layerName = result.layer;
    }

    return this.mapReferenceLayerService.getLayerByLayerName(mapObject, layerName);
  }

  getSearchItems(): TileySearchQuery[] {
    return this.searchItems;
  }

  getSelectedSearchItems(): TileySearchQuery[] {
    return this.selectedSearchItems;
  }

  setSelectedSearchItems(items: TileySearchQuery[]) {
    this.selectedSearchItems = items;

    if (this.currentSearchValue) {
      this.search(this.currentSearchValue);
    }
  }

  openAdvancedSearch(): void {
    this.setAdvancedSearchVisibility(true);
  }

  closeAdvancedSearch(): void {
    this.setAdvancedSearchVisibility(false);
  }

  getAdvancedSearchVisibility(): boolean {
    return this.advancedSearchVisible;
  }

  private setAdvancedSearchVisibility(visible: boolean): void {
    this.advancedSearchVisible = visible;
    this.onAdvancedSearchVisibleSubject.next(visible);
  }

  onAdvancedSearchVisibilityChange(): Observable<boolean> {
    return this.onAdvancedSearchVisibleSubject;
  }
}
