export enum TransportTripModeColor {
  TRAIN = '#F6891F',
  LIGHT_RAIL = '#EF373E',
  METRO = '#05B3AD',
  BUS = '#00B5EF',
  COACH = '#742283',
  FERRY = '#58B947',
  SCHOOL_BUS = '#8CD4F5',
  WALKING_A = '#D9DADB',
  WALKING_B = '#D9DADB',
  TAXI = '#000000'
}
