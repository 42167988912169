export enum UserNotificationCategories {
  MINOR_RELEASE = 'MINOR_RELEASE',
  MAJOR_RELEASE = 'MAJOR_RELEASE',
  GENERAL_ANNOUNCEMENT = 'GENERAL_ANNOUNCEMENT',
  VISION = 'VISION',
  PLANNED_EVENT = 'PLANNED_EVENT',
  MAINTENANCE = 'MAINTENANCE',
  INFORMATION = 'INFORMATION',
  WARNING = 'WARNING',
  HOLIDAYS = 'HOLIDAYS',
  MEOW = 'MEOW'
}
