import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {MaintenanceModeService} from '../../services/maintenance-mode/maintenance-mode.service';
import {map} from 'rxjs/operators';
import {AppRoutes} from '../../enums/app-routes';
import {UserRoles} from '../../enums/user-roles';
import {AuthService} from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {

  constructor(
    private maintenanceModeService: MaintenanceModeService,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.maintenanceModeService.checkMaintenanceMode()
      .pipe(
        map(response => {
          if (response && !this.authService.checkUserRole(UserRoles.ADMIN)) {
            this.router.navigate([AppRoutes.MAINTENANCE]);
            return false;
          }
          return true;
        })
      );
  }
}
