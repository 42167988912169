export enum MapPsuedoLayers {
  PSUEDO_LAYER_TOP = 'psuedo-layer-top',
  PSUEDO_LAYER_SCHOOL_ENROLMENT = 'psuedo-layer-school-enrolment',
  PSUEDO_LAYER_SCHOOL_CATCHMENT = 'psuedo-layer-school-catchment',
  PSUEDO_LAYER_SCHOOL_BOUNDARY = 'psuedo-layer-school-boundary',
  PSUEDO_LAYER_REF = 'psuedo-layer-ref',
  PSUEDO_LAYER_REACHABILITY_RESULTS = 'psuedo-layer-reachability-results',
  PSUEDO_LAYER_REACHABILITY_MARKER = 'psuedo-layer-reachability-marker',
  PSUEDO_CONTOUR_LAYER = 'psuedo-contour-layer'
}
