import { Injectable } from '@angular/core';
import {MapObject} from '../../interfaces/map-object';
import {Layer} from 'mapbox-gl';
import {MapboxService} from '../mapbox/mapbox.service';
import {Feature, FeatureCollection} from 'geojson';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapDataImportMappingService {

  private selectedFeatureSubject: Subject<Feature> = new Subject<Feature>();
  private mapObject: MapObject = null;
  private mapData: FeatureCollection = null;

  private hoveredFeatureId: number = null;
  private selectedFeatureId: number = null;
  private readonly layerId = 'map-layer-import';
  private layers: Layer[] = [{
    'id': `${this.layerId}-fill`,
    'metadata': {'st:type': 'default'},
    'filter': ['!=', 'id', ''],
    'type': 'fill',
    'layout': {},
    'paint': {
      'fill-color': '#71869C',
      'fill-opacity': 0.4,
      'fill-outline-color': '#FFFFFF'
    }
  }, {
    'id': `${this.layerId}-stroke`,
    'metadata': {'st:type': 'default'},
    'filter': ['!=', 'id', ''],
    'type': 'line',
    'paint': {'line-width': 2.5, 'line-color': '#71869C'}
  }, {
    'id': `${this.layerId}-fill-hover`,
    'metadata': {'st:type': 'hover'},
    'filter': ['==', 'id', ''],
    'type': 'fill',
    'layout': {},
    'paint': {
      'fill-color': '#F2B510',
      'fill-opacity': 0.4,
      'fill-outline-color': '#FFFFFF'
    }
  }, {
    'id': `${this.layerId}-stroke-hover`,
    'metadata': {'st:type': 'hover'},
    'filter': ['==', 'id', ''],
    'type': 'line',
    'paint': {'line-width': 2.5, 'line-color': '#F2B510'}
  }, {
    'id': `${this.layerId}-fill-selected`,
    'metadata': {'st:type': 'selected'},
    'filter': ['==', 'id', ''],
    'type': 'fill',
    'layout': {},
    'paint': {
      'fill-color': '#F2B510',
      'fill-opacity': 0.6,
      'fill-outline-color': '#FFFFFF'
    }
  }, {
    'id': `${this.layerId}-stroke-selected`,
    'metadata': {'st:type': 'selected'},
    'filter': ['==', 'id', ''],
    'type': 'line',
    'paint': {'line-width': 4, 'line-color': '#F2B510'}
  }];

  private onMouseClickEvent = (e) => {
    const layers = [`${this.layerId}-fill`, `${this.layerId}-fill-hover`];
    const features = this.mapObject.map.queryRenderedFeatures(e.point, {layers: layers});

    if (features && features.length > 0 && features[0].properties && features[0].properties.id) {
      this.selectFeature(features[0]);
    }
  }

  private onMouseMoveEvent = (e) => {
    if (this.mapObject) {
      const layers = [`${this.layerId}-fill`, `${this.layerId}-fill-hover`];
      const features = this.mapObject.map.queryRenderedFeatures(e.point, {layers: layers});

      if (features && features.length > 0 && features[0].properties && features[0].properties.id) {
        this.mapObject.map.getCanvas().style.cursor = 'pointer';
        this.highlightFeature(features[0].properties.id);
      } else {
        this.mapObject.map.getCanvas().style.cursor = '';
        this.unhighlightFeature();
      }
    }
  }

  constructor(
    private mapboxService: MapboxService
  ) { }

  setMapObject(mapObject: MapObject) {
    if (!this.mapObject) {
      this.mapObject = mapObject;
    }
  }

  setMapData(data: FeatureCollection) {
    if (this.mapObject) {
      this.clearMapData();

      this.mapData = data;
      this.mapboxService.updateGeoJsonDataLayer(this.mapObject, this.layerId, data.features, null, this.layers);
      this.mapboxService.fitToViewFeatures(this.mapObject.map, data.features, true);
      this.registerMapEvents();
    }
  }

  clearMapData() {
    if (this.mapObject) {
      this.mapData = null;
      this.unselectFeature();
      this.mapboxService.updateGeoJsonDataLayer(this.mapObject, this.layerId, [], null, this.layers);
      this.unregisterMapEvents();
    }
  }

  private registerMapEvents() {
    if (this.mapObject) {
      this.mapObject.map.on('click', this.onMouseClickEvent);
      this.mapObject.map.on('mousemove', this.onMouseMoveEvent);
    }
  }

  private unregisterMapEvents() {
    if (this.mapObject) {
      this.mapObject.map.off('click', this.onMouseClickEvent);
      this.mapObject.map.off('mousemove', this.onMouseMoveEvent);
    }
  }

  private highlightFeature(id: number) {
    if (this.mapObject) {
      if (this.hoveredFeatureId !== id && this.selectedFeatureId !== id) {
        this.hoveredFeatureId = id;

        this.layers.forEach(l => {
          if (l.metadata && l.metadata['st:type'] && this.mapObject.map.getLayer(l.id)) {
            if (l.metadata['st:type'] === 'default') {
              this.mapObject.map.setFilter(l.id, ['all', ['!=', 'id', id], ['!=', 'id', this.selectedFeatureId || '']]);
            } else if (l.metadata['st:type'] === 'hover') {
              this.mapObject.map.setFilter(l.id, ['all', ['==', 'id', id], ['!=', 'id', this.selectedFeatureId || '']]);
            }
          }
        });
      }
    }
  }

  private unhighlightFeature() {
    if (this.mapObject) {
      if (this.hoveredFeatureId) {
        this.hoveredFeatureId = null;

        this.layers.forEach(l => {
          if (l.metadata && l.metadata['st:type'] && this.mapObject.map.getLayer(l.id)) {
            if (l.metadata['st:type'] === 'default') {
              this.mapObject.map.setFilter(l.id, ['all', ['!=', 'id', ''], ['!=', 'id', this.selectedFeatureId || '']]);
            } else if (l.metadata['st:type'] === 'hover') {
              this.mapObject.map.setFilter(l.id, ['all', ['==', 'id', ''], ['!=', 'id', this.selectedFeatureId || '']]);
            }
          }
        });
      }
    }
  }

  private selectFeature(feature: any) {
    if (this.mapObject) {
      this.selectedFeatureId = feature.properties.id;
      this.unhighlightFeature();

      this.layers.forEach(l => {
        if (l.metadata && l.metadata['st:type'] && this.mapObject.map.getLayer(l.id)) {
          if (l.metadata['st:type'] === 'selected') {
            this.mapObject.map.setFilter(l.id, ['==', 'id', this.selectedFeatureId || '']);
          }
        }
      });

      if (this.mapData) {
        const selectedFeature = this.mapData.features
          .find(f => f.properties && f.properties.id ? f.properties.id === this.selectedFeatureId : null);

        if (selectedFeature) {
          this.selectedFeatureSubject.next(selectedFeature);
        }
      }
    }
  }

  private unselectFeature() {
    this.selectedFeatureId = null;

    this.layers.forEach(l => {
      if (l.metadata && l.metadata['st:type'] && this.mapObject.map.getLayer(l.id)) {
        if (l.metadata['st:type'] === 'selected') {
          this.mapObject.map.setFilter(l.id, ['==', 'id', '']);
        } else if (l.metadata['st:type'] === 'default') {
          this.mapObject.map.setFilter(l.id, ['all', ['!=', 'id', ''], ['!=', 'id', this.selectedFeatureId || '']]);
        } else if (l.metadata['st:type'] === 'hover') {
          this.mapObject.map.setFilter(l.id, ['all', ['==', 'id', ''], ['!=', 'id', this.selectedFeatureId || '']]);
        }
      }
    });
  }

  onSelectedFeatureChange(): Observable<Feature> {
    return this.selectedFeatureSubject;
  }
}
