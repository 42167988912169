<div class="dialog">
  <h1 mat-dialog-title>Edit Layer Styling</h1>
  <button mat-icon-button class="close-x-button" (click)="close()">
    <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>
  </button>

  <mat-dialog-content>
    <h2 *ngIf="data?.layerName">{{data?.layerName}}</h2>
    <ng-container *ngFor="let group of data?.paletteGroup">
      <div *ngFor="let item of group.paletteData" class="palette-input">
        <input *ngIf="isSmallNumberProperty(item.property)" type="number"
               [name]="item.inputName" step="0.01" min="0" max="1.0"
               [(ngModel)]="item.value" (ngModelChange)="valueHasChanged(item)">

        <input *ngIf="isLabelNumberProperty(item.property)" type="number"
               [name]="item.inputName" step="1" min="8" max="24"
               [(ngModel)]="item.value" (ngModelChange)="valueHasChanged(item)">

        <input *ngIf="isColorProperty(item.property)" type="color"
               [name]="item.inputName"
               [(ngModel)]="item.value" (ngModelChange)="valueHasChanged(item)">

        <label [for]="item.inputName">{{item.label}} {{item.propertyLabel}}</label>
      </div>
    </ng-container>

  </mat-dialog-content>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button class="key-action" [disabled]="!data?.stylingOverwritten"
            (click)="reset()" type="button">Reset</button>
    <button mat-raised-button class="key-action green" [disabled]="!valueChanged"
            (click)="apply()">Apply</button>
  </div>
</div>
