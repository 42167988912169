import {Injectable} from '@angular/core';
import {MapboxService} from '../../../shared/services/mapbox/mapbox.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MapObject} from '../../../shared/interfaces/map-object';
import {PlannedArea} from '../../interfaces/planned-area';
import {BehaviorSubject} from 'rxjs';
import {MapboxDrawModes} from '../../../shared/enums/mapbox-draw-modes';
import {PlannedAreaSelectPopupService} from '../planned-area-select-popup/planned-area-select-popup.service';
import {MapEditMode} from '../../../shared/enums/map-edit-mode';
import {Geometry} from 'geojson';
import {AppRoutes} from '../../../core/enums/app-routes';
import {InstructionsService} from '../../../shared/services/instructions/instructions.service';
import {HubCustomViewType} from '../../../hub/enums/hub-custom-view-type';
import Layer = mapboxgl.Layer;
import {PlannedAreaListService} from '../planned-area-list/planned-area-list.service';

@Injectable({
  providedIn: 'root'
})
export class PlannedAreaMappingService {

  private mapReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private mapObject: MapObject = null;
  private eventsCreated = false;
  private firstSet = false;
  private selectedPlannedArea: PlannedArea = null;
  private currentFeatureId: string = null;
  private editMode = false;
  private readonly sourceId = 'planned-area';
  private hoverPlannedAreaId = null;
  private instructionEvent: () => void;
  private hasZoomedToExtent = false;

  private layers: Layer[] = [
    {
      'id': 'planned-area-base',
      'type': 'fill',
      'paint': {
        'fill-color': ['case',
          ['==', ['get', 'rezoningStatusId'], 1], '#FFD056',
          ['==', ['get', 'rezoningStatusId'], 2], '#2A81BD',
          ['==', ['get', 'rezoningStatusId'], 3], '#2DCAD3',
          ['==', ['get', 'rezoningStatusId'], 4], '#42BA50',
          ['==', ['get', 'rezoningStatusId'], 5], '#E64173',
          '#3C3C3C'
        ],
        'fill-opacity': ['case',
          ['boolean', ['feature-state', 'hover'], false],
          0.5,
          0.25
        ]
      }
    }, {
      'id': 'planned-area-stroke',
      'type': 'line',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': ['case',
          ['==', ['get', 'rezoningStatusId'], 1], '#FFD056',
          ['==', ['get', 'rezoningStatusId'], 2], '#2A81BD',
          ['==', ['get', 'rezoningStatusId'], 3], '#2DCAD3',
          ['==', ['get', 'rezoningStatusId'], 4], '#42BA50',
          ['==', ['get', 'rezoningStatusId'], 5], '#E64173',
          '#3C3C3C'
        ],
        'line-width': 2
      }
    }, {
      'id': 'planned-area-labels',
      'type': 'symbol',
      'paint': {
        'text-color': '#333333',
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 2
      },
      'layout': {
        'symbol-placement': 'point',
        'text-font': ['Open Sans Regular'],
        'text-field': '{name}',
        'text-size': 12,
        'text-justify': 'center'
      }
    }
  ];

  private drawStyles: Layer[] = [
    {
      'id': 'planned-area-base',
      'type': 'fill',
      'layout': {},
      'paint': {
        'fill-color': '#FFD640',
        'fill-opacity': 0.25
      }
    }, {
      'id': 'planned-area-stroke-active',
      'type': 'line',
      'filter': ['==', 'active', 'true'],
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': '#FAA22B',
        'line-dasharray': [0.2, 2],
        'line-width': 2
      }
    }, {
      'id': 'planned-area-stroke-inactive',
      'type': 'line',
      'filter': ['!=', 'active', 'true'],
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': '#FAA22B',
        'line-width': 2
      }
    }, {
      'id': 'planned-area-vertex-stroke-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],

        ['!=', 'active', 'true']
      ],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#FFFFFF'
      }
    }, {
      'id': 'planned-area-vertex-fill-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],

        ['!=', 'active', 'true']
      ],
      'paint': {
        'circle-radius': 3,
        'circle-color': '#FAA22B'
      }
    }, {
      'id': 'planned-area-vertex-stroke-active',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],

        ['==', 'active', 'true']
      ],
      'paint': {
        'circle-radius': 7,
        'circle-color': '#FFFFFF'
      }
    }, {
      'id': 'planned-area-vertex-fill-active',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],

        ['==', 'active', 'true']
      ],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#FAA22B'
      }
    }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mapboxService: MapboxService,
    private plannedAreaSelectPopupService: PlannedAreaSelectPopupService,
    private instructionsService: InstructionsService,
    private plannedAreaListService: PlannedAreaListService
  ) {}

  private watchUpdates() {
    if (!this.firstSet) {
      this.firstSet = true;
      this.watchHoveredPlannedAreaChange();
      this.watchFilteredPlannedAreaListChange();
    }
  }

  private watchHoveredPlannedAreaChange() {
    this.plannedAreaListService.onHoveredPlannedAreaChange()
      .subscribe(id => {
        this.togglePlannedAreaHighlight(id, true);
      });
  }

  private watchFilteredPlannedAreaListChange() {
    this.plannedAreaListService.onFilteredPlannedAreaListChange()
      .subscribe(areas => {
        this.setPlannedAreaView(areas, true);
      });
  }

  setMapObject(mapObject: MapObject) {
    if (!this.mapObject) {
      this.mapObject = mapObject;
      this.mapReady.next(true);
    }
  }

  onMapReady(): BehaviorSubject<boolean> {
    return this.mapReady;
  }

  getMapObject(): MapObject {
    return this.mapObject;
  }

  getZoomedToExtent() {
    return this.hasZoomedToExtent;
  }

  setZoomedToExtent(value: boolean) {
    this.hasZoomedToExtent = value;
  }

  startEditPlannedAreaGeom() {
    this.mapboxService.hideLayers(this.mapObject, this.layers);
    this.mapboxService.enableEditMode(this.mapObject, MapEditMode.PLANNED_AREA, this.drawStyles);

    if (this.selectedPlannedArea) {
      const featureIds = this.mapboxService.addFeatureToDrawControl(this.mapObject, this.selectedPlannedArea);

      if (featureIds.length > 0) {
        this.currentFeatureId = featureIds[0];
      }
    }

    this.editMode = true;
  }

  stopEditPlannedAreaGeom() {
    this.mapObject.map.off('click', this.instructionEvent);
    this.mapboxService.showLayers(this.mapObject, this.layers);
    this.mapboxService.disableEditMode(this.mapObject);

    this.editMode = false;
  }

  startEditNewPlannedArea(encodedPolyline?: string, viewType?: HubCustomViewType) {
    this.currentFeatureId = null;
    this.mapboxService.enableEditMode(this.mapObject, MapEditMode.PLANNED_AREA, this.drawStyles);
    this.editMode = true;

    this.mapboxService.setDrawMode(this.mapObject, MapboxDrawModes.DRAW_POLYGON);

    // Load in existing geometry for user
    if (encodedPolyline && viewType) {
      // Get the actual circle geom from the given radius geom
      let geometry = null;
      if (viewType === HubCustomViewType.RADIUS) {
        geometry = this.mapboxService.getCircleGeomFromRadiusEncodedPolyline(encodedPolyline as string);
      } else if (viewType === HubCustomViewType.POLYGON) {
        geometry = this.mapboxService.convertEncodedPolylineToPolygon(encodedPolyline as string);
      }

      const feature = {
        type: 'Polygon',
        geometry: geometry
      };

      const featureIds = this.mapboxService.addFeatureToDrawControl(this.mapObject, feature);
      if (featureIds.length > 0) {
        this.currentFeatureId = featureIds[0];
      }

      this.mapboxService.fitToViewFeatures(this.mapObject.map, [feature]);
    }
    // Only show instructions if user is drawing their own geometry
    else {
      this.instructionsService.setMessage('Click on the map to start drawing a planned area.', 'map-marked-alt');

      this.instructionEvent = () => {
        this.instructionsService.setMessage('Click to add additional point to the area. Double-click to finish.',
          'map-marked-alt');
      };

      this.mapObject.map.once('click', this.instructionEvent);
    }
  }

  getModifiedGeometry(): Geometry {
    let geom: Geometry = null;

    if (this.mapObject.draw) {
      const feature: any = this.mapObject.draw.get(this.currentFeatureId);

      if (feature) {
        geom = feature.geometry;
      }
    }

    return geom;
  }

  togglePlannedAreaHighlight(id: number, suppressBroadcast?: boolean) {
    if (id) {
      if (this.hoverPlannedAreaId) {
        this.mapObject.map.setFeatureState({'source': this.sourceId, id: this.hoverPlannedAreaId.toString()}, {hover: false});
      }

      this.hoverPlannedAreaId = id;
      this.mapObject.map.setFeatureState({'source': this.sourceId, id: this.hoverPlannedAreaId.toString()}, {hover: true});
      if (!suppressBroadcast) {
        this.plannedAreaListService.setHoveredPlannedArea(id);
      }
    } else {
      if (this.hoverPlannedAreaId) {
        this.mapObject.map.setFeatureState({'source': this.sourceId, id: this.hoverPlannedAreaId.toString()}, {hover: false});
      }
      if (!suppressBroadcast) {
        this.plannedAreaListService.clearHoveredPlannedArea();
      }
    }
  }

  private onMouseMove(e) {
    if (!this.mapObject.editMode && !this.selectedPlannedArea) {
      if (e.features.length > 0) {
        const id = e.features[0].id;
        this.togglePlannedAreaHighlight(id);
        this.mapObject.map.getCanvas().style.cursor = 'pointer';
      }
    }
  }

  private onMouseLeave() {
    if (!this.mapObject.editMode) {
      this.togglePlannedAreaHighlight(null);
      this.mapObject.map.getCanvas().style.cursor = '';
    }
  }

  private onMouseClick(e) {
    if (!this.mapObject.editMode) {
      const features = this.mapObject.map.queryRenderedFeatures(e.point, {layers: ['planned-area-base']});

      if (features.length === 1) {
        const paId = features[0].properties.paId;
        this.router.navigate([AppRoutes.GAP, paId]);
      } else if (features.length > 1) {
        this.plannedAreaSelectPopupService.showPopup({
          x: e.point.x,
          y: e.point.y,
          features: features
        });
      }
    }
  }

  private onDrawCreate(e) {
    // If in edit mode and an object is drawn, save the feature ID
    if (this.editMode) {
      if (!this.currentFeatureId && e && e.features && e.features.length === 1) {
        this.instructionsService.hide();
        this.currentFeatureId = e.features[0].id;
      }
    }
  }

  private onDrawDelete() {
    // If in edit mode, capture event
    if (this.editMode) {
      this.currentFeatureId = null;
      this.mapboxService.setDrawMode(this.mapObject, MapboxDrawModes.DRAW_POLYGON);
    }
  }

  // Create map interaction events
  createEvents() {
    if (!this.eventsCreated) {
      this.mapboxService.registerEvent(this.mapObject, 'click', this.sourceId, (e) => {
        this.onMouseClick(e);
      });

      this.mapboxService.registerEvent(this.mapObject, 'mousemove', this.sourceId, (e) => {
        this.onMouseMove(e);
      });

      this.mapboxService.registerEvent(this.mapObject, 'mouseleave', this.sourceId, () => {
        this.onMouseLeave();
      });

      this.mapObject.map.on('draw.create', (e) => {
        this.onDrawCreate(e);
      });

      this.mapObject.map.on('draw.delete', () => {
        this.onDrawDelete();
      });

      this.eventsCreated = true;
    }
  }

  setPlannedAreaView(data: PlannedArea[], fitToView?: boolean) {
    this.mapObject.map.resize();

    // Set selected geometry if there is only one geometry object
    if (data && data.length === 1) {
      this.selectedPlannedArea = data[0];
    } else {
      this.selectedPlannedArea = null;
    }

    this.mapboxService.updateGeoJsonDataLayer(this.mapObject, this.sourceId, data, 'paId', this.layers);
    this.watchUpdates();
    this.createEvents();
    if (data && data.length > 0 && fitToView) {
      this.mapboxService.fitToViewFeatures(this.mapObject.map, data);
    }
  }
}
