import { Injectable } from '@angular/core';
import {MapReferenceLayerCategory} from '../../../shared/interfaces/map-reference-layer-category';
import {ApiCommsService} from '../../services/api-comms/api-comms.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {tap} from 'rxjs/operators';
import * as moment from 'moment';
import {MapReferenceLayerType} from '../../../shared/enums/map-reference-layer-type';

@Injectable({
  providedIn: 'root'
})
export class MapReferenceLayerResolverService implements Resolve<MapReferenceLayerCategory[]> {

  private onReferenceLayerCategoriesReadySubject: BehaviorSubject<MapReferenceLayerCategory[]> =
    new BehaviorSubject<MapReferenceLayerCategory[]>(null);

  constructor(private apiCommsService: ApiCommsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MapReferenceLayerCategory[]> {
    return this.apiCommsService.getReferenceLayers()
      .pipe(
        tap(referenceLayerCategories => {
          if (referenceLayerCategories) {

            const checkSubCategories = (subCategories: MapReferenceLayerCategory[], parent: MapReferenceLayerCategory) => {
              for (const subCat of subCategories) {
                subCat.parentCategory = parent;

                for (const layer of subCat.layers) {
                  const updateDate = layer.lastUpdatedDate ? moment(layer.lastUpdatedDate).format('DD/MM/YYYY') : null;
                  const interactive = layer.interactive || layer.layerType === MapReferenceLayerType.POLYGON;

                  let iconStrip = '';
                  if (interactive || layer.hasTooltip) {
                    iconStrip = `\
                      <div class="display-icon-strip"> \
                        ${interactive ? `<div class="display-icon"><img src="assets/images/icons/interactive-layer-icon.svg" class="icon"> Interactive</div>` : ''} \
                        ${layer.hasTooltip ? `<div class="display-icon"><span class="tooltip-icon icon"></span> Tooltip</div>` : ''} \
                      </div>
                    `;
                  }

                  layer.tooltipHTML = `
                    ${layer.description} \
                    <div class="small-text"> \
                      ${iconStrip} \
                      <span class="bold">Name: </span> ${layer.displayName}<br> \
                      <span class="bold">Source:</span> ${layer.source}<br> \
                      <span class="bold">Owner:</span> ${layer.owner}<br> ` +
                      (updateDate ? `<span class="bold">Updated:</span> ${updateDate}<br> ` : '') +
                    `</div>
                  `;

                  layer.category = subCat;
                }

                checkSubCategories(subCat.subCategories, subCat);
              }
            };

            checkSubCategories(referenceLayerCategories, null);

            this.onReferenceLayerCategoriesReadySubject.next(referenceLayerCategories);
          }
        })
      );
  }

  onReferenceLayerCategoriesReady(): Observable<MapReferenceLayerCategory[]> {
    return this.onReferenceLayerCategoriesReadySubject;
  }
}
