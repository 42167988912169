import {Injectable} from '@angular/core';
import {MapObject} from '../../interfaces/map-object';
import {MapboxService} from '../mapbox/mapbox.service';
import {MapEditMode} from '../../enums/map-edit-mode';
import {MapImage} from '../../interfaces/map-image';
import {environment} from '../../../../environments/environment';
import {Layer} from 'mapbox-gl';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {first, mergeMap} from 'rxjs/operators';
import {GeoJSON} from 'geojson';
import {OtpTimingOptions} from '../../enums/otp-timing-options';
import {OtpTripPeriodOptions} from '../../enums/otp-trip-period-options';
import {OtpTripModes} from '../../enums/otp-trip-modes';
import {OtpConfigResolverService} from '../../../core/resolvers/otp-config-resolver/otp-config-resolver.service';
import {InstructionsService} from '../instructions/instructions.service';
import {OtpOptions} from '../../interfaces/otp-options';
import {Observable} from 'rxjs';
import {MapPsuedoLayers} from '../../enums/map-psuedo-layers';
import {HubApiCommsService} from '../../../hub/services/hub-api-comms/hub-api-comms.service';
import {Chart} from 'highcharts';
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';
import {ChartService} from '../chart/chart.service';
import {HubStudentReachabilityIntervalData} from '../../../hub/interfaces/hub-student-reachability-interval-data';
import * as moment from 'moment';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class MapReachabilityService {

  private reachabilityAnalysisChart: Chart = null;

  private reachabilityChartOptions: any = {
    chart: {
      className: 'hub-enrolment-chart',
      spacingLeft: 0,
      spacingBottom: 0
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'Students'
      },
      labels: {
        format: '{value:,.0f}'
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    legend : {
      enabled: false
    },
    tooltip: {
      shadow: false,
      shared: true,
      outside: true,
      formatter: function() {
        const title = Number(this.x / 60);
        let tooltipHtml = `<b>${title} mins</b><br/><br/>`;

        this.points.forEach(point => {
          const val = point.y.toLocaleString();
          tooltipHtml +=
            `<span style="color:${point.color};">\u25CF</span> Number of Students: <b>${val}</b><br/>`;
        });
        return tooltipHtml;
      }
    },
    xAxis: {
      minRange: 900,
      tickInterval: 900,
      labels: {
        formatter: function () {
          return Number(this.value / 60);
        }
      },
      title: {
        text: 'Minutes'
      }
    },
    series: [{
      type: 'column',
      stack: 0,
      name: 'Time Interval',
      color: '#47B0E7',
      maxPointWidth: 50
    }],
    exporting: {
      enabled: false
    }
  };

  private readonly markerImage: MapImage = {
    id: 'map-reachability-marker',
    url: environment.assetsFolder + 'map-reachability-marker.png'
  };

  private readonly mapReachabilityMarkerLayerId = 'map-reachability-marker-layer';
  private markerLayer: Layer[] = [{
    'id': this.mapReachabilityMarkerLayerId,
    'type': 'symbol',
    'layout': {
      'icon-image': this.markerImage.id,
      'icon-size': 1,
      'icon-anchor': 'bottom'
    },
  }];

  private readonly mapReachabilityResultsLayerId = 'map-reachability-results';

  private resultsLayers: Layer[] = [{
    'id': `${this.mapReachabilityResultsLayerId}-2700`,
    'type': 'fill',
    'paint': {
      'fill-color': '#B60048',
      'fill-opacity': 0.2
    },
    'filter': ['all', ['>', 'time', 1800], ['<=', 'time', 2700]]
  }, {
    'id': `${this.mapReachabilityResultsLayerId}-1800`,
    'type': 'fill',
    'paint': {
      'fill-color': '#91006D',
      'fill-opacity': 0.2
    },
    'filter': ['all', ['>', 'time', 1200], ['<=', 'time', 1800]]
  }, {
    'id': `${this.mapReachabilityResultsLayerId}-1200`,
    'type': 'fill',
    'paint': {
      'fill-color': '#6D0091',
      'fill-opacity': 0.2
    },
    'filter': ['all', ['>', 'time', 900], ['<=', 'time', 1200]]
  }, {
    'id': `${this.mapReachabilityResultsLayerId}-900`,
    'type': 'fill',
    'paint': {
      'fill-color': '#4800B6',
      'fill-opacity': 0.2
    },
    'filter': ['all', ['>', 'time', 600], ['<=', 'time', 900]]
  }, {
    'id': `${this.mapReachabilityResultsLayerId}-600`,
    'type': 'fill',
    'paint': {
      'fill-color': '#2400DA',
      'fill-opacity': 0.2
    },
    'filter': ['all', ['>', 'time', 300], ['<=', 'time', 600]]
  }, {
    'id': `${this.mapReachabilityResultsLayerId}-300`,
    'type': 'fill',
    'paint': {
      'fill-color': '#0000FF',
      'fill-opacity': 0.2
    },
    'filter': ['<=', 'time', 300]
  }];

  constructor(
    private mapboxService: MapboxService,
    private otpConfigResolver: OtpConfigResolverService,
    private httpClient: HttpClient,
    private instructionsService: InstructionsService,
    private hubApiComms: HubApiCommsService,
    private chartService: ChartService
  ) { }

  enable(mapObject: MapObject, hideInstructions?: boolean) {
    if (!hideInstructions) {
      this.setInstructionMessage(mapObject);
    }

    mapObject.mapReachabilityObject.visible = true;
    mapObject.mapReachabilityObject.onVisibilityChange.next(mapObject.mapReachabilityObject.visible);

    if (!mapObject.mapReachabilityObject.otpOptions) {
      mapObject.mapReachabilityObject.otpOptions = {
        timing: OtpTimingOptions.ARRIVING,
        period: OtpTripPeriodOptions.AM,
        mode: [OtpTripModes.WALK],
        time: 30
      };
    }

    this.enableMapEditMode(mapObject);

    this.loadImages(mapObject, () => {
      this.registerMapClickEvent(mapObject);
    });
  }

  disable(mapObject: MapObject) {
    if (mapObject && mapObject.mapReachabilityObject) {
      mapObject.mapReachabilityObject.currentLocation = null;
      mapObject.mapReachabilityObject.visible = false;
      mapObject.mapReachabilityObject.onVisibilityChange.next(mapObject.mapReachabilityObject.visible);

      this.instructionsService.hide();

      this.unregisterMapClickEvent(mapObject);
      this.unregisterMarkerClickEvents(mapObject);
      this.removeLayers(mapObject);
      this.disableMapEditMode(mapObject);
    }
  }

  private setInstructionMessage(mapObject: MapObject) {
    this.instructionsService.setMessage('Click on the map define the start location.', 'chart-network');

    mapObject.map.once('click', () => {
      this.instructionsService.hide();
    });
  }

  private loadImages(mapObject: MapObject, callback: () => void) {
    this.mapboxService.loadImages(mapObject, [this.markerImage], callback);
  }

  private registerMapClickEvent(mapObject: MapObject) {
    if (mapObject && mapObject.mapReachabilityObject && !mapObject.mapReachabilityObject.onMapClick) {
      mapObject.mapReachabilityObject.onMapClick = (e) => {
        this.onMapClick(mapObject, e);
      };

      mapObject.map.on('click', mapObject.mapReachabilityObject.onMapClick);
    }
  }

  private unregisterMapClickEvent(mapObject: MapObject) {
    if (mapObject && mapObject.mapReachabilityObject && mapObject.mapReachabilityObject.onMapClick) {
      mapObject.map.off('click', mapObject.mapReachabilityObject.onMapClick);
      mapObject.mapReachabilityObject.onMapClick = null;
    }
  }

  private enableMapEditMode(mapObject: MapObject) {
    if (mapObject) {
      mapObject.map.getCanvas().style.cursor = 'crosshair';
      this.mapboxService.enableEditMode(mapObject, MapEditMode.REACHABILITY_TOOL);
    }
  }

  private disableMapEditMode(mapObject: MapObject) {
    if (mapObject) {
      this.mapboxService.disableEditMode(mapObject);
    }
  }

  private onMapClick(mapObject: MapObject, e: any) {
    if (e.lngLat && mapObject) {
      mapObject.mapReachabilityObject.marker = {
        geometry: {
          type: 'Point',
          coordinates: [e.lngLat.lng, e.lngLat.lat]
        }
      };

      this.updateMapMarker(mapObject);
      this.updateLocation(mapObject, e.lngLat.lat, e.lngLat.lng);
    }
  }

  private updateMapMarker(mapObject: MapObject) {
    this.updateMarkerPosition(mapObject);
    this.createMarkerEvents(mapObject);
  }

  private updateMarkerPosition(mapObject: MapObject) {
    this.mapboxService.updateGeoJsonDataLayer(mapObject, this.mapReachabilityMarkerLayerId,
      [mapObject.mapReachabilityObject.marker], null, this.markerLayer, MapPsuedoLayers.PSUEDO_LAYER_REACHABILITY_MARKER);
  }

  private createMarkerEvents(mapObject: MapObject) {
    // Mouse down event
    if (!mapObject.mapReachabilityObject.onMarkerMouseDown) {
      const markerMouseDownEvent = (e: any) => {
        e.preventDefault();
        mapObject.mapReachabilityObject.isDragging = true;
      };

      mapObject.map.on('mousedown', this.mapReachabilityMarkerLayerId, markerMouseDownEvent);
      mapObject.mapReachabilityObject.onMarkerMouseDown = markerMouseDownEvent;
    }

    // Mouse up event
    if (!mapObject.mapReachabilityObject.onMarkerMouseUp) {
      const markerMouseUpEvent = (e) => {
        if (mapObject.mapReachabilityObject.isDragging) {
          mapObject.mapReachabilityObject.isDragging = false;
          this.updateLocation(mapObject, e.lngLat.lat, e.lngLat.lng);
        }
      };

      mapObject.map.on('mouseup', markerMouseUpEvent);
      mapObject.mapReachabilityObject.onMarkerMouseUp = markerMouseUpEvent;
    }

    // (MAP) Mouse move event
    if (!mapObject.mapReachabilityObject.onMapMouseMove) {
      const mapMouseMoveEvent = (e) => {
        if (mapObject.mapReachabilityObject.isDragging && mapObject.mapReachabilityObject.marker) {
          e.preventDefault();
          mapObject.mapReachabilityObject.marker.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];
          this.updateMarkerPosition(mapObject);
        }
      };

      mapObject.map.on('mousemove', mapMouseMoveEvent);
      mapObject.mapReachabilityObject.onMapMouseMove = mapMouseMoveEvent;
    }

    // Mouse move event
    if (!mapObject.mapReachabilityObject.onMarkerMouseMove) {
      const markerMouseMoveEvent = (e) => {
        mapObject.map.getCanvas().style.cursor = 'pointer';
      };

      mapObject.map.on('mousemove', this.mapReachabilityMarkerLayerId, markerMouseMoveEvent);
      mapObject.mapReachabilityObject.onMarkerMouseMove = markerMouseMoveEvent;
    }

    // Mouse leave event
    if (!mapObject.mapReachabilityObject.onMarkerMouseLeave) {
      const markerMouseLeaveEvent = (e) => {
        mapObject.map.getCanvas().style.cursor = 'crosshair';
      };

      mapObject.map.on('mouseleave', this.mapReachabilityMarkerLayerId, markerMouseLeaveEvent);
      mapObject.mapReachabilityObject.onMarkerMouseLeave = markerMouseLeaveEvent;
    }
  }

  private unregisterMarkerClickEvents(mapObject: MapObject) {
    if (mapObject && mapObject.mapReachabilityObject) {
      if (mapObject.mapReachabilityObject.onMarkerMouseDown) {
        mapObject.map.off('mousedown', this.mapReachabilityMarkerLayerId, mapObject.mapReachabilityObject.onMarkerMouseDown);
        mapObject.mapReachabilityObject.onMarkerMouseDown = null;
      }

      if (mapObject.mapReachabilityObject.onMarkerMouseUp) {
        mapObject.map.off('mouseup', mapObject.mapReachabilityObject.onMarkerMouseUp);
        mapObject.mapReachabilityObject.onMarkerMouseUp = null;
      }

      if (mapObject.mapReachabilityObject.onMapMouseMove) {
        mapObject.map.off('mousemove', mapObject.mapReachabilityObject.onMarkerMouseDown);
        mapObject.mapReachabilityObject.onMarkerMouseDown = null;
      }

      if (mapObject.mapReachabilityObject.onMarkerMouseMove) {
        mapObject.map.off('mousemove', this.mapReachabilityMarkerLayerId, mapObject.mapReachabilityObject.onMarkerMouseMove);
        mapObject.mapReachabilityObject.onMarkerMouseMove = null;
      }

      if (mapObject.mapReachabilityObject.onMarkerMouseLeave) {
        mapObject.map.off('mouseleave', this.mapReachabilityMarkerLayerId, mapObject.mapReachabilityObject.onMarkerMouseLeave);
        mapObject.mapReachabilityObject.onMarkerMouseLeave = null;
      }
    }
  }

  private removeLayers(mapObject: MapObject) {
    this.mapboxService.updateGeoJsonDataLayer(mapObject, this.mapReachabilityMarkerLayerId,
      null, null, this.markerLayer);
    this.mapboxService.updateGeoJsonDataLayer(mapObject, this.mapReachabilityResultsLayerId,
      null, null, this.resultsLayers);

  }

  updateLocation(mapObject: MapObject, lat: number, lng: number) {
    mapObject.mapReachabilityObject.currentLocation = [lat, lng];
    this.updateResults(mapObject);
  }

  updateResults(mapObject: MapObject) {
    this.fetchOtpResults(mapObject.mapReachabilityObject.currentLocation, mapObject.mapReachabilityObject.otpOptions)
      .pipe(first())
      .subscribe((response: GeoJSON.FeatureCollection) => {
        this.updateOtpResults(mapObject, response);
      }, () => {
        this.updateOtpResults(mapObject, null);
      });
  }

  calculateStudentReachability(mapObject: MapObject) {
    return mapObject.mapReachabilityObject.onResultsUpdate
      .pipe(
        first(),
        mergeMap(
          featureCollection => {
            return this.hubApiComms.getStudentReachability(featureCollection);
          }
        )
      );
  }

  fetchOtpResults(location: [number, number], options: OtpOptions): Observable<GeoJSON.FeatureCollection> {
    return this.otpConfigResolver.onUpdate()
      .pipe(
        mergeMap(otpConfig => {
          const date = otpConfig.date;
          const time = options.period === OtpTripPeriodOptions.AM ? otpConfig.amTime : otpConfig.pmTime;
          const mode = Array.isArray(options.mode) ? options.mode.join(',') : [options.mode];
          const lat = location[0];
          const lng = location[1];
          // const arriveBy =
          // (mapObject.mapReachabilityObject.otpOptions.timing === OtpTimingOptions.ARRIVING ? 1 : 0);
          // const toPlace =
          // (mapObject.mapReachabilityObject.otpOptions.timing === OtpTimingOptions.DEPARTING ? `&toPlace=${lat},${lng}` : '');

          const t = options.time * 60;

          const intervals = [
            5 * 60,
            10 * 60,
            15 * 60,
            20 * 60,
            30 * 60,
            45 * 60
          ].filter(i => i <= t);

          const cutOffs = intervals.map(i => `&cutoffSec=${i}`).join('');

          return this.hubApiComms.getOtpResults(date, time, mode as string[], lat, lng, cutOffs);
        })
      );
  }

  updateOtpResults(mapObject: MapObject, featureCollection: GeoJSON.FeatureCollection) {
    mapObject.mapReachabilityObject.onResultsUpdate.next(featureCollection);

    this.mapboxService.updateGeoJsonDataLayer(mapObject, this.mapReachabilityResultsLayerId,
      featureCollection ? featureCollection.features : null, null, this.resultsLayers, MapPsuedoLayers.PSUEDO_LAYER_REACHABILITY_RESULTS);
  }

  createReachabilityResults(mapObject: MapObject, reachabilityOptions: any) {
    mapObject.mapReachabilityObject.otpOptions = {
      timing: reachabilityOptions.timing,
      period: reachabilityOptions.period,
      mode: Array.isArray(reachabilityOptions.mode) ? reachabilityOptions.mode : [reachabilityOptions.mode],
      time: reachabilityOptions.time
    };

    this.enable(mapObject, true);

    this.onMapClick(mapObject, {
      lngLat: {
        lat: reachabilityOptions.location[0],
        lng: reachabilityOptions.location[1]
      }
    });
  }

  updateReachabilityAnalysisChart(container: string, selectedGrades: string[], data: HubStudentReachabilityIntervalData[]) {
    if (this.reachabilityAnalysisChart) {
      this.reachabilityAnalysisChart.destroy();
      this.reachabilityAnalysisChart = null;
    }

    const chartOptions = _.cloneDeep(this.reachabilityChartOptions);

    chartOptions.series[0].data = data.map(i => {
      const filtered = i.grades.filter(g => selectedGrades.includes(g.grade));
      let total = null;

      if (filtered && filtered.length > 0) {
        total = filtered.map(d => d.students).reduce((a, b) => (a + b));
      }

      return [i.timeInterval, total];
    });

    this.reachabilityAnalysisChart = Highcharts.chart(container, chartOptions);
  }

  exportReachabilityAnalysisChart(modes: string) {
    this.chartService.exportChart('Reachability', 'Student Reachability Analysis', modes, this.reachabilityAnalysisChart);
  }

  exportReachabilityAnalysisData(mapObject: MapObject, modes: string) {
    mapObject.mapReachabilityObject.onResultsUpdate
      .pipe(
        first(),
        mergeMap((featureCollection: GeoJSON.FeatureCollection) => {
          return this.hubApiComms.getStudentReachabilityExport(featureCollection);
        })
      )
      .subscribe(data => {
        const fileName = `EE-export - Reachability - Student Reachability Analysis - ${modes} ` +
          `- ${moment().format('YYYYMMDD')}`;

        saveAs(data, fileName);
      });
  }

  getOtpResultsKmlExport(geoJson: GeoJSON.FeatureCollection) {
    this.hubApiComms.getOtpResultsKmlExport(geoJson)
      .pipe(
        first()
      )
      .subscribe(data => {
        if (data) {
          const fileName = `EE-export - HUB - Reachability Geometry` +
            ` - ${moment().format('YYYYMMDD')}.kml`;

          saveAs(data, fileName);
        }
      });
  }
}
