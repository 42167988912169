import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {MapReferenceLayerCategory} from '../../../shared/interfaces/map-reference-layer-category';
import {MapBasemapCategory} from '../../../shared/interfaces/map-basemap-category';
import {UserEvent} from '../../interfaces/user-event';
import {catchError, map, tap} from 'rxjs/operators';
import {UserData} from '../../../admin/interfaces/user-data';
import {OtpConfig} from '../../../shared/interfaces/otp-config';
import {ShapeDownload} from '../../../shared/interfaces/shape-download';
import {DocumentLinks} from '../../interfaces/document-links';

@Injectable({
  providedIn: 'root'
})
export class ApiCommsService {

  private readonly coreUrl = environment.backendServer.mainPath + environment.backendServer.eagleEye;

  constructor(private http: HttpClient) {
  }

  processParams(payload: any): string {
    const params: string[] = [];

    Object.keys(payload).forEach(k => {
      if (payload[k] && payload[k].length > 0) {
        let value = '';

        if (Array.isArray(payload[k])) {
          value = JSON.stringify(payload[k]).replace(/\[/g, '').replace(/\]/g, '');
        } else {
          value = payload[k];
        }

        params.push(`${k}=${value}`);
      }
    });

    return params && params.length > 0 ? ('?' + params.join('&')) : '';
  }

  // Eagle eye calls
  getBasemaps(): Observable<MapBasemapCategory[]> {
    const url = `${this.coreUrl}/basemaps`;
    return this.http.get<MapBasemapCategory[]>(url);
  }

  getReferenceLayers(): Observable<MapReferenceLayerCategory[]> {
    const url = `${this.coreUrl}/reference-layers`;
    return this.http.get<MapReferenceLayerCategory[]>(url);
  }
  
  getLayerHierarchy(): Observable<MapReferenceLayerCategory[]> {
    const url = `${this.coreUrl}/layer-hierarchy`;
    return this.http.get<MapReferenceLayerCategory[]>(url);
  }

  getShapeDownloads(): Observable<ShapeDownload[]> {
    const url = `${this.coreUrl}/shape-downloads`;
    return this.http.get<ShapeDownload[]>(url);
  }

  getSchoolDates(): Observable<string[]> {
    const url = `${this.coreUrl}/school-dates`;
    return this.http.get<string[]>(url);
  }

  getDocumentLinks(): Observable<DocumentLinks[]> {
    const url = `${this.coreUrl}/document-links`;
    return this.http.get<DocumentLinks[]>(url);
  }

  getOtpConfig(): Observable<OtpConfig> {
    const url = `${this.coreUrl}/otp-config`;
    return this.http.get<OtpConfig>(url);
  }

  postUserEvent(userEvent: UserEvent): Observable<any> {
    const url = `${this.coreUrl}/user-event`;
    return this.http.post<any>(url, userEvent);
  }

  getGiphyImage(keyword: string): Observable<any> {
    const url = `https://api.giphy.com/v1/gifs/search?api_key=${environment.giphyApiKey}&q=${keyword}&rating=g&offset=0`;
    return this.http.get<any>(url)
      .pipe(
        map(result => {
          if (result && result.data && result.data.length > 0) {
            const index = Math.floor(Math.random() * result.data.length);
            if (result.data[index].images.original.url) {
              return result.data[index].images.original.url;
            }
          }

          return null;
        })
      );
  }

  getCurrentUser(): Observable<UserData> {
    const url = `${this.coreUrl}/current-user`;
    return this.http.get<any>(url);
  }

  unsubscribeUser(token: string): Observable<any> {
    const url = `${this.coreUrl}/unsubscribe`;
    return this.http.put<any>(url, {
      token: token
    });
  }

  updateUserTocAgreement(): Observable<any> {
    const url = `${this.coreUrl}/user-toc-agreement`;
    return this.http.put<any>(url, {});
  }

  getFavorites(userId: number): Observable<number[]> {
    return this.http.get<any>(`${this.coreUrl}/favorites/${userId}`)
      .pipe(
        tap(response => console.log('Response from getFavorites:', response)), 
        map(response => {
          if (Array.isArray(response)) {
            const layerIds = response.map(fav => fav.layer_id);
            return layerIds;
          } else if (response && response.favorite_layer_ids) {
            return response.favorite_layer_ids;
          } else {
            console.error('Unexpected response format:', response);
            return [];
          }
        }),
        catchError(error => {
          console.error('Error in getFavorites:', error);
          return of([]); 
        })
      );
  }

}
