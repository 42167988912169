import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'ee-session-expiry-notification',
  templateUrl: './session-expiry-notification.component.html',
  styleUrls: ['./session-expiry-notification.component.scss']
})
export class SessionExpiryNotificationComponent implements OnInit {

  private remainingInterval = null;
  remainingTime = '0 seconds';

  constructor(
    public dialogRef: MatDialogRef<SessionExpiryNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  private setRemainingTime() {
    let remaining = 0;
    let seconds = true;

    if (this.data && this.data.expiry) {
      const now = Math.round(new Date().getTime() / 1000);
      remaining = this.data.expiry - now;

      if (remaining > 60) {
        remaining = Math.round(remaining / 60);
        seconds = false;
      }
    }

    this.remainingTime = (remaining >= 0 ? remaining : 0).toString() + (seconds ? ' seconds' : ' minutes');

    if (remaining <= 0 && this.remainingInterval) {
      clearInterval(this.remainingInterval);
      this.close(false);
    }
  }

  ngOnInit() {
    this.setRemainingTime();

    this.remainingInterval = setInterval(() => {
      this.setRemainingTime();
    }, 1000);
  }

  close(val: boolean) {
    this.dialogRef.close(val);
  }

}
