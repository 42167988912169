import { Component, OnInit } from '@angular/core';
import {first} from 'rxjs/operators';
import {ApiCommsService} from '../../services/api-comms/api-comms.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ee-unsubscribe-page',
  templateUrl: './unsubscribe-page.component.html',
  styleUrls: ['./unsubscribe-page.component.scss']
})
export class UnsubscribePageComponent implements OnInit {

  gifUrl = null;

  constructor(
    private apiCommsService: ApiCommsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getGiphy();
    this.unsubscribeUser();
  }

  private getGiphy() {
    this.apiCommsService
      .getGiphyImage('bye')
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.gifUrl = result;
        }
      });
  }

  private unsubscribeUser() {
    if (this.route.snapshot.queryParams.token) {
      this.apiCommsService.unsubscribeUser(this.route.snapshot.queryParams.token).subscribe();
    }
  }

}
