<div fxLayout="row" fxLayoutAlign="start center" matAutocompleteOrigin #mapSearchOrigin="matAutocompleteOrigin">
  <button mat-icon-button class="search-button" (click)="focusSearch()">
    <mat-icon fontSet="far" fontIcon="fa-search"></mat-icon>
  </button>

  <mat-form-field class="map-search" [floatLabel]="'never'">
    <input #mapSearchInput matInput type="search" placeholder="Search for something" (focus)="onSearchFocus()"
           [matAutocomplete]="mapSearch" [matAutocompleteConnectedTo]="mapSearchOrigin" [formControl]="searchValue"
           #autocomplete="matAutocompleteTrigger">
    <mat-icon matSuffix fontSet="fal" fontIcon="fa-times" (click)="clearSearch()" *ngIf="showClear && !isLoading"></mat-icon>
    <div class="spinner" matSuffix *ngIf="isLoading">
      <mat-progress-spinner mode="indeterminate" class="main-loading"></mat-progress-spinner>
    </div>
  </mat-form-field>

  <button mat-icon-button class="search-button advanced" (click)="toggleAdvancedSearch()" [ngClass]="{'opened': advancedSearchVisible}">
    <mat-icon fontSet="far" fontIcon="fa-ellipsis-v"></mat-icon>
  </button>

  <mat-autocomplete #mapSearch="matAutocomplete" autoActiveFirstOption
                    [class]="'map-search-autocomplete' + (extraClass ? ' ' + extraClass : '')"
                    (optionSelected)="onSelect($event)" [displayWith]="displayResult">

    <div class="map-search-scroll-container" [perfectScrollbar]>
        <ng-container *ngFor="let result of searchResults">
          <mat-optgroup *ngIf="result && result.results && result.results.length > 0"
                        [label]="result.tag" [ngClass]="getResultsIcon(result)">
            <mat-option *ngFor="let item of result.results" [value]="item" [title]="item.result">
              <div class="name">{{item.result}}</div>
              <div class="id" *ngIf="item.id && result.tag !== MapSearchTypeEnum.LOCATION" 
              (click)="openSchoolView(item); $event.stopPropagation();"
              (mouseenter)="item.hovered = true"
              (mouseleave)="item.hovered = false"
              [class.clickable]="result.tag === MapSearchTypeEnum.GOV_SCHOOL"
              [style.background-color]="item.hovered ? '#2E80BC' : ''">{{item.id}}</div>
            </mat-option>
          </mat-optgroup>
        </ng-container>
    </div>

  </mat-autocomplete>
</div>
