import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MapSearchService} from '../../services/map-search/map-search.service';
import {MapSearchResult} from '../../interfaces/map-search-result';
import {FormControl} from '@angular/forms';
import {debounceTime, map, takeUntil, tap} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import {MapObject} from '../../interfaces/map-object';
import {Subject} from 'rxjs';
import {MapSearchType} from '../../enums/map-search-type';
import {MapSearchResultItem} from '../../interfaces/map-search-result-item';
import { Router } from '@angular/router';
import { HubSchoolRoutes } from 'src/app/hub/enums/hub-school-routes';
import { HubRoutes } from 'src/app/hub/enums/hub-routes';
import { AppRoutes } from 'src/app/core/enums/app-routes';
import { TileyService } from '../../services/tiley/tiley.service';
import { MapReferenceLayerDefs } from '../../enums/map-reference-layer-defs';

@Component({
  selector: 'ee-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss']
})
export class MapSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mapObject: MapObject;
  @Input() extraClass: string;

  @ViewChild('mapSearchInput', { static: true }) mapSearchInput: any;
  @ViewChild('mapSearch', { static: true }) mapSearch: any;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  showClear = false;
  isLoading = false;
  searchValue = new FormControl();
  searchResults: MapSearchResultItem[] = [];
  selectedResult: MatOption = null;
  skipSelectClear = false;
  advancedSearchVisible = false;
  MapSearchTypeEnum = MapSearchType;
  private initialised = false;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  hoveredItemIds: { [key: string]: boolean } = {};
  tileId: string = '';

  constructor(
    private router: Router,
    private mapSearchService: MapSearchService,
    private tileyService: TileyService
  ) {}

  ngOnInit() {
    this.watchSearchValue();
    this.watchSearchLoading();
    this.watchSearchResults();
    this.watchAdvancedSearchVisible();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapObject && this.mapObject && !this.initialised) {
      this.initialised = true;
      this.checkSearchClear();
    }
  }

  private watchSearchValue() {
    this.searchValue.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(500),
        tap(searchValue => {
          if (this.skipSelectClear) {
            this.skipSelectClear = false;
          } else {
            this.selectedResult = null;
          }

          this.showClear = (searchValue !== '' && searchValue !== null);
        }),
        map(searchValue => {
          if (typeof searchValue === 'string') {
            return searchValue;
          } else {
            return null;
          }
        })
      )
      .subscribe(searchValue => {
        this.isLoading = true;
        this.mapSearchService.search(searchValue);
      });
  }

  private watchSearchLoading() {
    this.mapSearchService.onSearchLoading()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.isLoading = true;
      });
  }

  private watchSearchResults() {
    this.mapSearchService.onSearchComplete()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(results => {
        this.searchResults = results;
        this.isLoading = false;
      });
  }

  private watchAdvancedSearchVisible() {
    this.mapSearchService.onAdvancedSearchVisibilityChange()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        this.advancedSearchVisible = visible;
      });
  }

  private checkSearchClear() {
    this.mapObject.onForceSearchClear
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result) {
          this.clearSearch();
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onSearchFocus() {
    if (this.mapSearchService.getAdvancedSearchVisibility()) {
      this.mapSearchService.closeAdvancedSearch();
    }
  }

  clearSearch() {
    if (this.mapObject) {
      this.searchValue.reset();
      this.selectedResult = null;
      this.mapSearchService.clearSearch();
      this.mapSearchService.clearSearchResultLayer(this.mapObject);
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    if (this.mapObject && event && event.option && event.option.value) {
      this.selectedResult = event.option;
      this.mapSearchService.selectSearchResult(this.mapObject, this.selectedResult.value as MapSearchResult);
      this.skipSelectClear = true;
    }
  }

  openSchoolView(result: MapSearchResult){
    const query = `SELECT tile_id AS result FROM data.${result.dataset} WHERE ${result.idColumn} = ${result.id}`;
      this.tileyService.query(query)
        .subscribe(res => {
          if (res && res.data && res.data[0] && res.data[0].result) {
            this.handleTileId(res,result);
          }
        }); 
      }
  
  private handleTileId(res: any, result: MapSearchResult) {
    let url: string;
    this.tileId = res.data[0].result;
    //console.log(this.tileId);
      if (result.tag === this.MapSearchTypeEnum.CATCHMENT){
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${HubRoutes.SCHOOL_CATCHMENTS}/${this.tileId}`;
      } else if(result.tag === this.MapSearchTypeEnum.GOV_SCHOOL || 
                result.tag === this.MapSearchTypeEnum.NON_GOV_SCHOOL ||
                result.tag === this.MapSearchTypeEnum.NEW_SCHOOL ||
                result.tag === this.MapSearchTypeEnum.UPGRADE_SCHOOL){
        url = `${AppRoutes.HUB}/${HubRoutes.SCHOOL}/${result.id}/${HubSchoolRoutes.SCHOOL_OVERVIEW}`;
      } else if (result.tag === this.MapSearchTypeEnum.PLANNED_AREA_PRECINT){
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${result.layer}/${result.id}`;
      } else if (result.tag === this.MapSearchTypeEnum.CLUSTER){
        url = `${AppRoutes.HUB}/${HubRoutes.CLUSTER}/${result.id}`;
      } else if (result.tag === this.MapSearchTypeEnum.LGA){
        url = `${AppRoutes.HUB}/${HubRoutes.LGA}/${result.id}`;
      } else if (result.tag === this.MapSearchTypeEnum.PRINCIPAL_NETWORKS){
        url = `${AppRoutes.HUB}/${HubRoutes.PRINCIPAL_NETWORK}/${result.id}`;
      } else if (result.tag === this.MapSearchTypeEnum.STATE_ELECTORATE_DISTRICTS){
        url = `${AppRoutes.HUB}/${HubRoutes.SED}/${result.id}`;
      } else if (result.tag === this.MapSearchTypeEnum.DET_REGIONS){
        url = `${AppRoutes.HUB}/${HubRoutes.DET}/${result.id}`;
      } else if (result.tag === this.MapSearchTypeEnum.SA1_BOUNDARIES){
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.SA1_BOUNDARIES}/${this.tileId}`;
      } else if (result.tag === this.MapSearchTypeEnum.SA2_BOUNDARIES){
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.SA2_BOUNDARIES}/${this.tileId}`;
      } else if (result.tag === this.MapSearchTypeEnum.SA3_BOUNDARIES){
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.SA3_BOUNDARIES}/${this.tileId}`;
      } else if (result.tag === this.MapSearchTypeEnum.SA4_BOUNDARIES){
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.SA4_BOUNDARIES}/${this.tileId}`;
      } else if (result.tag === this.MapSearchTypeEnum.MESH_BLOCKS){
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.MESH_BLOCKS}/${this.tileId}`;
      } else if (result.tag === this.MapSearchTypeEnum.CADASTRE_LOTS){
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.CADASTRE_LOTS}/${this.tileId}`;
      } else if (result.tag === this.MapSearchTypeEnum.DPIE_REGIONS){
        url = `${AppRoutes.HUB}/${HubRoutes.DPIE_REGION}/${result.id}`;
      }else if (result.tag === this.MapSearchTypeEnum.OPERATIONAL_DIRECTORATES){
        url = `${AppRoutes.HUB}/${HubRoutes.OPERATIONAL_DIRECTORATE}/${result.id}`;
      }
      //console.log(url);
      this.router.navigate([url]);
  }

  displayResult(result: MapSearchResult) {
    return result ? result.result : undefined;
  }

  focusSearch() {
    this.mapSearchInput.nativeElement.focus();
  }

  toggleAdvancedSearch() {
    if (this.advancedSearchVisible) {
      this.mapSearchService.closeAdvancedSearch();
    } else {
      this.mapSearchService.openAdvancedSearch();
      this.autocomplete.closePanel();
    }
  }

  getResultsIcon(result: any) {
    let icon = 'vector';

    if (result.tag === MapSearchType.GOV_SCHOOL || result.tag === MapSearchType.NEW_SCHOOL ||
      result.tag === MapSearchType.UPGRADE_SCHOOL || result.tag === MapSearchType.NON_GOV_SCHOOL) {
      icon = 'school';
    } else if (result.tag === MapSearchType.LOCATION || result.tag === MapSearchType.COORDINATES) {
      icon = 'marker';
    }

    return icon;
  }
}
