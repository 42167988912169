import { Injectable } from '@angular/core';
import {InstructionsMessage} from '../../../core/interfaces/instructions-message';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstructionsService {

  instructionsMessage: InstructionsMessage = {
    message: '',
    icon: 'info',
    visible: false
  };

  private instructionsSubject: Subject<InstructionsMessage> = new Subject<InstructionsMessage>();

  constructor() {
  }

  onInstructionsToggle(): Subject<InstructionsMessage> {
    return this.instructionsSubject;
  }

  setMessage(message: string, icon?: string) {
    if (!icon) {
      icon = 'info';
    }

    this.instructionsMessage = {
      message: message,
      icon: icon,
      visible: true
    };

    this.instructionsSubject.next(this.instructionsMessage);
  }

  hide() {
    this.instructionsMessage.visible = false;
    this.instructionsSubject.next(this.instructionsMessage);
  }
}
