export enum VisionAppRoutes {
  CREATE_CLUSTER = 'create-cluster',
  SNAPSHOT = 'snapshot',
  ADMIN = 'admin',
  ADMIN_CLUSTERS = 'clusters',
  ADMIN_REPORTS = 'reports',
  ADMIN_USERS = 'users',
  ADMIN_TEAMS = 'teams',
  REPORTS = 'reports',
  REPORTS_SELECT_TEMPLATE = 'select-template',
  REPORTS_CREATE = 'create',
  REPORTS_VIEW = 'view',
  REPORTS_EDIT = 'edit',
  REPORTS_REVIEW = 'review',
  REPORTS_ATTACHMENTS = 'attachments',
  REPORTS_UPDATE = 'update',
  REPORT_REVIEW = 'review',
  DASHBOARD = 'dashboard',
  OVERVIEW = 'overview',
  CLUSTERS = 'clusters',
  CUSTOM = 'custom',
  MY_REPORTS = 'my-reports',
  TEMPLATES = 'templates',
  TEMPLATE_VIEW = 'view',
  TEMPLATE_EDIT = 'edit',
}
